import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Widget from "../../../components/widget/Widget";
import GridConfig from "../../../components/grid/GridConfig";
import { GridConfigData } from "./grid_data/GridData";
import { getKycData } from "../store/grid_player_load/gridPlayersActions";
import { Box, Typography, Button } from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

const KYC = (_, context) => {
  const store = useSelector((state) => ({
    players: state.PlayersData.players,
    kyc: state.PlayersData.kyc,
  }));
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState({});
  const [playerId, setPlayerId] = useState({ id: "", disabled: true });

  useEffect(() => {
    dispatch(getKycData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Grid
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const onRowSelected = (e) => {
    const selectedRows = gridApi.getSelectedRows();
    if (selectedRows.length === 1) {
      setPlayerId({ id: selectedRows[0].player.uuid, disabled: false });
      return;
    }
    setPlayerId({ id: "", disabled: true });
  };

  return (
    <>
      <Box sx={(theme) => ({
        marginTop,
        marginLeft: drawerWidth,
        [theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      })}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("Pending KYC")}
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("Total Pending")}
                data={store.kyc?.totalKycPending}
                description={context.t("Players awaiting approval")}
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Total Approved")}
                data={(store.kyc?.totalKycApproved)}
                description={context.t("Players kyc approved")}
                Icon={PeopleAltIcon}
              />
            }
          </Box>
        </Box>
        <Box sx={{
          display: "grid",
          margin: (theme) => theme.spacing(10, 4),
          gridTemplateColumns: "100%",
        }}>
          <Box sx={{
            justifySelf: "end",
            margin: (theme) => theme.spacing(1, 0),
          }}>
            <Button
              variant="contained"
              sx={(theme) => ({
                padding: theme.spacing(1, 2),
                margin: theme.spacing(0, 1),
                minWidth: 200,
                color: theme.palette.primary.light,
                background: theme.palette.secondary.dark,
              })}
              disabled={playerId.disabled}
              href={`/playerKyc?id=${playerId.id}`}
            >
              {context.t("Review")}
            </Button>
          </Box>
          <div>
            <div className="ag-theme-material">
              <Box sx={{
                height: "50vh",
              }}>
                {store.kyc?.kycRecords?.length === 0 ? (
                  <div>
                    <Typography variant="h5" noWrap paragraph>
                      {context.t("No Records")}
                    </Typography>
                  </div>
                ) : (
                  <GridConfig
                    props={{
                      GridConfigData,
                      onGridReady,
                      onRowSelected,
                      rowData: store.kyc?.kycRecords,
                    }}
                  />
                )}
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

KYC.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default KYC;
