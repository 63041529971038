import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from '@redux-devtools/extension';
import { i18nState } from "redux-i18n";

import { changeTheme } from "../components/navbar/store/choose_theme/ChooseThemeReducers";
import { changeMenuOpen } from "../components/navbar/store/menu_open/MenuOpenReducers";
import { changeCurrentPage } from "../components/navbar/store/current_page/CurrentPageReducers";
import { loginUser } from "../containers/signin/login_page/store/LoginReducers";
import { showNotification } from "../components/notifications/store/notificationReducers";
import { ForgotPassword } from "../containers/signin/forgot_password/store/ForgotPasswordReducers";
// Game Providers
import { GameProviders } from "./game_providers/GameProvidersReducers";
import { Games } from "./games/GamesReducers";
// Admin Tab
import { AdminsData } from "../containers/admin/store/get_all_admins/AdminsReducers";

// Player Management
import { PlayersData } from "../containers/player_management/store/grid_player_load/gridPlayersReducers";
import { PlayerData } from "../containers/player_management/store/player_actions/playerReducers";
import { PlayerKyc } from "../containers/player_management/KYC/store/kycReducers";
// Player Payments
import { InGameTransactions } from "../containers/payments/payments_dashboard/player_payments/store/inGameTransactionsReducers";
import { PlayerPayments } from "../containers/payments/payments_dashboard/player_payments/store/PlayerPaymentsReducers";
// Transaction
import { TransactionsData } from "../containers/payments/store/get_all_transactions/TransactionsReducers";
// Settings
import { PlatformConfig } from "../containers/settings/currencies/store/currenciesReducer";
// Bonuses
import { BonusRecords } from "../containers/bonuses/store/bonus_records/BonusRecordsReducers";
import { BonusEntries } from "../containers/bonuses/store/bonus_entries/BonusEntriesReducers";
import { bonusTypesFilters } from "../containers/bonuses/bonus_types/store/filtersReducers";
// CRM
import { campaignData } from "../containers/crm/create_promo/store/createCampaignReducers";
// Loading
import { showLoading } from "../components/loading/store/showLoadingReducers";
import { Languages } from "../containers/languages/store/LanguageReducers";
import { Pages } from "../containers/pages/store/PageReducers";
import { Translations } from "../containers/translations/store/translationReducers";
import { Glossaries } from "../containers/glossary/store/GlossaryReducers";

import { getLanguages, setLanguage, getTranslations } from "./languages/LanguageReducers";

const persistConfig = {
  key: "PantheraBackOffice",
  whitelist: [
    "changeTheme",
    "changeCurrentPage",
    "loginUser",
    "bonusTypesFilters",
  ],
  storage,
};

const rootReducer = combineReducers({
  showNotification,
  changeMenuOpen,
  changeTheme,
  changeCurrentPage,
  loginUser,
  ForgotPassword,
  GameProviders,
  Games,
  AdminsData,
  PlayerData,
  PlayersData,
  PlayerKyc,
  TransactionsData,
  BonusRecords,
  BonusEntries,
  bonusTypesFilters,
  campaignData,
  InGameTransactions,
  PlayerPayments,
  PlatformConfig,
  showLoading,
  i18nState,
  Languages,
  Pages,
  Translations,
  Glossaries,
  getLanguages,
  setLanguage,
  getTranslations,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
const persistor = persistStore(store);
export { store, persistor };
