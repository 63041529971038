import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, withRouter } from "react-router-dom";

import { createTheme, ThemeProvider } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import NavBar from "../components/navbar/index";
import { themesConfig } from "../themes/main";
import { Routes } from "../components/routes/routes";
import { ProtectedRoute } from "../helpers/protectedRoute";
import { setCurrentPage } from "../components/navbar/store/current_page/CurrentPageActions";
import LoadingComponent from "../components/loading/Loading";
import { checkToken } from "./signin/login_page/store/LoginActions";
import getConfig from "../config/getConfig";
import "../themes/Main.scss";
import { GetTranslations } from "../store/languages/LanguageActions";
import { setLanguage } from "redux-i18n";

const App = ({ match, history }) => {
  const state = useSelector((state) => ({
    theme: state.changeTheme.theme,
    loggedIn: state.loginUser.loggedIn,
    isLoading: state.showLoading.isLoading,
    translations: state.getTranslations.translations,
    lang: state.i18nState.lang,
  }));
  const theme = createTheme(themesConfig[state.theme]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetTranslations());
    console.log(state.lang)
    if (state.lang === "en") {
      dispatch(setLanguage("en_GB"));
    }
  }, []);

  useEffect(() => {
    let single = [].concat(...Routes.map((el) => el.components));
    let pathname = getConfig().DOMAIN;
    try {
      pathname = single.filter((el) => el.path === history.location.pathname)[0]
        .name;
    } catch {
      pathname = "Not Found";
    }
    document.title = `${pathname} - ${getConfig().DOMAIN} `;
    dispatch(setCurrentPage(history.location.pathname));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  useEffect(() => {
    if (state.loggedIn) {
      dispatch(checkToken());
    }
  })

  useEffect(() => {
    // console.log("first");
    dispatch(GetTranslations());
  }, []);

  const routeComponents = Routes.map((route) =>
    route.components.map(({ path, component, usersCanSee }, key) => (
      <ProtectedRoute
        exact
        users={usersCanSee}
        path={path}
        component={component}
        key={key}
      />
    )));
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        {state.isLoading ? <LoadingComponent /> : ""}
        {state.loggedIn ? <NavBar /> : ""}
        <Switch>{routeComponents}</Switch>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default withRouter(App);
