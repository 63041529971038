import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Widget from "../../../components/widget/Widget";
import GridConfig from "../../../components/grid/GridConfig";
import { GridConfigData } from "./grid_data/GridData";
import { getPlayersData } from "../../player_management/store/grid_player_load/gridPlayersActions";
import { Box, Typography, Button } from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

const PaymentsDashboard = (_, context) => {
  const store = useSelector((state) => ({
    data: state.PlayersData.players,
  }));
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState({});
  const [playerId, setPlayerId] = useState({ id: "", disabled: true });
  useEffect(() => {
    dispatch(getPlayersData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Grid
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const onRowSelected = (e) => {
    const selectedRows = gridApi.getSelectedRows();
    if (selectedRows.length === 1) {
      setPlayerId({ id: selectedRows[0].uuid, disabled: false });
      return;
    }
    setPlayerId({ id: "", disabled: true });
  };
  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("Payments Dashboard")}
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("24hr Money Won")}
                data={39504}
                description={`${context.t("Average winning")}: 38.4`} // HOW TO DO THIS??
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name={context.t("24hr Money Lost")}
                data={41504}
                description={`${context.t("Average lost")}: 83.4`}
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name={context.t("24hr Net Profit")}
                data={2000}
                description={`${context.t("NGR per Player")}: 43`}
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name={context.t("Players over 24hr")}
                data={6}
                description={context.t("Total Players")}
                Icon={PeopleAltIcon}
              />
            }
          </Box>
        </Box>
        <Box sx={{
          display: "grid",
          margin: (theme) => theme.spacing(10, 4),
          gridTemplateColumns: "100%",
        }}>
          <Box sx={{
            justifySelf: "end",
            margin: (theme) => theme.spacing(1, 0),
          }}>
            <Button
              variant="contained"
              sx={(theme) => ({
                padding: theme.spacing(1, 2),
                margin: theme.spacing(0, 1),
                minWidth: 200,
                color: theme.palette.primary.light,
                background: theme.palette.secondary.dark,
              })}
              disabled={playerId.disabled}
              href={`/playerPayments?id=${playerId.id}`}
            >
              {context.t("Show More")}
            </Button>
          </Box>
          <div>
            <div className="ag-theme-material">
              <Box sx={{
                height: "50vh",
              }}>
                <GridConfig
                  props={{
                    GridConfigData,
                    onGridReady,
                    onRowSelected,
                    rowData: store.data,
                  }}
                />
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

PaymentsDashboard.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PaymentsDashboard;
