import * as constants from "./GamesConstants";
import { GameTypesMock } from "../mock_data/GameTypesMock";
import { GameConditionsMock } from "../mock_data/GameConditionsMock";
import {
  setNotificationMessage,
  setShowNotificationMessage,
} from "../../components/notifications/store/notificationActions";
import getConfig from "../../config/getConfig";

export const getGamesData = (filters) => (dispatch, getState) => {
  let gameProvider = filters.gameProvider;
  let gameType =
  filters.gameType === "All"
      ? undefined
      : { filterType: "set", values: [filters.gameType] };

  let query = {
    gameProvider: {
      filterType: "set",
      values: gameProvider,
    },
    ...(gameType !== undefined && { gameType }),
    ...(filters.name && {name: filters.name})
  };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(query),
  };
  fetch(`${getConfig().API_URL_GAME_PROVIDERS}/gp/games`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error.message);
      }
      dispatch({
        type: constants.REQUEST_GAMES_SUCCESS,
        payload: json.games,
      });
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};

export const getGameTypesData = () => (dispatch, getState) => {
  // const { token } = getState().loginUser;
  // const requestOptions = {
  //   method: "GET",
  //   headers: { "x-access-token": `${token}` },
  // };
  // dispatch({ type: constants.REQUEST_GAME_TYPES_PENDING });
  // fetch(`${config.API_URL}admins/get_users`, requestOptions)
  //   .then((response) => response.json())
  //   .then((json) => {
  //    if(json.errors){
  //      throw new Error()
  //    }
  //     return dispatch({
  //       type: constants.REQUEST_GAME_TYPES_SUCCESS,
  //       payload: json,
  //     });
  //   })
  //   .catch((error) =>
  //     dispatch({ type: constants.REQUEST_GAME_TYPES_FAILED, payload: error })
  //   );
  return dispatch({
    type: constants.REQUEST_GAME_TYPES_SUCCESS,
    payload: GameTypesMock,
  });
};

export const getGameConditionsData = () => (dispatch, getState) => {
  // const { token } = getState().loginUser;
  // const requestOptions = {
  //   method: "GET",
  //   headers: { "x-access-token": `${token}` },
  // };
  // dispatch({ type: constants.REQUEST_GAME_TYPES_PENDING });
  // fetch(`${config.API_URL}admins/get_users`, requestOptions)
  //   .then((response) => response.json())
  //   .then((json) => {
  //    if(json.errors){
  //      throw new Error()
  //    }
  //     return dispatch({
  //       type: constants.REQUEST_GAME_TYPES_SUCCESS,
  //       payload: json,
  //     });
  //   })
  //   .catch((error) =>
  //     dispatch({ type: constants.REQUEST_GAME_TYPES_FAILED, payload: error })
  //   );
  return dispatch({
    type: constants.REQUEST_GAME_CONDITIONS_SUCCESS,
    payload: GameConditionsMock,
  });
};
