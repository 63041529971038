import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Button, Divider, Box } from "@mui/material";
import LabelIcon from "@mui/icons-material/Label";
import SearchBox from "../../../components/bonuses/searchBox";
import BonusTypeCard from "../../../components/bonuses/bonusesCard";
import { getBonusRecordsData } from "../store/bonus_records/BonusRecordsActions";
import { setCurrentFilter } from "./store/filtersActions";
import CreateNewBonus from "./create_new_bonus/CreateNewBonus";

const drawerWidth = '270px';
const marginTop = '100px';

const BonusTypes = (_, context) => {
  const store = useSelector((state) => ({
    bonusRecords: state.BonusRecords.bonusRecords,
    filters: state.bonusTypesFilters.filters,
    chosenFilter: state.bonusTypesFilters.chosenFilter,
  }));
  const dispatch = useDispatch();
  const [searchField, setsearchField] = useState("");
  const [createBonus, setcreateBonus] = useState(false);
  const onSearchChange = (event) => {
    event.preventDefault();
    setsearchField(event.target.value);
  };
  const [
    bonusFilteredByPredefinedFilters,
    setbonusFilteredByPredefinedFilters,
  ] = useState([]);
  const filteredBonusTypes = bonusFilteredByPredefinedFilters.filter((type) => {
    return type.name.toLowerCase().includes(searchField.toLowerCase());
  });
  const selectFilter = (filter) => {
    if (filter.name === store.chosenFilter) {
      setbonusFilteredByPredefinedFilters(store.bonusRecords);
      return dispatch(setCurrentFilter(""));
    }
    const compare = (post, operator, value) => {
      switch (operator) {
        case ">":
          return post > value;
        case "<":
          return post < value;
        case ">=":
          return post >= value;
        case "<=":
          return post <= value;
        case "===":
          return post === value;
        case "!==":
          return post !== value;
        default:
          return post === value;
      }
    };
    setbonusFilteredByPredefinedFilters(
      store.bonusRecords.filter((el) => {
        let field;
        if (filter.field.split(".").length > 1) {
          field = el[filter.field.split(".")[0]][filter.field.split(".")[1]];
        } else {
          field = el[filter.field];
        }
        return compare(field, filter.parametr, filter.value);
      })
    );

    dispatch(setCurrentFilter(filter.name));
  };
  const [toggle, setToggle] = useState(false);

  // const editFiltersFunc = () => {
  //   seteditFilters(!editFilters);
  // };
  // const deleteFilterFunc = (name) => {
  //   dispatch(removeFilter(name));
  // };
  useEffect(() => {
    setToggle(!toggle);
    setbonusFilteredByPredefinedFilters(store.bonusRecords);
  }, [store.bonusRecords]);
  useEffect(() => {
    dispatch(getBonusRecordsData());
    setbonusFilteredByPredefinedFilters(store.bonusRecords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.bonusRecords.length]);
  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("Avaliable Bonuses")}
          </Typography>
          <Box sx={(theme) => ({
            margin: theme.spacing(4, 0),
            display: "grid",
            gridGap: "20px",
            gridTemplateColumns: "20% 80%",
            [theme.breakpoints.down("md")]: {
              gridTemplateColumns: "100%",
            },
          })}>
            <Box sx={{
              alignSelf: "start",
              borderRadius: "4px",
              background: (theme) => theme.palette.background.paper,
            }}>
              <Box sx={{
                display: "flex",
                padding: (theme) => theme.spacing(2, 0, 1, 0),
                textAlign: "center",
                flexDirection: "column",
              }}>
                <Typography variant="h6" noWrap paragraph>
                  {context.t("Filters")}:
                </Typography>
                <Divider/>
                <Box sx={{
                  maxHeight: "35vh",
                  overflow: "auto",
                }}>
                  {store.filters.map((filter) => {
                    return (
                      <Box
                        key={filter.name}
                        sx={(theme) => ({
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                          margin: theme.spacing(1, 5, 1, 5),
                          padding: theme.spacing(1, 1),
                          borderRadius: "0 10px 10px 0",
                          textAlign: "left",
                          ...(store.chosenFilter === filter.name && {
                            background: theme.palette.secondary.dark,
                            color: theme.palette.primary.light,
                          })
                        })}
                        onClick={(event) => {
                          selectFilter(
                            store.chosenFilter === filter ? "" : filter
                          );
                        }}
                      >
                        <Box sx={{
                          display: "flex",
                        }}>
                          <LabelIcon
                            color={
                              store.chosenFilter === filter.name
                                ? "secondary"
                                : "primary"
                            }
                          />
                          <Typography
                            variant="body1"
                            sx={{
                              padding: (theme) => theme.spacing(0, 2),
                            }}
                          >
                            {filter.name}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                <Divider/>
                <Button
                  variant="contained"
                  sx={(theme) => ({
                    alignSelf: "center",
                    margin: theme.spacing(2),
                    padding: theme.spacing(1, 2),
                    minWidth: 200,
                    color: theme.palette.primary.light,
                    background: theme.palette.secondary.dar
                  })}
                  onClick={(e) => setcreateBonus(true)}
                >
                  {context.t("Create New Bonus Type")}
                </Button>
                <CreateNewBonus open={createBonus} setOpen={setcreateBonus}/>
              </Box>
            </Box>
            <div>
              <Box sx={(theme) => ({
                display: "flex",
                justifyContent: "flex-start",
                padding: theme.spacing(0, 0, 2, 0),
                [theme.breakpoints.down("md")]: {
                  justifyContent: "center",
                },
              })}>
                <SearchBox searchChange={onSearchChange}/>
              </Box>
              <Box sx={(theme) => ({
                maxHeight: "65vh",
                overflow: "auto",
                display: "grid",
                gridTemplateColumns: "33% 33% 33%",
                [theme.breakpoints.down("md")]: {
                  gridTemplateColumns: "50% 50%",
                },
                [theme.breakpoints.down("sm")]: {
                  gridTemplateColumns: "100%",
                },
              })}>
                <BonusTypeCard bonusType={filteredBonusTypes}/>
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

BonusTypes.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BonusTypes;
