import * as constants from "./GlossaryConstants";

const initialState = {
  EN: [],
  ALT: [],
};

export const Glossaries = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_GET_GLOSSARY:
      return {
        ...state,
        EN: action.payload,
      };
    case constants.REQUEST_GET_ALT_GLOSSARY:
      return {
        ...state,
        ALT: action.payload,
      };
    case constants.REQUEST_ADD_GLOSSARY:
      console.log("AddG");
      return {
        ...state,
      };
    case constants.REQUEST_UPDATE_GLOSSARY:
      // console.log("UpdateG");
      return {
        ...state,
      };
    default:
      // console.log("default");
      return {
        ...state,
      };
  }
};
