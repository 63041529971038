import * as constants from "./createCampaignConstants";

export const setStepOneData = (field, data) => ({
  type: constants.SET_STEP_ONE_DATA,
  field,
  payload: data,
});

export const setStepTwoData = (field, data) => ({
  type: constants.SET_STEP_TWO_DATA,
  field,
  payload: data,
});

export const setStepThreeData = (field, data) => ({
  type: constants.SET_STEP_THREE_DATA,
  field,
  payload: data,
});
