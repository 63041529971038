import * as React from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';

export default function MultiSelector({ languages, setActiveLanguage }) {
  const handleChange = (event) => {
    setActiveLanguage(event.target.value);
  };
  return (
    <div>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Language"
            onChange={handleChange}
          >
            {languages.map(({ value, label }, index) => {
              // console.log(value)
              if (value !== 1) {
                return <MenuItem key={index} value={value}>{label}</MenuItem>
              }
            })}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}
