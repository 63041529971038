import React from "react";
import { Card, CardContent, CardHeader, Typography, CardMedia } from "@mui/material";
import emailTemplate from "../../../assets/emailTemplate.png";
import PropTypes from "prop-types";

const Preview = () => {
  return (
    <>
      <Card sx={{
        margin: "8px 15%",
        "& .bodyText": {
          color: "black",
        },
      }} variant="outlined">
        <CardHeader
          sx={{
            backgroundColor: "#7a80b4",
          }}
          title={
            <Typography gutterBottom variant="h5" component="h2">
              Campaign Settings
            </Typography>
          }
        />
        <CardContent sx={{
          backgroundColor: "#e8eaf6",
        }}>
          <Typography component={"div"} color="textSecondary" gutterBottom>
            Campaign Name:
            <div className="bodyText">Bonus Promo</div>
          </Typography>
          <Typography component={"div"} color="textSecondary" gutterBottom>
            Target Group:
            <div className="bodyText">
              Players who registered in the last week
            </div>
          </Typography>
          <Typography
            component={"div"}
            color="textSecondary"
          >
            Campaign Duration:
            <br />
            <div className="bodyText">2020-3-11 - 2025-3-11</div>
            <br />
          </Typography>
        </CardContent>

        <CardHeader
          sx={{
            backgroundColor: "#7a80b4",
          }}
          title={
            <Typography gutterBottom variant="h5" component="h2">
              Bonus Type
            </Typography>
          }
        />
        <CardContent sx={{
          backgroundColor: "#e8eaf6",
        }}>
          <Typography component={"div"} color="textSecondary" gutterBottom>
            Name:
            <div className="bodyText">Signup - Slot 100% 15x</div>
          </Typography>
          <Typography
            component="div"
            sx={{
              "& .status": {
                color: "green",
              },
            }}
            color="textSecondary"
          >
            Status:
            <br />
            <div className="status">Active</div>
          </Typography>
          <Typography
            component="div"
            color="textSecondary"
          >
            Duration:
            <br />
            <div className="bodyText">2020-3-11 - 2025-3-11</div>
            <br />
          </Typography>
          <Typography
            component="div"
            color="textSecondary"
          >
            Description:
            <br />
            <div className="bodyText">
              In dignissim mauris elit, ac facilisis diam ornare sit amet.
              Mauris tempor vestibulum bibendum.
            </div>
            <br />
          </Typography>
        </CardContent>

        <CardHeader
          sx={{
            backgroundColor: "#7a80b4",
          }}
          title={
            <Typography gutterBottom variant="h5" component="h2">
              Selected Template
            </Typography>
          }
        />

        <CardContent sx={{
          backgroundColor: "#e8eaf6",
        }}>
          <CardMedia sx={{
            maxWidth: "70%",
            height: 1380,
            margin: "15px 20%",
          }} image={emailTemplate} />
        </CardContent>
      </Card>
    </>
  );
};

Preview.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Preview;
