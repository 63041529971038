const columnDefs = (context) => [
    {
      headerName: context.t("Name"),
      checkboxSelection: true,
      minWidth: 240,

      valueGetter: function combineField(params) {
        return `${params.data.firstName} ${params.data.lastName}`;
      },
      cellClass: "cell-default-style",
      filter: "agTextColumnFilter",
      filterParams: {
        cellHeight: 20,
        debounceMs: 100,
        filterOptions: ["contains"],
      },
    },
    {
      headerName: context.t("Email"),
      valueGetter: function getField(params) {
        return `${params.data.email}`;
      },
      minWidth: 240,

      cellClass: "cell-default-style",
      chartDataType: "series",
    },
  ];
  const defaultColDef = {
    resizable: true,
    filter: true,
  };
  const floatingFilter = true;

  const rowSelection = "multiple";

  export const GridConfigData = {
    columnDefs,
    defaultColDef,
    floatingFilter,
    rowSelection,
  };
