 const columnDefs = (context) => [
    {
      headerName: context.t("Provider"),
      field: "brand",
      minWidth: 130,
      // checkboxSelection: true,
      cellClass: "cell-default-style",
    },
    {
      headerName: context.t("Turnover"),
      field: "turnover",
      minWidth: 130,
      cellClass: "cell-default-style",
      chartDataType: "series",
    },
    {
      headerName: context.t("Margin") + "%",
      field: "margin",
      minWidth: 130,
      cellClass: "cell-default-style",
      chartDataType: "series",
    },
    {
      headerName: "GGR",
      field: "ggr",
      minWidth: 130,
      cellClass: "cell-default-style",
      chartDataType: "series",
    },
    {
      headerName: context.t("Bonus Given"),
      field: "bonusgiven",
      minWidth: 130,
      cellClass: "cell-default-style",
      chartDataType: "series",
    },
    {
      headerName: context.t("Bonus Spent"),
      field: "bonusspent",
      minWidth: 130,
      cellClass: "cell-default-style",
      chartDataType: "series",
    },
    {
      headerName: "NGR",
      field: "ngr",
      minWidth: 130,
      cellClass: "cell-default-style",
      chartDataType: "series",
    },
  ];

  const defaultColDef = {
    resizable: true,
  };
   const floatingFilter = false;

   const rowClassRules = {
    "bold-text": "data.brand === 'Total:'",
  };
   const rowSelection = 'single'

   const pagination = false;
   const paginationAutoPageSize = false;
   const sideBar = null;

   export const GridConfigData = {
    columnDefs,
    defaultColDef,
    floatingFilter,
    rowClassRules,
    rowSelection,
    pagination,
    paginationAutoPageSize,
    sideBar
   }

  export const createBottomCalc = (rowData) => {
    let result = [];
    let filtered = 0;
    if (rowData.length > 0) {
      filtered = rowData.reduce((a, b) => ({
        turnover: a.turnover + b.turnover,
        margin: a.margin + b.margin,
        ggr: a.ggr + b.ggr,
        bonusgiven: a.bonusgiven + b.bonusgiven,
        bonusspent: a.bonusspent + b.bonusspent,
        ngr: a.ngr + b.ngr,
      }));
      result.push({
        brand: "Total:",
        turnover: filtered.turnover,
        margin: filtered.margin,
        ggr: filtered.ggr,
        bonusgiven: filtered.bonusgiven,
        bonusspent: filtered.bonusspent,
        ngr: filtered.ngr,
      });
    } else {
      result.push({
        brand: "Total:",
        turnover: 0,
        margin: 0,
        ggr: 0,
        bonusgiven: 0,
        bonusspent: 0,
        ngr: 0,
      });
    }
    return result;
  }
