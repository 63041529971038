import {
  setNotificationMessage,
  setShowNotificationMessage,
  setNotificationSeverity,
} from "../../../components/notifications/store/notificationActions";
import getConfig from "../../../config/getConfig";
import * as constants from "./LanguageConstants";

export const getLanguages = (data) => (dispatch) => {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  fetch(`${getConfig().API_URL_AUTH}/auth/getLanguages`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      dispatch({
        type: constants.REQUEST_GET_LANGUAGE_SUCCESS,
        payload: json,
      });
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};

export const addLanguage = (data) => (dispatch) => {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  fetch(`${getConfig().API_URL_AUTH}/auth/addLanguage`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      dispatch({ type: constants.REQUEST_ADD_LANGUAGE_SUCCESS, payload: json });
      dispatch(setNotificationMessage("Language Added"));
      dispatch(setNotificationSeverity("success"));
      dispatch(setShowNotificationMessage(true));
      dispatch(getLanguages({all: true}));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};

export const disableLanguage = (data) => (dispatch) => {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  fetch(`${getConfig().API_URL_AUTH}/auth/disableLanguage`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      dispatch({ type: constants.REQUEST_ADD_LANGUAGE_SUCCESS, payload: json });
      dispatch(setNotificationMessage("Language Disabled"));
      dispatch(setNotificationSeverity("success"));
      dispatch(setShowNotificationMessage(true));
      dispatch(getLanguages({ all: true }));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
}

export const enableLanguage = (data) => (dispatch) => {
const requestOptions = {
  method: "POST",
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify(data),
};
fetch(`${getConfig().API_URL_AUTH}/auth/enableLanguage`, requestOptions)
  .then((response) => response.json())
  .then((json) => {
    dispatch({ type: constants.REQUEST_ADD_LANGUAGE_SUCCESS, payload: json });
    dispatch(setNotificationMessage("Language Enabled"));
    dispatch(setNotificationSeverity("success"));
    dispatch(setShowNotificationMessage(true));
    dispatch(getLanguages({ all: true }));
  })
  .catch((error) => {
    dispatch(setNotificationMessage(error.message));
    dispatch(setShowNotificationMessage(true));
  });
}
