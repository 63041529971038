import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import Stepper from "./StepperCreateBonus";

const CreateNewBonus = ({ open, setOpen }, context) => {
  const handleGoBack = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} maxWidth="md">
        <DialogTitle sx={(theme) => ({
          color: theme.palette.primary.light,
          background: theme.palette.primary.main,
        })}>
          {context.t("Create New Bonus")}
        </DialogTitle>
        <DialogContent sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          whiteSpace: "nowrap",
        })}>
          <Stepper cancel={handleGoBack} />
        </DialogContent>
      </Dialog>
    </>
  );
};

CreateNewBonus.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CreateNewBonus;
