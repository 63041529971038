import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateCurrencyInformation } from "../store/currenciesActions";
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const Extremums = ({open, setOpen, data}, context) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    dispatch(updateCurrencyInformation(values));
    setOpen(false);
  };
  // form
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: data?.code || null,
      maxConversionLimit: data?.maxConversionLimit || 0,
      minDeposit: data?.minDeposit || 0,
      maxDeposit: data?.maxDeposit || 0,
      minMaxAmount: data?.minMaxAmount || 0,
      maxMaxDeposit: data?.maxMaxDeposit || 0,
      minMaxWageringContribution: data?.minMaxWageringContribution || 0,
      maxMaxWageringContribution: data?.maxMaxWageringContribution || 0,
      maxApproveLimit: data?.maxApproveLimit || 0,
    },

    validationSchema: Yup.object().shape({
      maxConversionLimit: Yup.number().required(
        "max Conversion Limit is required"
      ),
      minDeposit: Yup.number().required("min Deposit is required"),
      maxDeposit: Yup.number().required("max Deposit is required"),
      minMaxAmount: Yup.number().required("min Deposit BO is required"),
      maxMaxDeposit: Yup.number().required("max Deposit BO is required"),
      minMaxWageringContribution: Yup.number().required(
        "min Wagering Contribution is required"
      ),
      maxMaxWageringContribution: Yup.number().required(
        "max Wagering Contribution is required"
      ),
      maxApproveLimit: Yup.number().required("Total Bonus Cap is required"),
    }),
  });

  const {values, touched, errors, handleChange, handleBlur, isValid, dirty} =
    formik;

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          {data?.code || context.t("Select currency first")}
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}>
            <TextField
              required
              name="maxConversionLimit"
              error={touched.maxConversionLimit && Boolean(errors.maxConversionLimit)}
              helperText={touched.maxConversionLimit ? errors.maxConversionLimit : ""}
              label={context.t("max Conversion Limit")}
              type="number"
              InputProps={{inputProps: {min: 0.0}}}
              onBlur={handleBlur}
              value={values.maxConversionLimit}
              onChange={handleChange}
              sx={{
                padding: (theme) => theme.spacing(1),
                minWidth: 220,
              }}
            />
            <TextField
              required
              name="minDeposit"
              error={touched.minDeposit && Boolean(errors.minDeposit)}
              helperText={touched.minDeposit ? errors.minDeposit : ""}
              label={context.t("min Deposit")}
              type="number"
              InputProps={{inputProps: {min: 0.0}}}
              onBlur={handleBlur}
              value={values.minDeposit}
              onChange={handleChange}
              sx={{
                padding: (theme) => theme.spacing(1),
                minWidth: 220,
              }}
            />
            <TextField
              required
              name="maxDeposit"
              error={touched.maxDeposit && Boolean(errors.maxDeposit)}
              helperText={touched.maxDeposit ? errors.maxDeposit : ""}
              label={context.t("max Deposit")}
              type="number"
              InputProps={{inputProps: {min: 0.0}}}
              onBlur={handleBlur}
              value={values.maxDeposit}
              onChange={handleChange}
              sx={{
                padding: (theme) => theme.spacing(1),
                minWidth: 220,
              }}
            />
            <TextField
              required
              name="minMaxAmount"
              error={touched.minMaxAmount && Boolean(errors.minMaxAmount)}
              helperText={touched.minMaxAmount ? errors.minMaxAmount : ""}
              label={context.t("min Deposit BO")}
              type="number"
              InputProps={{inputProps: {min: 0.0}}}
              onBlur={handleBlur}
              value={values.minMaxAmount}
              onChange={handleChange}
              sx={{
                padding: (theme) => theme.spacing(1),
                minWidth: 220,
              }}
            />
            <TextField
              required
              name="maxMaxDeposit"
              error={touched.maxMaxDeposit && Boolean(errors.maxMaxDeposit)}
              helperText={touched.maxMaxDeposit ? errors.maxMaxDeposit : ""}
              label={context.t("max Deposit BO")}
              type="number"
              InputProps={{inputProps: {min: 0.0}}}
              onBlur={handleBlur}
              value={values.maxMaxDeposit}
              onChange={handleChange}
              sx={{
                padding: (theme) => theme.spacing(1),
                minWidth: 220,
              }}
            />
            <TextField
              required
              name="minMaxWageringContribution"
              error={touched.minMaxWageringContribution && Boolean(errors.minMaxWageringContribution)}
              helperText={touched.minMaxWageringContribution ? errors.minMaxWageringContribution : ""}
              label={context.t("min Wagering Contribution")}
              type="number"
              InputProps={{inputProps: {min: 0.0}}}
              onBlur={handleBlur}
              value={values.minMaxWageringContribution}
              onChange={handleChange}
              sx={{
                padding: (theme) => theme.spacing(1),
                minWidth: 220,
              }}
            />
            <TextField
              required
              name="maxMaxWageringContribution"
              error={touched.maxMaxWageringContribution && Boolean(errors.maxMaxWageringContribution)}
              helperText={touched.maxMaxWageringContribution ? errors.maxMaxWageringContribution : ""}
              label={context.t("max Wagering Contribution")}
              type="number"
              InputProps={{inputProps: {min: 0.0}}}
              onBlur={handleBlur}
              value={values.maxMaxWageringContribution}
              onChange={handleChange}
              sx={{
                padding: (theme) => theme.spacing(1),
                minWidth: 220,
              }}
            />
            <TextField
              required
              name="maxApproveLimit"
              error={touched.maxApproveLimit && Boolean(errors.maxApproveLimit)}
              helperText={touched.maxApproveLimit ? errors.maxApproveLimit : ""}
              label={context.t("Total Bonus Cap")}
              type="number"
              InputProps={{inputProps: {min: 0.0}}}
              onBlur={handleBlur}
              value={values.maxApproveLimit}
              onChange={handleChange}
              sx={{
                padding: (theme) => theme.spacing(1),
                minWidth: 220,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            disabled={!(isValid && dirty)}
            color="primary"
          >
            {context.t("Submit")}
          </Button>
          <Button onClick={handleClose} color="primary">
            {context.t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
Extremums.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Extremums;
