import PictureCellRenderer from './PictureRenderer';

const columnDefs = (context) => [
    {
        headerName: context.t("Profile Picture"),
        field: "profilePicture",
        minWidth: 147,
        maxWidth: 147,
        filter: "none",
        cellClass: "cell-default-style",
        checkboxSelection: true,
        pinned: "left",
        resizable: true,
        cellRendererSelector(params) {
            return {
                component: PictureCellRenderer,
            };
        },
    },
    {
        headerName: context.t("Name"),
        field: "firstName",
        minWidth: 140,
        cellClass: "cell-default-style",
        filter: "agTextColumnFilter",
        filterParams: {
            cellHeight: 20,
            debounceMs: 100,
            filterOptions: ["contains", "equals", "notEqual", "notContains", "startsWith", "endsWith"],
        },
    },
    {
        headerName: context.t("Last Name"),
        field: "lastName",
        minWidth: 140,
        cellClass: "cell-default-style",
        filter: "agTextColumnFilter",
        filterParams: {
            cellHeight: 20,
            debounceMs: 100,
            filterOptions: ["contains", "equals", "notEqual", "notContains", "startsWith", "endsWith"],
        },
    },
    {
        headerName: context.t("Email"),
        field: "email",
        minWidth: 230,
        cellClass: "cell-default-style",
        chartDataType: "series",
        filter: "agTextColumnFilter",
        filterParams: {
            cellHeight: 20,
            debounceMs: 100,
            filterOptions: ["contains", "equals", "notEqual", "notContains", "startsWith", "endsWith"],
        },
    },
    {
        headerName: context.t("Role"),
        field: "role",
        valueGetter: function checkField(params) {
            return params.data?.role?.name || "none";
        },
        minWidth: 140,
        filterParams: {
            values: ["admin", "hr", "dev"],
        },
        cellClass: "cell-default-style",
    },

    {
        headerName: context.t("Status"),
        field: "status",
        valueGetter: function checkField(params) {
            return params.data?.status?.name || "none";
        },
        minWidth: 140,
        filter: "agSetColumnFilter",
        filterParams: {
            values: ["active", "inactive", "blocked"],
        },
        cellClass: "cell-default-style",
    },
    {
        headerName: context.t("Online Status"),
        field: "loginStatus",
        valueGetter: function checkField(params) {
            return params.data?.loginStatus ? "Online" : "Offline";
        },
        filter: "agSetColumnFilter",
        filterParams: {
            values: ["Online", "Offline"],
        },
        minWidth: 140,
        cellClass: "cell-default-style",
    },
];
const defaultColDef = {
    resizable: true,
    filter: true,
};

const floatingFilter = true;

export const rowSelection = "single";

// use the server-side row model
let rowModelType = "serverSide";
let serverSideStoreType = "partial";

let animateRows = true;


export const GridConfigData = {
    columnDefs,
    defaultColDef,
    rowModelType,
    serverSideStoreType,
    floatingFilter,
    rowSelection,
    animateRows,
};
