const columnDefs = (context) => [
  {
    headerName: context.t("Base"),
    valueGetter: function GetField(params) {
      return params.data.base.code;
    },
    minWidth: 240,
    // checkboxSelection: true,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Quote"),
    valueGetter: function GetField(params) {
      return params.data.quote.code;
    },
    minWidth: 190,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Status"),
    valueGetter: function GetField(params) {
      return params.data.status ? "Active" : "Disabled";
    },
    minWidth: 190,
    cellClass: "cell-default-style",
  },
  {
    headerName: context.t("Value"),
    field: "value",
    minWidth: 190,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};
const floatingFilter = true;

const rowSelection = "single";

export const GridConfigDataER = {
  columnDefs,
  defaultColDef,
  floatingFilter,
  rowSelection,
};
