import React from "react";
import DisplayGlossary from "../../components/glossary/displayGlossary";
import { Box } from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

const Glossary = () => {
  return (
    <Box sx={{
      marginTop,
      marginLeft: drawerWidth,
      [(theme) => theme.breakpoints.down("sm")]: {
        marginLeft: 0,
      },
    }}>
      <Box sx={{
        margin: (theme) => theme.spacing(0, 4),
        display: "flex",
        flexDirection: "column",
      }}>
        <DisplayGlossary />
      </Box>
    </Box>
  );
};
export default Glossary;
