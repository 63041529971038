import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import {
  Typography,
  styled,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Button,
  stepConnectorClasses
} from "@mui/material";
import BonusInformation from "./steps/BonusInformation";
import SelectGames from "./steps/SelectGames";
import Review from "./steps/ReviewBonus";
import { CreateBonusRecordsData } from "../../store/bonus_records/BonusRecordsActions";

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.secondary.main} 100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.main
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}));
const initialForm = {
  name: "",
  games: [],
  code: "",
  condition: "",
  currency: null,
  startDate: new Date(),
  endDate: new Date(),
  duration: 0,
  approveLimit: 0,
  description: "",
  percentage: 100,
  maxAmount: 300,
  maxWageringContribution: 10,
  freeSpins: null,
  wageringRequirement: 35,
  image: null,
  previewImage: null,
};
const ColorlibStepIcon = (props) => {
  const {active, completed} = props;

  const icons = {
    1: <SettingsIcon/>,
    2: <GroupAddIcon/>,
    3: <VideoLabelIcon/>,
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        ...(active && {
          backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,
          boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
        }),
        ...(completed && {
          background: theme.palette.primary.main,
        })
      })}
    >
      {icons[String(props.icon)]}
    </Box>
  );
};
ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const getSteps = (context) => {
  return [
    context.t("Bonus Information"),
    context.t("Select Games"),
    context.t("Preview"),
  ];
};

const GetStepContent = (step, bonusData, gamesFilter) => {
  switch (step) {
    case 0:
      return (
        <BonusInformation bonusData={bonusData} gamesFilter={gamesFilter}/> // TODO CHANGE ON BE
      );
    case 1:
      return <SelectGames bonusData={bonusData} gamesFilter={gamesFilter}/>;
    case 2:
      return <Review bonusData={bonusData} gamesFilter={gamesFilter}/>; // TODO CHANGE ON BE
    default:
      return "Unknown step";
  }
};
const StepperCreateBonus = ({cancel}, context) => {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps(context);
  const [bonusType, setBonusType] = useState(initialForm);
  const [gamesFilters, setGamesFilters] = useState({
    gameProvider: [],
    gameType: "All",
  });
  const firstStepDisabled =
    bonusType.name === "" ||
    bonusType.promoCode === "" ||
    bonusType.approveLimit < 1 ||
    bonusType.duration < 1 ||
    gamesFilters.gameProvider.length === 0;
  const secondStepDisabled = bonusType.games?.length === 0;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("approveLimit", bonusType.approveLimit);
    formData.append("code", bonusType.code);
    formData.append("percentage", bonusType.percentage);
    formData.append("condition", bonusType.condition);
    formData.append("currency", bonusType.currency);
    formData.append("description", bonusType.description);
    formData.append("duration", bonusType.duration);
    formData.append("endDate", bonusType.endDate);
    formData.append("freeSpins", bonusType.freeSpins);
    formData.append(
      "games",
      JSON.stringify(bonusType.games.map((game) => game.id))
    );
    formData.append("image", bonusType.image);
    formData.append("maxAmount", bonusType.maxAmount);
    formData.append(
      "maxWageringContribution",
      bonusType.maxWageringContribution
    );
    formData.append("name", bonusType.name);
    formData.append("startDate", bonusType.startDate);
    formData.append("wageringRequirement", bonusType.wageringRequirement);
    dispatch(CreateBonusRecordsData(formData));
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setBonusType(initialForm);
  };

  return (
    <Box sx={{
      width: "100%",
    }}>
      <Stepper
        sx={{
          marginTop: 3,
          marginBottom: 3,
        }}
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector/>}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography variant="h5" sx={{
              display: "flex",
              justifyContent: "center",
              margin: (theme) => theme.spacing(5, 0),
            }}>
              Bonus Was Created
            </Typography>
            <Button onClick={handleReset} sx={{marginRight: (theme) => theme.spacing(1)}}>
              Create New One
            </Button>
            <Button onClick={cancel} sx={{marginRight: (theme) => theme.spacing(1)}}>
              Close
            </Button>
          </div>
        ) : (
          <div>
            {GetStepContent(
              activeStep,
              {bonusType, setBonusType},
              {gamesFilters, setGamesFilters}
            )}

            <Box sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}>
              <Button onClick={cancel} sx={{marginRight: (theme) => theme.spacing(1)}}>
                Cancel
              </Button>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{marginRight: (theme) => theme.spacing(1)}}
              >
                Back
              </Button>
              <Button
                disabled={
                  (activeStep === 0 && firstStepDisabled) ||
                  (activeStep === 1 && secondStepDisabled)
                }
                variant="contained"
                color="primary"
                onClick={
                  activeStep === steps.length - 1 ? handleSubmit : handleNext
                }
                sx={{marginRight: (theme) => theme.spacing(1)}}
              >
                {activeStep === steps.length - 1 ? "Apply" : "Next"}
              </Button>
            </Box>
          </div>
        )}
      </div>
    </Box>
  );
};

StepperCreateBonus.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default StepperCreateBonus;
