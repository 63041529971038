import * as constants from "./AdminsConstants";
import {
  setNotificationMessage,
  setShowNotificationMessage,
} from "../../../../components/notifications/store/notificationActions";
import getConfig from "../../../../config/getConfig";

export const getAdminsData = (params, filters) => (dispatch) => {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(filters),
  };
  fetch(
    `${getConfig().API_URL_USERS}/admins/get_users?skip=${params.request.startRow}&take=${
      params.request.endRow - params.request.startRow
    }`,
    requestOptions
  )
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error.message);
      }
      params.successCallback(json.users, json.amount);
      dispatch({
        type: constants.REQUEST_ADMINS_SUCCESS,
        payload: json,
      });
    })
    .catch((error) => {
      params.failCallback();
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};
