import * as constants from "./translationConstants";
// import {
//   setNotificationMessage,
//   setShowNotificationMessage,
// } from "../../../../components/notifications/store/notificationActions";
// import config from "../../../../config/getConfig";
import {
  setNotificationMessage,
  setShowNotificationMessage,
} from "../../../components/notifications/store/notificationActions";
import { setLoading } from "../../../components/loading/store/showLoadingActions";
import getConfig from "../../../config/getConfig";
// import { setLoading } from "../../../../components/loading/store/showLoadingActions";

export const getLocaleText = () => (dispatch) => {
  const requestOptions = {
    method: "GET",
    credentials: "include",
  };
  dispatch(setLoading(true));

  fetch(`${getConfig().API_URL_AUTH}/auth/getLocaleText`, requestOptions)
    .then((response) => {
      if (response.status === 204) {
        return { error: "No Records" };
      }
      return response.json();
    })
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch({
        type: constants.REQUEST_GET_LOCALE_TEXT,
        payload: json,
      });
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};

export const updateLocaleText = (data) => (dispatch) => {
  console.log(data)
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  };
  dispatch(setLoading(true));

//   console.log(requestOptions)

  fetch(`${getConfig().API_URL_AUTH}/auth/updateLocaleText`, requestOptions)
    .then((response) => {
      if (response.status === 204) {
        return { error: "No Records" };
      }
      return response.json();
    })
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch({
        type: constants.REQUEST_SET_LOCALE_TEXT,
        payload: json,
      });
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};

// export const getKycData = () => (dispatch, getState) => {
//   const { token } = getState().loginUser;
//   const requestOptions = {
//     method: "GET",
//     headers: { "x-access-token": `${token}` },
//   };
//   dispatch(setLoading(true));

//   fetch(`${config.API_URL}/kyc/get_all_kyc?includeDocuments=true`, requestOptions)
//     .then((response) => {
//       if (response.status === 204) {
//         return { error: "No Records" };
//       }
//       return response.json();
//     })
//     .then((json) => {
//       if (json.error) {
//         throw new Error(json.error);
//       }
//       dispatch({
//         type: constants.REQUEST_KYC_SUCCESS,
//         payload: json,
//       });
//       dispatch(setLoading(false));
//     })
//     .catch((error) => {
//       dispatch(setNotificationMessage(error.message));
//       dispatch(setShowNotificationMessage(true));
//       dispatch(setLoading(false));
//     });
// };
