import React, { useEffect } from "react";
import { TextField, Button, Box } from "@mui/material";
import { getGlossaries, addGlossaries } from "../../containers/glossary/store/GlossaryActions";
import { useDispatch } from "react-redux";

const AddGlossary = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    getGlossaries(1);
  }, []);

  const submit = (e) => {
    e.preventDefault();
    dispatch(
      addGlossaries({
        lanCode: "en_GB",
        content: { heading: e.target.heading.value, content: "" },
      })
    );
  };

  return (
    <div>
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        gap: "1.25rem",
      }}>
        <form onSubmit={submit}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
          >
            <Box sx={{
              paddingTop: "20px",
              paddingBottom: "20px",
            }}>
              <TextField
                id="outlined-multiline-flexible"
                label="Add Glossary"
                multiline
                maxRows={4}
                name="heading"
              />
            </Box>

            <Box sx={{
              marginBottom: "20px",
            }}>
              <Button variant="outlined" type="submit">
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </div>
  )
};

export default AddGlossary;
