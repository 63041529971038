import React, { useState } from "react";
import PropTypes from "prop-types";
import { AppBar, Tabs, Tab, Typography, Box } from "@mui/material";
import BaseReports from "../../../components/reports/BaseReports";
import { reportsDataMock } from "./mock_data/reportsMock";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const drawerWidth = '270px';

export default function ReportTabs() {
  const [reportTab, setReportTab] = useState("one");
  const handleChange = (event, newValue) => {
    setReportTab(newValue);
  };

  return (
    <Box sx={{
      marginLeft: drawerWidth,
      [(theme) => theme.breakpoints.down("sm")]: {
        marginLeft: 0,
      },
    }}>
      <AppBar sx={{
        position: "static!important",
      }}>
        <Tabs
          value={reportTab}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
          textColor="secondary"
          indicatorColor="secondary"
          centered
          sx={{color: 'red'}}
        >
          <Tab
            value="one"
            label="Overall Performance"
            {...a11yProps("one")}
          />
          <Tab value="two" label="Player KPIs" {...a11yProps("two")} />
          <Tab value="three" label="Marketing KPIs" {...a11yProps("three")} />
        </Tabs>
      </AppBar>
      <TabPanel value={reportTab} index="one">
        <Box sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "33% 33% 33%",
          gridGap: "5px",
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "50% 50%",
          },
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "100%",
          },
        })}>
          <div>
            <BaseReports
              type="line"
              title="GGR vs NGR Report"
              data={reportsDataMock.adminGGRvsNGR.line}
            />
          </div>
          <div>
            <BaseReports
              type="line"
              title="NGR to Deposits"
              data={reportsDataMock.NGRtoDeposits.line}
            />
          </div>

          <div>
            <BaseReports
              type="pie"
              title="Country GGR"
              width={100}
              height={50}
              data={reportsDataMock.countries.pie}
            />
          </div>
        </Box>
      </TabPanel>
      <TabPanel value={reportTab} index="two">
        <Box sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "33% 33% 33%",
          gridGap: "5px",
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "50% 50%",
          },
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "100%",
          },
        })}>
          <div>
            <BaseReports
              type="line"
              title="Bets to Deposits"
              data={reportsDataMock.betsToDeposits.line}
            />
          </div>
          <div>
            <BaseReports
              type="line"
              title="Average Revenue per user"
              data={reportsDataMock.averageRev.line}
            />
          </div>
          <div>
            <BaseReports
              type="line"
              title="Bet to Bankroll Ratio"
              data={reportsDataMock.betToBankrollRatio.line}
            />
          </div>
        </Box>
      </TabPanel>
      <TabPanel value={reportTab} index="three">
        <Box sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "33% 33% 33%",
          gridGap: "5px",
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "50% 50%",
          },
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "100%",
          },
        })}>
          <div>
            <BaseReports
              type="bar"
              title="Cost per Aquisition"
              data={reportsDataMock.CPA.bar}
            />
          </div>
          <div>
            <BaseReports
              type="line"
              title="Conversion Rate vs Churn Rate"
              data={reportsDataMock.conversionToChurn.line}
            />
          </div>
        </Box>
      </TabPanel>
    </Box>
  );
}
