import React, { useState, useEffect } from "react";
import StepOne from "./createCampaignStepOne";
import StepTwo from "./createCampaignStepTwo";
import StepThree from "./createCampaignStepThree";
import StepFour from "./createCampaignStepFour";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Box, Stepper, Step, StepButton, Button, Typography } from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

function getSteps(context) {
  return [
    context.t("Select campaign settings"),
    context.t("Select Bonus Type"),
    context.t("Create an ad"),
    context.t("Preview"),
  ];
}

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function CreateCampaign(props, context) {
  const store = useSelector((state) => ({
    stepOneData: state.campaignData.stepOneData,
    stepTwoData: state.campaignData.stepTwoData,
    stepThreeData: state.campaignData.stepThreeData,
  }));
  const steps = getSteps(context);
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState({});
  const {height, width} = useWindowDimensions();

  useEffect(() => {
  }, []);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <StepOne/>;
      case 1:
        return <StepTwo/>;
      case 2:
        return <StepThree/>;
      case 3:
        return <StepFour/>;
      default:
        return "Unknown step";
    }
  };

  const handleNext = () => {
    let newCompleted = completedSteps;

    newCompleted[activeStep] = true;

    setActiveStep(activeStep + 1);
    setCompletedSteps(newCompleted);

    if (activeStep === steps.length - 1) {
      let timer = setTimeout(() => {
        clearTimeout(timer);
        // reset campaign
        setActiveStep(0);
        setCompletedSteps({});
        window.location.reload();
      }, 3000);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStep = (step) => () => {
    if (activeStep === steps.length) return;
    setActiveStep(step);
  };

  return (
    <Box sx={(theme) => ({
      marginTop,
      marginLeft: drawerWidth,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
      },
    })}>
      <Box sx={(theme) => ({
        margin: theme.spacing(0, 4),
        display: "flex",
        flexDirection: "column",
      })}>
        <Typography variant="h5" noWrap paragraph>
          Create Campaign
        </Typography>
        <div/>
        <Stepper
          nonLinear
          activeStep={activeStep}
          orientation={`${width < 600 ? "vertical" : "horizontal"}`}
        >
          {steps.map((label, index) => {
            const stepProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepButton
                  onClick={handleStep(index)}
                  completed={completedSteps[index]}
                >
                  {label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <Box sx={{
              margin: "0 34%",
            }}>
              <Typography variant="h6">
                Congratulations! You successfully published the campaign!
                Campaign ID: 2831
              </Typography>
            </Box>
          ) : (
            <div>
              <Typography component={"div"} sx={(theme) => ({
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
              })}>
                {getStepContent(activeStep)}
              </Typography>
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={(theme) => ({
                    padding: theme.spacing(1.5),
                    minWidth: 100,
                    marginRight: theme.spacing(1),
                    marginBottom: theme.spacing(6),
                  })}
                >
                  {context.t("Back")}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  sx={(theme) => ({
                    padding: theme.spacing(1.5),
                    minWidth: 100,
                    marginRight: theme.spacing(1),
                    marginBottom: theme.spacing(6),
                  })}
                  disabled={
                    activeStep === 0
                      ? store.stepOneData.startDate ===
                      store.stepOneData.endDate ||
                      store.stepOneData.name === "" ||
                      store.stepOneData.targetName.length === 0
                      : activeStep === 1
                        ? Object.keys(store.stepTwoData.chosenBonus).length === 0
                        : activeStep === 2
                          ? Object.keys(store.stepThreeData.chosenTemplate)
                          .length === 0
                          : false
                  }
                >
                  {activeStep === steps.length - 1
                    ? context.t("Publish")
                    : context.t("Next")}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Box>
    </Box>
  );
}

CreateCampaign.contextTypes = {
  t: PropTypes.func.isRequired,
};
