import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getGamesData } from "../../../../../store/games/GamesActions";
import GridConfig from "../../../../../components/grid/GridConfig";
import { checkToken } from "../../../../signin/login_page/store/LoginActions";
import { GridConfigData } from "./grid_data/GridData";
import { Box, Checkbox, Typography, FormControlLabel } from "@mui/material";

const SelectGames = ({bonusData, gamesFilter}, context) => {
  const {gamesFilters} = gamesFilter;
  const {bonusType, setBonusType} = bonusData;
  const [checkAll, setcheckAll] = useState(false);
  const [checkPromoted, setcheckPromoted] = useState(false);
  const store = useSelector((state) => ({
    games: state.Games.games,
  }));
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setcheckAll(event.target.checked);
    if (event.target.checked) {
      gridApi.forEachNode((node) => {
        node.setSelected(true);
      });
    } else {
      gridApi.forEachNode((node) => {
        node.setSelected(false);
      });
    }
  };
  const handlePromoted = (event) => {
    setcheckPromoted(event.target.checked);
  };

  const [gridApi, setGridApi] = useState({});
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onRowSelected = (e) => {
    const selectedRows = gridApi.getSelectedRows();
    if (selectedRows.length !== bonusType.games.length) {
      setBonusType({
        ...bonusType,
        games: selectedRows,
      })
    }
  };

  useEffect(() => {
    dispatch(checkToken(getGamesData(gamesFilters)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box sx={{
        display: "grid",
        justifyItems: "center",
        "& .MuiSvgIcon-root": {color: (theme) => theme.palette.primary.main},
      }}>
        <Typography variant="h5" sx={{
          justifySelf: "start",
        }}>
          {context.t("Select Games")}:
        </Typography>

        <Box sx={{
          justifySelf: "end",
        }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkAll}
                onChange={handleChange}
                name="game"
              />
            }
            label={context.t("Select All")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkPromoted}
                onChange={handlePromoted}
                name="game"
              />
            }
            label={context.t("Show Only Promoted Games")}
          />
        </Box>
        <Box sx={{
          display: "grid",
          minWidth: "500px",
          margin: (theme) => theme.spacing(4, 4),
          gridTemplateColumns: "100%",
        }}>
          <div className="ag-theme-material">
            <Box sx={{
              height: "47vh",
            }}>
              <GridConfig
                props={{
                  GridConfigData,
                  onGridReady,
                  onRowSelected,
                  rowData: store.games
                }}
              />
            </Box>
          </div>
        </Box>
      </Box>
    </>
  );
};

SelectGames.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SelectGames;
