import BusinessIcon from "@mui/icons-material/Business";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ReportIcon from "@mui/icons-material/Report";
import PaymentIcon from "@mui/icons-material/Payment";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import MoneyIcon from "@mui/icons-material/Money";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SettingsIcon from "@mui/icons-material/Settings";
import PeopleIcon from "@mui/icons-material/People";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { authRoles } from "../../helpers/authRoles";
import SigninPage from "../../containers/signin/login_page/Login";

import AdminDashboard from "../../containers/admin/admin_dashboard/AdminDashboard";
import AdminReports from "../../containers/admin/admin_reports/AdminReports";
import AdminManagement from "../../containers/admin/admin_management/AdminManagement";
import MyProfile from "../../containers/admin/my_profile/MyProfile";

import PlayerManagement from "../../containers/player_management/PlayerManagement";
import EditPlayer from "../../containers/player_management/edit_player/EditPlayer";
import KYC from "../../containers/player_management/KYC/KYC";
import PlayerKYC from "../../containers/player_management/KYC/PlayerKYC";

import PlayerActivity from "../../containers/player_reports/player_activity/PlayerActivity";
import OnlinePlayers from "../../containers/player_reports/online_players/OnlinePlayers";

import PaymentsDashboard from "../../containers/payments/payments_dashboard/PaymentsDashboard";
import PlayerPayments from "../../containers/payments/payments_dashboard/player_payments/PlayerPayments";
import PlayerInGameTransactions from "../../containers/payments/payments_dashboard/player_payments/PlayerInGameTransactions";
import WithdrawalTransactions from "../../containers/payments/withdrawal_transactions/WithdrawalTransactions";
import DepositsHistory from "../../containers/payments/deposits_history/DepositsHistory";
import WithdrawalHistory from "../../containers/payments/withdrawal_history/Withdrawal.jsHistory";

import BonusesDashboard from "../../containers/bonuses/bonuses_dashboard/BonusesDashboard";
import BonusTypes from "../../containers/bonuses/bonus_types/BonusTypes";
import BonusesReports from "../../containers/bonuses/bonus_reports/BonusesReports";

import CreateCampaign from "../../containers/crm/create_promo/CreateCampaign";
import CampaignsReports from "../../containers/crm/campaigns_reports/CampaignsReports";

import ResponsibleGaming from "../../containers/responsible_gaming/ResponsibleGaming";
import Restrictions from "../../containers/responsible_gaming/show_more/Restrictions";
import CurrencySettings from "../../containers/settings/currencies/Currencies";

import NotFound from "../not_found/NotFound";
import UnderConstruction from "../under_construction/UnderConstruction";
import ResetPassword from "../../containers/signin/forgot_password/ResetPassword";
import Pages from "../../containers/pages/Pages";
import Languages from "../../containers/languages/Language";
import Translation from "../../containers/translations/Translations";
import Glossary from "../../containers/glossary/Glossary";
export const Routes = [
  {
    accordion: true,
    display: true,
    name: "Reporting & BI",
    icon: BusinessIcon,
    components: [
      {
        name: "Admin",
        icon: SupervisorAccountIcon,
        usersCanSee: authRoles.owner,
        path: "/admin",
        component: AdminDashboard,
      },
      {
        name: "Admin Management",
        icon: ControlPointIcon,
        usersCanSee: authRoles.all,
        path: "/adminManagement",
        component: AdminManagement,
      },
      {
        name: "Reports",
        icon: ReportIcon,
        usersCanSee: authRoles.owner,
        path: "/reports",
        component: AdminReports,
      },
    ],
  },
  {
    accordion: false,
    display: false,
    name: "ResetPassword",
    icon: ReportIcon,
    components: [
      {
        name: "ResetPassword",
        icon: ReportIcon,
        usersCanSee: authRoles.only_guest,
        component: ResetPassword,
        path: "/reset-password/:email/:token",
      },
    ],
  },
  {
    accordion: false,
    display: false,
    name: "My Profile",
    icon: SupervisorAccountIcon,
    components: [
      {
        name: "My Profile",
        icon: SupervisorAccountIcon,
        usersCanSee: authRoles.all,
        path: "/myProfile",
        component: MyProfile,
      },
    ],
  },
  {
    accordion: true,
    display: true,
    name: "Player Management",
    icon: SupervisorAccountIcon,
    components: [
      {
        name: "Player Management",
        icon: PeopleIcon,
        usersCanSee: authRoles.all,
        path: "/",
        component: PlayerManagement,
      },
      {
        name: "KYC",
        icon: VerifiedUserIcon,
        usersCanSee: authRoles.all,
        path: "/Kyc",
        component: KYC,
      },
    ],
  },
  {
    accordion: false,
    display: false,
    name: "Player KYC",
    icon: ControlPointIcon,
    components: [
      {
        name: "Player KYC",
        icon: ControlPointIcon,
        usersCanSee: authRoles.all,
        path: "/playerKyc",
        component: PlayerKYC,
      },
    ],
  },
  {
    accordion: false,
    display: false,
    name: "Edit Player",
    icon: ControlPointIcon,
    components: [
      {
        name: "Edit Player",
        icon: ControlPointIcon,
        usersCanSee: authRoles.all,
        path: "/playerProfile",
        component: EditPlayer,
      },
    ],
  },
  {
    accordion: true,
    display: true,
    name: "Players Reports",
    icon: ReportIcon,
    components: [
      {
        name: "Players Activity",
        icon: ReportIcon,
        usersCanSee: authRoles.owner,
        path: "/playersActivityReport",
        component: PlayerActivity,
      },
      {
        name: "Online Players",
        icon: ReportIcon,
        usersCanSee: authRoles.all,
        path: "/onlinePlayersReport",
        component: OnlinePlayers,
      },
    ],
  },
  {
    accordion: true,
    display: true,
    name: "Payments",
    icon: PaymentIcon,
    components: [
      {
        name: "Payments Dashboard",
        icon: PaymentIcon,
        usersCanSee: authRoles.all,
        path: "/payments",
        component: PaymentsDashboard,
      },
      {
        name: "Deposits History",
        icon: MoneyIcon,
        usersCanSee: authRoles.owner,
        path: "/depositsHistory",
        component: DepositsHistory,
      },
      {
        name: "Pending Withdrawals",
        icon: MoneyIcon,
        usersCanSee: authRoles.owner,
        path: "/pendingWithdrawals",
        component: WithdrawalTransactions,
      },
      {
        name: "Withdrawal History",
        icon: MoneyIcon,
        usersCanSee: authRoles.owner,
        path: "/withdrawalHistory",
        component: WithdrawalHistory,
      },
    ],
  },
  {
    accordion: false,
    display: false,
    name: "Player Payments",
    icon: ControlPointIcon,
    components: [
      {
        name: "Player Payments",
        icon: ControlPointIcon,
        usersCanSee: authRoles.all,
        path: "/playerPayments",
        component: PlayerPayments,
      },
    ],
  },
  {
    accordion: false,
    display: false,
    icon: ControlPointIcon,
    name: "Player In-Game Transactions",
    components: [
      {
        name: "Player In-Game Transactions",
        icon: ControlPointIcon,
        usersCanSee: authRoles.all,
        path: "/playerInGameTransactions",
        component: PlayerInGameTransactions,
      },
    ],
  },
  {
    accordion: true,
    display: true,
    name: "Bonuses",
    icon: MonetizationOnIcon,
    components: [
      {
        name: "Bonuses Dashboard",
        icon: MonetizationOnIcon,
        usersCanSee: authRoles.all,
        path: "/bonuses",
        component: BonusesDashboard,
      },
      {
        name: "Bonus Management",
        icon: MonetizationOnIcon,
        usersCanSee: authRoles.all,
        path: "/bonusManagement",
        component: BonusTypes,
      },
      {
        name: "Bonuses Reports",
        icon: MonetizationOnIcon,
        usersCanSee: authRoles.owner,
        path: "/bonusesReports",
        component: BonusesReports,
      },
    ],
  },
  {
    accordion: true,
    display: true,
    name: "CRM",
    icon: ContactMailIcon,
    components: [
      {
        name: "Create Promo",
        icon: MonetizationOnIcon,
        usersCanSee: authRoles.owner,
        path: "/createCampaign",
        component: CreateCampaign,
      },
      {
        name: "Campaigns Reports",
        icon: BusinessIcon,
        usersCanSee: authRoles.owner,
        path: "/campaignsReports",
        component: CampaignsReports,
      },
    ],
  },
  {
    accordion: false,
    display: true,
    name: "Communications",
    components: [
      {
        name: "Communications",
        icon: QuestionAnswerIcon,
        usersCanSee: authRoles.all,
        path: "/communications",
        component: UnderConstruction,
      },
    ],
  },
  {
    accordion: false,
    display: true,
    name: "Responsible Gaming",
    icon: SportsEsportsIcon,
    components: [
      {
        name: "Responsible Gaming",
        icon: SportsEsportsIcon,
        usersCanSee: authRoles.owner,
        path: "/responsibleGaming",
        component: ResponsibleGaming,
      },
    ],
  },
  {
    accordion: false,
    display: false,
    name: "Player Restrictions",
    icon: ControlPointIcon,
    components: [
      {
        name: "Player Restrictions",
        icon: ControlPointIcon,
        usersCanSee: authRoles.owner,
        path: "/playerRestrictions",
        component: Restrictions,
      },
    ],
  },
  {
    accordion: true,
    display: true,
    name: "Settings",
    icon: SettingsIcon,
    components: [
      {
        name: "Currencies",
        icon: MonetizationOnIcon,
        usersCanSee: authRoles.owner,
        path: "/settings/currencies",
        component: CurrencySettings,
      },
    ],
  },
  {
    accordion: false,
    display: false,
    name: "Login",
    icon: ReportIcon,
    components: [
      {
        name: "Login",
        icon: ReportIcon,
        usersCanSee: authRoles.only_guest,
        path: "/auth",
        component: SigninPage,
      },
    ],
  },
  {
    accordion: false,
    display: true,
    name: "Pages",
    icon: ReportIcon,
    components: [
      {
        name: "Pages",
        icon: ReportIcon,
        usersCanSee: authRoles.all,
        path: "/pages",
        component: Pages,
      },
    ],
  },
  {
    accordion: false,
    display: true,
    name: "Languages",
    icon: ReportIcon,
    components: [
      {
        name: "Languages",
        icon: ReportIcon,
        usersCanSee: authRoles.all,
        path: "/languages",
        component: Languages,
      },
    ],
  },
  {
    accordion: false,
    display: true,
    name: "Translation",
    icon: ReportIcon,
    components: [
      {
        name: "Translation",
        icon: ReportIcon,
        usersCanSee: authRoles.all,
        path: "/translations",
        component: Translation,
      },
    ],
  },
  {
    accordion: false,
    display: true,
    name: "Glossary",
    icon: ReportIcon,
    components: [
      {
        name: "Glossary",
        icon: ReportIcon,
        usersCanSee: authRoles.all,
        path: "/glossary",
        component: Glossary,
      },
    ],
  },
  {
    accordion: false,
    display: false,
    name: "Not Found",
    icon: ReportIcon,
    components: [
      {
        name: "Not Found",
        icon: ReportIcon,
        usersCanSee: authRoles.all,
        path: "*",
        component: NotFound,
      },
    ],
  },
];
