const columnDefs = (context) => [
  {
    headerName: context.t("Name"),
    field: "name",
    minWidth: 240,
    checkboxSelection: true,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Code"),
    field: "code",
    minWidth: 190,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("max Conversion Limit"),
    field: "maxConversionLimit",
    filter: "agNumberColumnFilter",
    minWidth: 190,
    cellClass: "cell-default-style",
  },
  {
    headerName: context.t("min Deposit"),
    field: "minDeposit",
    filter: "agNumberColumnFilter",
    minWidth: 190,
    cellClass: "cell-default-style",
  },
  {
    headerName: context.t("max Deposit"),
    field: "maxDeposit",
    filter: "agNumberColumnFilter",
    minWidth: 190,
    cellClass: "cell-default-style",
  },
  {
    headerName: context.t("min Deposit BO"),
    field: "minMaxAmount",
    filter: "agNumberColumnFilter",
    minWidth: 190,
    cellClass: "cell-default-style",
  },
  {
    headerName: context.t("max Deposit BO"),
    field: "maxMaxDeposit",
    filter: "agNumberColumnFilter",
    minWidth: 190,
    cellClass: "cell-default-style",
  },
  {
    headerName: context.t("min Wagering Contribution"),
    field: "minMaxWageringContribution",
    filter: "agNumberColumnFilter",
    minWidth: 190,
    cellClass: "cell-default-style",
  },
  {
    headerName: context.t("max Wagering Contribution"),
    field: "maxMaxWageringContribution",
    filter: "agNumberColumnFilter",
    minWidth: 190,
    cellClass: "cell-default-style",
  },
  {
    headerName: context.t("Total Bonus Cap"),
    field: "maxApproveLimit",
    filter: "agNumberColumnFilter",
    minWidth: 190,
    cellClass: "cell-default-style",
  },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};
const floatingFilter = true;

const rowSelection = "single";

export const GridConfigDataEX = {
  columnDefs,
  defaultColDef,
  floatingFilter,
  rowSelection,
};
