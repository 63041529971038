import * as constants from "./LanguageConstants";

const initialState = {
  languages: [],
};

export const Languages = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_GET_LANGUAGE_SUCCESS:
      return { languages: action.payload };
    case constants.REQUEST_ADD_LANGUAGE_SUCCESS:
      return { ...state };
    case constants.REQUEST_ENABLE_LANGUAGE_SUCCESS:
      return { ...state };
    case constants.REQUEST_DISABLE_LANGUAGE_SUCCESS:
      return { ...state };
    default:
      return state;
  }
};
