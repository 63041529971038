import React, { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateNodesFromDOM } from "@lexical/html";
import { $getRoot, $getSelection, $createParagraphNode } from "lexical";

export default function GetTextPlugin({ data }) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    const htmlString = data ? data : "";
    editor.update(() => {
      const root = $getRoot();
      const paragraph = $createParagraphNode();
      root.clear();
      root.append(paragraph);

      const parser = new DOMParser();
      const dom = parser.parseFromString(htmlString, "text/html");
      const nodes = $generateNodesFromDOM(editor, dom);
      const selection = $getSelection();
      $getRoot().select();
      selection.insertNodes(nodes);
    });
  }, [data]);
  return null;
}
