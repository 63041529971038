import * as constants from "./playerPaymentsConstant";

const initialState = {
  playerTransactions: [],
};

export const PlayerPayments = (state = initialState, action = {}) => {
  switch (action.type) {

    case constants.REQUEST_PLAYER_TRANSACTIONS_SUCCESS:
      return { ...state, playerTransactions: action.payload};

    default:
      return state;
  }
};
