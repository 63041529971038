import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  Box
} from "@mui/material";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import ColorCard from "../../ColorCard";
import { themesConfig } from "../../../../themes/main";


const ChangeThemeButton = () => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <IconButton
        aria-label="chuoose color scheme"
        aria-controls="Color Change"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        <ColorLensIcon/>
      </IconButton>
      <Box sx={(theme) => ({
        display: "flex",
        padding: "5px 30px 5px 5px",
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      })}
        onClick={handleClick}
      >
        Choose Theme
      </Box>
      <Dialog
        open={open}
        onClose={handleClick}
      >
        <DialogTitle>Choose Theme</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose theme That you would like to see
          </DialogContentText>
          <Box sx={(theme) => ({
            display: "grid",
            gridTemplateColumns: "repeat(1, 1fr);",
            justifyItems: "center",
            gridGap: "10px",
            [theme.breakpoints.up("md")]: {
              gridTemplateColumns: "repeat(2, 1fr);",
            },
          })}>
            {Object.entries(themesConfig).map(([key, value]) => {
              return <ColorCard colors={value.palette} theme={key} key={key}/>;
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClick} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangeThemeButton;
