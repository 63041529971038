import * as constants from "./currenciesConstatns";

const initialState = {
  baseCurrency: "",
  enabledCurrencies: [],
  disabledCurrencies: [],
  exchangeRates: [],
};

export const PlatformConfig = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_BASE_CURRENCY:
      return { ...state, baseCurrency: action.payload };
    case constants.REQUEST_CURRENCIES_SUCCESS:
      return {
        ...state,
        enabledCurrencies: action.payload.enabled,
        disabledCurrencies: action.payload.disabled,
      };
    case constants.REQUEST_EXCHANGE_RATES_SUCCESS:
      return { ...state, exchangeRates: action.payload };
    default:
      return state;
  }
};
