import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Autocomplete from "@mui/lab/Autocomplete";
import Draggable from "react-draggable";
import ClearIcon from "@mui/icons-material/Clear";
import PanToolIcon from "@mui/icons-material/PanTool";
import colors from "../../../../themes/Colors.scss";
import { useSelector, useDispatch } from "react-redux";
import { getGlossaries } from "../../../../containers/glossary/store/GlossaryActions";
import { Box, TextField } from "@mui/material";

const GlossaryContent = (props) => {
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    glossaries:
      state.i18nState.lang === 1 ? state.Glossaries.EN : state.Glossaries.ALT,
    lang: state.i18nState.lang,
  }));

  const [textFieldValue, setTextFieldValue] = useState("");
  const [dragged, setdragged] = useState(true);
  const [searchOptions, setSearchOptions] = useState([]);

  // Load glossaries
  useEffect(() => {
    if (store.lang === 1) {
      dispatch(getGlossaries(store.lang));
    } else {
      dispatch(getGlossaries(store.lang, "ALT"));
    }
  }, [store.lang]);

  useEffect(() => {
    if (store?.glossaries?.content) {
      const options = store.glossaries.content.map((item) => ({
        labelTitle: item.heading,
        alias: item.heading,
      }));
      setSearchOptions(options);
    }
  }, [store.glossaries, store.lang]);
  const getContentName = () => {
    return textFieldValue === ""
      ? pathname === "/"
        ? "PlayersManagement"
        : capitalizeFirstLetter(pathname.slice(1)) // TODO temp fix
      : textFieldValue;
  };

  const handleAutoCompleteChange = (e, value) => {
    if (!value) return;

    setTextFieldValue(value.alias);
  };

  const capitalizeFirstLetter = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const Content = () => {
    let result = "";
    if (store?.glossaries.content) {
      result = store.glossaries.content.filter(
        (item) => item.heading === getContentName()
      );

      if (result.length) {
        return (
          <div>
            <p>{result[0].content}</p>
          </div>
        );
      }
    }
    return <></>;
  };
  const onStop = () => {
    setdragged(true);
  };

  const onControlledDrag = (e, position) => {
    setdragged(false);
  };
  return (
    <>
      <Draggable
        allowAnyClick={true}
        handle=".track"
        onDrag={onControlledDrag}
        onStop={onStop}
      >
        <div className="box">
          <Box
            sx={{
              position: "absolute",
              top: "56px",
              right: "0",
              height: "700px",
              width: "300px",
              zIndex: "1000",
              backgroundColor: "#eff3f6",
              color: "#0e0c28",
              padding: "15px",
              boxShadow: dragged ? colors.shadow : colors.greenShadow
            }}
          >
            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 0",
            }}>
              <PanToolIcon
                className="track"
                style={{cursor: "move"}}
                color="primary"
              />
              <ClearIcon onClick={props.handleClick} color="primary"/>
            </Box>
            <Autocomplete
              sx={{
                "& .MuiSvgIcon-root": {color: (theme) => theme.palette.primary.main},
              }}
              options={searchOptions}
              getOptionLabel={(option) => option.labelTitle}
              onChange={handleAutoCompleteChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <div className="glossary-content-wrapper no-cursor">
              <Content/>
            </div>
          </Box>
        </div>
      </Draggable>
    </>
  );
};

export default GlossaryContent;
