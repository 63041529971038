import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppBar, Toolbar, Typography, IconButton, Link, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { setMenuOpen } from "./store/menu_open/MenuOpenActions";
import MenuTopNav from "./topNavBarElements/Menu";
import MobileMenuTopNav from "./topNavBarElements/MobileMenu";
import claw from "../../assets/claw.png";
import { PageNotifications } from "../notifications/notificationsService";
import getConfig from "../../config/getConfig";

const drawerWidth = 270;

const TopNavBar = () => {
  const state = useSelector((state) => ({
    menuOpen: state.changeMenuOpen.menuOpen,
    showNotificationMessage: state.showNotification.showNotificationMessage,
  }));

  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    dispatch(setMenuOpen(true));
  };

  return (
    <>
      {state.showNotificationMessage ? <PageNotifications/> : ""}
      <AppBar
        position="fixed"
        sx={(theme) => ({
          marginLeft: state.menuOpen ? drawerWidth : 0,
          width: state.menuOpen ? `calc(100% - ${drawerWidth}px)` : "100%",
          zIndex: theme.zIndex.drawer + 2,
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: state.menuOpen
              ? theme.transitions.duration.enteringScreen
              : theme.transitions.duration.leavingScreen,
          }),
          backgroundColor: (theme) => theme.palette.primary.main
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={(theme) => ({
              marginRight: 36,
              display: state.menuOpen ? "none" : "block",
              [theme.breakpoints.up("md")]: {
                display: "none",
              },
            })}
          >
            <MenuIcon/>
          </IconButton>
          <img src={claw} alt="Panthera" width="40" height="40"/>
          <Link href="/" color="inherit" underline="none">
            <Typography variant="h6" noWrap>
              {getConfig().DOMAIN}
            </Typography>
          </Link>

          <Box sx={{flexGrow: 1}}/>
          <Box sx={(theme) => ({
            display: "none",
            [theme.breakpoints.up("md")]: {
              display: "flex",
            },
          })}>
            <MenuTopNav/>
          </Box>
          <Box sx={(theme) => ({
            display: "flex",
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
          })}>
            <MobileMenuTopNav/>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopNavBar;
