import * as constants from "./PageConstants";

const initialState = {
  // pages: null,
  en: null,
  alt: null,
};

export const Pages = (state = initialState, action = {}) => {
  switch (action.type) {
    // case constants.REQUEST_GET_PAGE_DESCRIPTION:
    //   return {
    //     pages: action.payload,
    //   };
    case constants.REQUEST_GET_PAGE_DESCRIPTION_EN:
      return { ...state, en: action.payload };
    case constants.REQUEST_GET_PAGE_DESCRIPTION_ALT:
      return { ...state, alt: action.payload };
    case constants.REQUEST_ADD_PAGE_DESCRIPTION:
      return {
        ...state,
        pages: action.payload,
      };
    case constants.REQUEST_UPDATE_PAGE_DESCRIPTION:
      return {
        ...state,
        pages: action.payload,
      };
    default:
      return state;
  }
};
