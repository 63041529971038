import * as constants from "./BonusEntriesConstants";
import {
  setNotificationMessage,
  setShowNotificationMessage,
} from "../../../../components/notifications/store/notificationActions";
import getConfig from "../../../../config/getConfig";
import { setLoading } from "../../../../components/loading/store/showLoadingActions";

export const getBonusEntriesData = () => (dispatch, getState) => {
  const { token } = getState().loginUser;
  const requestOptions = {
    method: "GET",
    headers: {
      "x-access-token": `${token}`,
      "Content-Type": "application/json",
    },
  };
  dispatch(setLoading(true));

  fetch(`${getConfig().API_URL_BONUSES}/bonuses/all_bonuses`, requestOptions)
    .then((response) => {
      if (response.status === 204) {
       return {error: "No Records"}
      }
      return response.json();
    })
    .then((json) => {
      if (json.errors) {
        json.errors.forEach((er) => {
          throw new Error(er.message);
        });
      }
      dispatch({
        type: constants.REQUEST_BONUS_ENTRIES_SUCCESS,
        payload: json,
      });
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};
