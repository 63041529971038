import * as constants from "./GlossaryConstants";
import {
  setNotificationMessage,
  setShowNotificationMessage,
  setNotificationSeverity,
} from "../../../components/notifications/store/notificationActions";
import getConfig from "../../../config/getConfig";

export const getGlossaries =
  // eslint-disable-next-line default-param-last
  (id = 1, code) =>
  (dispatch) => {
    const requestOptions = {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(`${getConfig().API_URL_AUTH}/auth/getGlossary/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        dispatch({
          type: code
            ? constants.REQUEST_GET_ALT_GLOSSARY
            : constants.REQUEST_GET_GLOSSARY,
          payload: json,
        });
      })
      .catch((error) => {
        dispatch(setNotificationMessage(error.message));
        dispatch(setShowNotificationMessage(true));
      });
  };

export const addGlossaries = (data) => (dispatch) => {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  fetch(`${getConfig().API_URL_AUTH}/auth/addGlossary`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error.message);
      }
      dispatch({
        type: constants.REQUEST_ADD_GLOSSARY,
        payload: json,
      });
      dispatch(setNotificationMessage("Glossary Added"));
      dispatch(setNotificationSeverity("success"));
      dispatch(setShowNotificationMessage(true));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};

export const updateGlossary = (data) => (dispatch) => {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  fetch(`${getConfig().API_URL_AUTH}/auth/updateGlossary`, requestOptions)
    .then((res) => res.json)
    .then((json) => {
      dispatch({
        type: constants.REQUEST_UPDATE_GLOSSARY,
        payload: json,
      });
      dispatch(setNotificationMessage("Glossary Updated"));
      dispatch(setNotificationSeverity("success"));
      dispatch(setShowNotificationMessage(true));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};
