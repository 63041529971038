import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import claw from "../../../assets/claw.png";
import { PageNotifications } from "../../../components/notifications/notificationsService";
import { LoginAction } from "./store/LoginActions";
import { ForgotPasswordRequest } from "../forgot_password/store/ForgotPasswordActions";
import { Box, Avatar, Button, TextField, Typography, IconButton, InputAdornment, alpha } from "@mui/material";

const Login = () => {
  const state = useSelector((state) => ({
    menuOpen: state.changeMenuOpen.menuOpen,
    showNotificationMessage: state.showNotification.showNotificationMessage,
  }));
  const [forgotPass, setForgotPass] = useState(false);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    email: "",
    forgotPassEmail: "",
    password: "",
    showPassword: false,
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(LoginAction({email: form.email, password: form.password}));
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    console.log(form.forgotPassEmail);
    dispatch(
      ForgotPasswordRequest({
        email: form.forgotPassEmail,
        setForm,
        setForgotPass,
      })
    );
  };

  return (
    <>
      {state.showNotificationMessage ? <PageNotifications/> : ""}
      <Box sx={(theme) => ({
        display: "flex",
        minHeight: "100vh",
        justifyContent: "center",
        background: theme.palette.background.default,
        backgroundImage: `url(${claw})`,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
      })}>
        <Box sx={{
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
          alignItems: "center",
        }}>
          {forgotPass ? (
            <Box sx={(theme) => ({
              padding: theme.spacing(2),
              borderRadius: "10px",
              background: alpha(theme.palette.background.paper, 0.95),
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            })}>
              <Avatar sx={(theme) => ({
                background: theme.palette.primary.main,
                margin: theme.spacing(1),
              })}>
                <LockOutlinedIcon/>
              </Avatar>
              <Typography component="h1" variant="h5">
                Please enter your email
              </Typography>
              <Box
                component="form"
                sx={(theme) => ({
                  width: "350px",
                  marginTop: theme.spacing(2),
                  "& .MuiSvgIcon-root": {color: theme.palette.primary.main},
                })} noValidate onSubmit={handleForgotPassword}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  value={form.forgotPassEmail}
                  onChange={(email) => setForm({...form, forgotPassEmail: email.target.value})}
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                    margin: (theme) => theme.spacing(3, 0, 2),
                  }}
                >
                  Send Email
                </Button>
              </Box>
              <Button
                color="primary"
                background="primary"
                onClick={() => {
                  setForgotPass(false);
                }}
              >
                Go Back
              </Button>
            </Box>
          ) : (
            <Box sx={(theme) => ({
              padding: theme.spacing(2),
              borderRadius: "10px",
              background: alpha(theme.palette.background.paper, 0.95),
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            })}>
              <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main}}>
                <LockOutlinedIcon/>
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box
                component="form"
                sx={(theme) => ({
                  width: "350px",
                  marginTop: theme.spacing(2),
                  "& .MuiSvgIcon-root": {color: theme.palette.primary.main},
                })} noValidate onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  value={form.email}
                  onChange={(email) => setForm({...form, email: email.target.value})}
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={form.password}
                  onChange={(password) => setForm({...form, password: password.target.value})}
                  name="password"
                  label="Password"
                  id="password"
                  autoComplete="current-password"
                  type={form.showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setForm({...form, showPassword: !form.showPassword})}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {form.showPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                    margin: (theme) => theme.spacing(3, 0, 2),
                  }}
                >
                  Sign In
                </Button>
              </Box>
              <Button
                color="primary"
                background="primary"
                onClick={() => {
                  setForgotPass(true);
                }}
              >
                Forgot Password?
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Login;
