import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  MenuItem,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  getPlatformCurrencies,
  getExchangeRates,
} from "../../containers/settings/currencies/store/currenciesActions";
import { CreateBonusRecordsData } from "../../containers/bonuses/store/bonus_records/BonusRecordsActions";

const BonusDetails = (props, context) => {
  const {open, close, data} = props;
  const store = useSelector((state) => ({
    gameProviders: state.GameProviders.gameProviders,
    gameTypes: state.Games.gameTypes,
    gameConditions: state.Games.gameConditions,
    enabledCurrencies: state.PlatformConfig.enabledCurrencies,
    exchangeRates: state.PlatformConfig.exchangeRates,
  }));
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data.name || "",
      percentage: data.percentage || "",
      startDate: data.startDate || null,
      endDate: data.endDate || null,
      code: data.code || "",
      description: data.description || "",
      maxAmount: data.maxAmount || null,
      duration: data.duration || null,
      freeSpins: data.freeSpins || null,
      approveLimit: data.approveLimit || null,
      wageringRequirement: data.wageringRequirement || null,
      currency: data.currency?.code || "",
      condition: data.condition ? data.condition.name : null,
      games: data.games ? data.games.map((game) => game.uuid) : [],
      previewImage: data.image,
      image: data.image,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      currency: Yup.string().required("Currency is required"),
      description: Yup.string().required("Description is required"),
      code: Yup.string().required("Code is required"),
    }),
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    setFieldError,
    isValid,
    dirty,
  } = formik;

  useEffect(() => {
    dispatch(getPlatformCurrencies());
    dispatch(getExchangeRates());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClone = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("approveLimit", values.approveLimit);
    formData.append("code", values.code);
    formData.append("percentage", values.percentage);
    formData.append("condition", values.condition);
    formData.append("currency", values.currency);
    formData.append("description", values.description);
    formData.append("duration", values.duration);
    formData.append("endDate", values.endDate);
    formData.append("freeSpins", values.freeSpins);
    formData.append(
      "games",
      JSON.stringify(values.games.map((game) => game.id))
    );
    formData.append("image", values.image);
    formData.append("maxAmount", values.maxAmount);
    formData.append("name", values.name);
    formData.append("startDate", values.startDate);
    formData.append("wageringRequirement", values.wageringRequirement);
    dispatch(CreateBonusRecordsData(formData));
    close();
  };
  const changeCurrency = (currency) => {
    let value;
    if (currency === data.currency.code) {
      value = data.maxAmount;
    } else {
      const exchangeRate = store.exchangeRates.find(
        (el) =>
          el.quote.code === currency && el.base.code === data.currency.code
      );
      value =
        exchangeRate !== undefined
          ? Math.round(exchangeRate.value * data.maxAmount)
          : 0;
    }
    if (value === 0) {
      setFieldError("maxAmount", "No Exchange Rate Found");
    }
    setFieldValue("currency", currency);
    setFieldValue("maxAmount", value);
  };
  const imagePreView = (event) => {
    if (event.currentTarget.files[0] instanceof Blob) {
      setFieldValue("image", event.currentTarget.files[0]);
      const reader = new FileReader();
      reader.onloadend = (event) => {
        const img = new Image();
        img.src = reader.result;
        setFieldValue("previewImage", reader.result);
      };
      reader.readAsDataURL(event.currentTarget.files[0]);
    }
  };
  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle>{data.name}</DialogTitle>
      <DialogContent>
        <Box sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "repeat(2, 50%)",
          gridTemplateAreas: "'column column'",
          justifyItems: "center",
          marginBottom: theme.spacing(5),
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "100%",
            gridTemplateAreas: "'column'",
          },
          "& .MuiSvgIcon-root": {color: theme.palette.primary.main},
        })}>
          <TextField
            id="name"
            label={context.t("Bonus Type Name")}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name ? errors.name : ""}
            value={values.name}
            onChange={handleChange}
            type="text"
            required
            sx={{
              margin: (theme) => theme.spacing(1),
              minWidth: 230,
              maxWidth: 230,
            }}
          />
          <TextField
            id="code"
            label={context.t("Bonus Code")}
            error={touched.code && Boolean(errors.code)}
            helperText={touched.code ? errors.code : ""}
            value={values.code}
            onChange={handleChange}
            type="text"
            required
            sx={{
              margin: (theme) => theme.spacing(1),
              minWidth: 230,
              maxWidth: 230,
            }}
          />
          <DatePicker
            sx={{
              margin: (theme) => theme.spacing(1),
              minWidth: 230,
              maxWidth: 230,
            }}
            autoOk
            required
            views={['year', 'month', 'day']}
            openTo="year"
            disablePast={true}
            label={context.t("Bonus Start Date")}
            format="MM/dd/yyyy"
            value={values.startDate}
            onChange={(date) => setFieldValue("startDate", date)}
            renderInput={(params) => <TextField
              color="secondary"
              {...params}
            />}
          />
          <DatePicker
            sx={{
              margin: (theme) => theme.spacing(1),
              minWidth: 230,
              maxWidth: 230,
            }}
            autoOk
            required
            views={['year', 'month', 'day']}
            openTo="year"
            disablePast={true}
            label={context.t("Bonus End Date")}
            format="MM/dd/yyyy"
            value={values.endDate}
            onChange={(date) => setFieldValue("endDate", date)}
            renderInput={(params) => <TextField
              color="secondary"
              {...params}
            />}
          />
          <FormControl sx={{
            margin: (theme) => theme.spacing(1),
            minWidth: 230,
            maxWidth: 230,
          }}>
            <InputLabel id="choose-currency">{context.t("Choose Currency")}</InputLabel>
            <Select
              label={context.t("Choose Currency")}
              labelId="choose-currency"
              value={values.currency}
              onChange={(_, newValue) => {
                changeCurrency(newValue.props.value);
                setFieldValue("currency", newValue.props.value);
              }}
            >
              {store.enabledCurrencies
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((currency) => (
                <MenuItem value={currency.code}>{currency.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="maxAmount"
            label={context.t("Maximum Bonus Amount")}
            error={Boolean(errors.maxAmount)}
            helperText={errors.maxAmount}
            value={values.maxAmount}
            onChange={handleChange}
            type="number"
            required
            sx={{
              margin: (theme) => theme.spacing(1),
              minWidth: 230,
              maxWidth: 230,
            }}
          />
          <TextField
            id="approveLimit"
            label={context.t("Daily Request Limit")}
            error={Boolean(errors.approveLimit)}
            helperText={errors.approveLimit}
            value={values.approveLimit}
            onChange={handleChange}
            type="number"
            required
            sx={{
              margin: (theme) => theme.spacing(1),
              minWidth: 230,
              maxWidth: 230,
            }}
          />
          <TextField
            name="description"
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description ? errors.description : ""}
            sx={{
              gridColumnStart: "column-start",
              gridColumnEnd: "column-end",
              width: "100%",
            }}
            label={context.t("Bonus Description")}
            value={values.description}
            onChange={handleChange}
            labelWidth={120}
            multiline
            rows={4}
          />
          <Box sx={{
            gridColumnStart: "column-start",
            gridColumnEnd: "column-end",
            width: "100%",
          }}>
            {values.previewImage && (
              <Box component="img" src={values.previewImage} sx={{
                marginTop: "20px",
                width: "300px",
                height: "250px",
                borderRadius: "2%",
                justifyContent: "center",
                marginBottom: "20px",
              }}/>
            )}
          </Box>
          <Box sx={{
            width: "100%",
            marginTop: "20px",
            display: "flex",
            flexDirection: "row",
          }}>
            <Button
              variant="contained"
              component="label"
              sx={{
                marginRight: "20px",
              }}
            >
              {values.previewImage ? "Change" : "Add Image"}
              <input
                type="file"
                hidden
                onChange={(event) => {
                  imagePreView(event);
                }}
              />
            </Button>
            {values.previewImage && (
              <Button
                variant="contained"
                component="label"
                sx={{
                  marginRight: "20px",
                }}
                onClick={() => {
                  setFieldValue("previewImage", "");
                  setFieldValue("image", "");
                }}
              >
                Remove
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Close
        </Button>
        <Button
          onClick={handleClone}
          disabled={!(isValid && dirty && values.code !== data.code)}
          color="primary"
        >
          Clone
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BonusDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BonusDetails;
