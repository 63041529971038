const columnDefs = (context) => [
  {
    headerName: context.t("Name"),
    field: "name",
    minWidth: 240,
    checkboxSelection: true,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Code"),
    field: "code",
    minWidth: 190,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Status"),
    field: "isEnabled",
    valueGetter: function GetField(params) {
        return params.data.isEnabled ? "Enabled" : "Disabled";
      },
    minWidth: 190,
    cellClass: "cell-default-style",
  },
  {
    headerName: context.t("Symbol"),
    field: "symbol",
    minWidth: 190,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Decimal Digits"),
    field: "decimalDigits",
    minWidth: 190,
    cellClass: "cell-default-style",
    hide: true
  },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};
const floatingFilter = true;

const rowSelection = "single";

export const GridConfigData = {
  columnDefs,
  defaultColDef,
  floatingFilter,
  rowSelection,
};
