const columnDefs = (context) => [
  {
    headerName: context.t("Name"),
    valueGetter: function combineField(params) {
      return params.data.firstName === null ? `_` : `${params.data.firstName} ${params.data.lastName}`;
    },
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    minWidth: 200,
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Currency"),
    valueGetter: function checkField(params) {
      return params.data.accounts === null ?  'EUR' : params.data.accounts.filter((el) => el.type.name=== "current")[0].currency.code;
    },
    cellClass: "cell-default-style",
    minWidth: 200,
  },
  {
    headerName: context.t("Real Balance"),
    valueGetter: function checkField(params) {
      return params.data.accounts === null ?  0 : params.data.accounts.filter((el) => el.type.name=== "current")[0].balance;
    },
    cellClass: "cell-default-style",
    filter: "agNumberColumnFilter",
    sortable: true,
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
    minWidth: 200,
  },
  {
    headerName: context.t("Blocked Balance"),
    valueGetter: function checkField(params) {
      return params.data.accounts === null ?  0 : params.data.accounts.filter((el) => el.type.name=== "blocked")[0].balance;
    },
    minWidth: 200,
    cellClass: "cell-default-style",
    chartDataType: "series",
    sortable: true,
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },

  },
  {
    headerName: context.t("Bonus Balance"),
    valueGetter: function checkField(params) {
      return params.data.accounts === null ?  0 : params.data.accounts.filter((el) => el.type.name=== "bonus")[0].balance;
    },
    cellClass: "cell-default-style",
    filter: "agNumberColumnFilter",
    sortable: true,
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
    minWidth: 200,
  },
  {
    headerName: context.t("Country"),
    field: "country",
    cellClass: "cell-default-style",
    minWidth: 200,
  },
//   {
//     headerName: context.t("Total Bets"),
//     field: "totalBets",
//     cellClass: "cell-default-style",
//     filter: "agNumberColumnFilter",
//     sortable: true,
//     filterParams: {
//       filterOptions: ["equals", "lessThan", "greaterThan"],
//     },
//     minWidth: 200,
//   },
//   {
//     headerName: context.t("Total GGR"),
//     field: "totalGgr",
//     cellClass: "cell-default-style",
//     filter: "agNumberColumnFilter",
//     sortable: true,
//     filterParams: {
//       filterOptions: ["equals", "lessThan", "greaterThan"],
//     },
//     minWidth: 200,
//   },
  {
    headerName: context.t("Registration Date"),
    valueGetter: function checkField(params) {
        return new Date(params.data.createdAt).toDateString();
      },
    cellClass: "cell-default-style",
    minWidth: 200,
    filter: "agDateColumnFilter",
    filterParams: {
      filterOptions: ["inRange"],
      comparator(filterLocalDateAtMidnight, cellValue) {
        let dateAsString = cellValue;
        let dateParts = dateAsString.split("/");
        let cellDate = new Date(
          Number(dateParts[2].slice(0, 4)),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
  },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};
const floatingFilter = true;

const rowSelection = "single";

export const GridConfigData = {
  columnDefs,
  defaultColDef,
  floatingFilter,
  rowSelection,
};
