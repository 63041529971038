import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Typography, Button, Box } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Widget from "../../../components/widget/Widget";
import GridConfig from "../../../components/grid/GridConfig";
import ReviewBonus from "./review_bonus/ReviewBonus";
import ApplyBonus from "./apply_bonus/ApplyBonus";
import { GridConfigData } from "./grid_data/GridData";
import { getBonusEntriesData } from "../store/bonus_entries/BonusEntriesActions";
import { getPlayersData } from "../../player_management/store/grid_player_load/gridPlayersActions";

const drawerWidth = '270px';
const marginTop = '100px';


const BonusesDashboard = (_, context) => {
  const store = useSelector((state) => ({
    bonusEntries: state.BonusEntries.bonusEntries,
    activeBonusesFTD: state.BonusEntries.activeBonusesFTD,
    activeBonusesRELOAD: state.BonusEntries.activeBonusesRELOAD,
    activeBonuses: state.BonusEntries.activeBonuses,
    total: state.BonusEntries.total,
    players: state.PlayersData.players,
  }));
  const [gridData, setgridData] = useState([]);
  const [gridApi, setGridApi] = useState({});
  const [bonusInfo, setbonusInfo] = useState({ data: null, disabled: true });
  const [reviewBonus, setreviewBonus] = useState(false);
  const [applyBonus, setapplyBonus] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPlayersData());
    dispatch(getBonusEntriesData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (store.bonusEntries.length !== 0 && store.players.length !== 0) {
      let data = store.bonusEntries.map((entry) => {
        let player = store.players.find(
          (player) => player.uuid === entry.player.uuid
        );
        entry.player = player;
        return entry;
      });
      setgridData(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.bonusEntries, store.players]);
  // Grid
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const onRowSelected = (e) => {
    const focusedCell = gridApi.getFocusedCell();
    const selectedInfo = gridApi.getDisplayedRowAtIndex(focusedCell.rowIndex);
    selectedInfo.selected
      ? setbonusInfo({ data: selectedInfo.data, disabled: false })
      : setbonusInfo({ data: null, disabled: true });
  };

  return (
    <>
      <Box sx={(theme) => ({
        marginTop,
        marginLeft: drawerWidth,
        [theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      })}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("Bonuses Dashboard")}
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("Total Amount Of Bonuses")}
                data={store.total}
                description=""
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name={context.t("Active Bonuses")}
                data={store.activeBonuses}
                description=""
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name={context.t(`Active "First Time Deposit" Bonuses`)}
                data={store.activeBonusesFTD}
                description=""
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name={context.t("Active Reload Bonuses")}
                data={store.activeBonusesRELOAD}
                description=""
                Icon={AccountBalanceIcon}
              />
            }
          </Box>
        </Box>
        <Box sx={{
          display: "grid",
          margin: (theme) => theme.spacing(10, 4),
          gridTemplateColumns: "100%",
        }}>
          <Box sx={{
            justifySelf: "end",
            margin: (theme) => theme.spacing(1, 0),
          }}>
            <Button
              variant="contained"
              sx={(theme) => ({
                margin: theme.spacing(0, 0, 0, 1),
                padding: theme.spacing(1, 2),
                minWidth: 200,
                color: theme.palette.primary.light,
                background: theme.palette.secondary.dark,
              })}
              onClick={(e) => setapplyBonus(true)}
            >
              {context.t("Apply New Bonus")}
            </Button>
            <ApplyBonus open={applyBonus} setOpen={setapplyBonus} />
            <Button
              variant="contained"
              sx={(theme) => ({
                margin: theme.spacing(0, 0, 0, 1),
                padding: theme.spacing(1, 2),
                minWidth: 200,
                color: theme.palette.primary.light,
                background: theme.palette.secondary.dark,
              })}
              disabled={bonusInfo.disabled}
              onClick={(e) => setreviewBonus(true)}
            >
              {context.t("Show More")}
            </Button>
            <ReviewBonus
              open={reviewBonus}
              setOpen={setreviewBonus}
              bonus={bonusInfo.data}
            />
          </Box>
          <div>
            <div className="ag-theme-material">
              <Box sx={{
                height: "50vh",
              }}>
                {gridData.length === 0 ? (
                  <div>
                    <Typography variant="h5" noWrap paragraph>
                      {context.t("No Records")}
                    </Typography>
                  </div>
                ) : (
                  <GridConfig
                    props={{
                      GridConfigData,
                      onGridReady,
                      onRowSelected,
                      rowData: gridData,
                    }}
                  />
                )}
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

BonusesDashboard.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BonusesDashboard;
