import * as constants from "./AdminsConstants";

const initialState = {
  data: [],
  amount: 0
};

export const AdminsData = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_ADMINS_SUCCESS:
      return { ...state, data: action.payload.users, amount: action.payload.amount, isLoading: false };
    default:
      return state;
  }
};
