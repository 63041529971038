import * as constants from "./BonusRecordsConstants";

const initialState = {
  bonusRecords: [],
};

export const BonusRecords = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_BONUS_RECORDS_SUCCESS:
      return { ...state, bonusRecords: action.payload };
    default:
      return state;
  }
};
