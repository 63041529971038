import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  useTheme,
  Box,
  styled,
  Drawer,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuRoutes } from "./MenuItems";
import { setMenuOpen } from "./store/menu_open/MenuOpenActions";

const drawerWidth = '270px';

const CustomLink = styled(Link)(() => ({
  textDecoration: "none",
  color: "inherit",
}));

const SideNav = () => {
  const theme = useTheme();
  const state = useSelector((state) => ({
    menuOpen: state.changeMenuOpen.menuOpen,
    currentPage: state.changeCurrentPage.currentPage,
    role: state.loginUser.role,
  }));
  const dispatch = useDispatch();

  const handleDrawerClose = () => {
    dispatch(setMenuOpen(false));
  };

  const renderMenuElements = (item) => {
    if (item.usersCanSee.includes(state.role)) {
      return (
        <List sx={{
          p: 0,
        }} key={`LIST${item.name}`}>
          <CustomLink
            to={{
              pathname: item.path,
            }}
            id={item.name}
          >
            <ListItem
              button
              key={item.name}
              sx={{
                background: state.currentPage === item.path ? theme.palette.primary.main : "",
                "&:hover": {
                  background: state.currentPage === item.path ? theme.palette.primary.main : "",
                },
              }}

            >
              <ListItemIcon sx={{color: (theme) => theme.palette.secondary.main}}>{<item.icon/>}</ListItemIcon>
              <ListItemText
                primary={item.name}
                primaryTypographyProps={{style: {whiteSpace: "normal"}}}
              />
            </ListItem>
          </CustomLink>
        </List>
      );
    }
  };
  const renderMenu = () => {
    return MenuRoutes.map((route, i) => {
      if (route.display) {
        if (route.accordion) {
          const routes = route.components
          .map((component) => renderMenuElements(component))
          .filter((route) => route !== undefined);

          return (
            <>
              {routes.length !== 0 ? (
                <>
                  <Accordion
                    sx={(theme) => ({
                      expanded: {
                        "&$expanded": {
                          margin: 0,
                        },
                      },
                      background: theme.palette.secondary.light,
                      color: theme.palette.secondary.main,
                      boxShadow: "none",
                      position: "initial",
                    })}
                    square={false}
                    defaultExpanded={route.components.some(
                      (route) => route.path === state.currentPage
                    )}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{
                      color: theme.palette.secondary.main,
                    }}/>}>
                      <route.icon/>
                      <Typography sx={{
                        padding: "0 0 0 30px"
                      }}>{route?.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                      background: "#0a091c!important",
                      display: "flex",
                      flexDirection: "column",
                    }}>
                      {routes}
                    </AccordionDetails>
                  </Accordion>
                </>
              ) : (
                <></>
              )}
            </>
          );
        }
        return <>{route.components.map((component) => renderMenuElements(component))}</>;

      }
      return <></>;

    });
  };
  return (
    <>
      <Drawer
        variant="permanent"
        PaperProps={{
          sx: (theme) => ({

            overflowX: state.menuOpen ? "auto" : "hidden",
            background: theme.palette.secondary.light,
            color: theme.palette.secondary.main,
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: state.menuOpen
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen,
            }),
            width: state.menuOpen ? drawerWidth : "1px",
            flexShrink: 0,
            whiteSpace: "nowrap",
            "& ::-webkit-scrollbar": {
              width: "5px",
            },
            "& ::-webkit-scrollbar-track": {
              background: theme.palette.secondary.light,
            },
            /* Handle */
            "& ::-webkit-scrollbar-thumb": {
              background: theme.palette.primary.light,
            },

            /* Handle on hover */
            "& ::-webkit-scrollbar-thumb:hover": {
              background: theme.palette.primary.dark,
            },
            [theme.breakpoints.up("md")]: {
              width: drawerWidth,
            },
          })
        }}
      >
        <Box sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: theme.spacing(0, 1),
          color: theme.palette.secondary.main + "!important",
          // necessary for content to be below app bar
          ...theme.mixins.toolbar,
        })}>
          <IconButton sx={{
            color: theme.palette.secondary.main,
          }} onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
          </IconButton>
        </Box>
        <Divider/>
        {renderMenu()}
      </Drawer>
    </>
  );
};

export default SideNav;
