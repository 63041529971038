import {
  setNotificationMessage,
  setShowNotificationMessage,
  setNotificationSeverity,
} from "../../components/notifications/store/notificationActions";
import getConfig from "../../config/getConfig";
import * as constants from "./LanguageConstants";
import { setTranslations } from "redux-i18n";

export const GetLanguages = () => (dispatch) => {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };
  fetch(`${getConfig().API_URL_AUTH}/auth/getLanguages`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      dispatch({
        type: constants.REQUEST_GET_LANGUAGE_SUCCESS,
        payload: json,
      });
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};

export const GetTranslations = () => (dispatch) => {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };
  fetch(`${getConfig().API_URL_AUTH}/auth/getLocaleText`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      dispatch({
        type: constants.REQUEST_GET_TRANSLATION_SUCCESS,
        payload: json,
      });
      dispatch(setTranslations(json));
      // console.log(json);
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};

export const SetLanguage = (data) => (dispatch) => {
  // console.log(data)
  dispatch({
    type: constants.REQUEST_SET_LANGUAGE,
    payload: data,
  });
};

// export const addLanguage = (data) => (dispatch) => {
//   const requestOptions = {
//     method: "POST",
//     credentials: "include",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   };
//   fetch(`${config.API_URL}/auth/addLanguage`, requestOptions)
//     .then((response) => response.json())
//     .then((json) => {
//       dispatch(setNotificationMessage("Language Added"));
//       dispatch(setNotificationSeverity("success"));
//       dispatch(setShowNotificationMessage(true));
//       dispatch(getLanguages());
//     })
//     .catch((error) => {
//       dispatch(setNotificationMessage(error.message));
//       dispatch(setShowNotificationMessage(true));
//     });
// };
