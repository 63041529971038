const columnDefs = (context) => [
  {
    headerName: context.t("Campaign ID"),
    field: "id",
    checkboxSelection: true,
    minWidth: 140,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: [
        "contains",
        "equals",
        "notEqual",
        "notContains",
        "startsWith",
        "endsWith",
      ],
    },
  },
  {
    headerName: context.t("Campaign Name"),
    field: "name",
    minWidth: 250,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: [
        "contains",
        "equals",
        "notEqual",
        "notContains",
        "startsWith",
        "endsWith",
      ],
    },
  },
  {
    headerName: context.t("Provider"),
    field: "brand",
    minWidth: 250,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: [
        "contains",
        "equals",
        "notEqual",
        "notContains",
        "startsWith",
        "endsWith",
      ],
    },
  },
  {
    headerName: context.t("Status"),
    field: "status",
    minWidth: 140,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: [
        "contains",
        "equals",
        "notEqual",
        "notContains",
        "startsWith",
        "endsWith",
      ],
    },
  },
  {
    headerName: context.t("CTR") + "%",
    field: "ctr",
    minWidth: 140,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: [
        "contains",
        "equals",
        "notEqual",
        "notContains",
        "startsWith",
        "endsWith",
      ],
    },
  },
  {
    headerName: context.t("Bonus given"),
    field: "bonusGiven",
    minWidth: 140,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: [
        "contains",
        "equals",
        "notEqual",
        "notContains",
        "startsWith",
        "endsWith",
      ],
    },
  },
  {
    headerName: context.t("Bonus spent"),
    field: "bonusSpent",
    minWidth: 140,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: [
        "contains",
        "equals",
        "notEqual",
        "notContains",
        "startsWith",
        "endsWith",
      ],
    },
  },
  {
    headerName: context.t("Start date"),
    field: "startdate",
    minWidth: 140,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: [
        "contains",
        "equals",
        "notEqual",
        "notContains",
        "startsWith",
        "endsWith",
      ],
    },
  },
  {
    headerName: context.t("End date"),
    field: "enddate",
    minWidth: 140,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: [
        "contains",
        "equals",
        "notEqual",
        "notContains",
        "startsWith",
        "endsWith",
      ],
    },
  },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};
const floatingFilter = true;

const rowSelection = "single";

export const GridConfigData = {
  columnDefs,
  defaultColDef,
  floatingFilter,
  rowSelection,
};
