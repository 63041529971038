import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Typography, TextField, Button, Accordion, AccordionSummary, AccordionDetails, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ReportIcon from "@mui/icons-material/Report";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Widget from "../../../components/widget/Widget";
import PlayerNavigation from "../../../components/player_nav/PlayerNavigation";
import { getPlayersData } from "../store/grid_player_load/gridPlayersActions";
import { getParams } from "../../../helpers/getParams";
import { getPlayerKyc, documentApproval } from "./store/kycActions";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from "@mui/x-date-pickers";
import { setNotificationMessage, setShowNotificationMessage } from "../../../components/notifications/store/notificationActions";

dayjs.extend(utc)

const drawerWidth = '270px';
const marginTop = '100px';

const initialStates = {
  decisionDetails: {
    body: null,
    documentUuid: null
  }
}

const DECISION_TYPE = {
  APPROVE: "APPROVE",
  REJECT: "REJECT"
}

const ApproveDialog = ({
  open,
  decisionDetails,
  handleClose,
  handleApproval
}) => {
  const DEFAULT_EXPIRATION_DATE = dayjs().add(6, "month")
  const [expiryTime, setExpiryTime] = useState(DEFAULT_EXPIRATION_DATE)
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
      component: 'form',
      onSubmit: (event) => {
        event.preventDefault();
        const expiryTimeInUTC = dayjs(expiryTime).utc().toISOString()
        handleApproval({ ...decisionDetails.body, expiryTime: expiryTimeInUTC }, decisionDetails.documentUuid)
        handleClose();
    },
  }}>
      <DialogTitle>Approve Document</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To approve any document for kyc, you must mention the expiry time with it.
        </DialogContentText>
        <br />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Expiration"
            value={expiryTime}
            onChange={(newValue) => setExpiryTime(newValue)}
            renderInput={(props) => <TextField {...props} size='small' helperText={null} />}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button type="submit">Approve</Button>
      </DialogActions>
    </Dialog>
  )
}

const RejectDialog = ({
  open,
  decisionDetails,
  handleClose,
  handleApproval
}) => {
  const dispatch = useDispatch()
  const [reason, setReason] = useState("")
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
      component: 'form',
      onSubmit: (event) => {
        event.preventDefault();
        if (reason.length > 0) {
          handleApproval({ ...decisionDetails.body, reason }, decisionDetails.documentUuid)
          handleClose();
        } else {
          dispatch(setShowNotificationMessage(true));
          dispatch(setNotificationMessage("Reason cannot be empty"))
        }
    },
  }}>
      <DialogTitle>Reject Document</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To reject any document for kyc, you must mention the reason with it.
        </DialogContentText>
        <br />
        <TextField
          id="outlined-basic"
          label="Reason for rejection"
          variant="outlined"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit">Reject</Button>
      </DialogActions>
    </Dialog>
  )
}

const PlayerKYC = ({_, history}, context) => {
  const [openApprovalDialog, setOpenApprovalDialog] = useState(false)
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [decisionDetails, setDecisionDetails] = useState(initialStates.decisionDetails)

  const store = useSelector((state) => ({
    data: state.PlayersData.players,
    kyc: state.PlayerKyc.playerKyc,
  }));
  const dispatch = useDispatch();
  // form
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: store.kyc?.name || "",
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
      city: Yup.string().required("City is required"),
      address: Yup.string().required("Address is required"),
      country: Yup.string().required("Country is required"),
      firstName: Yup.string().required("Name is required"),
      lastName: Yup.string().required("Last Name is required"),
    }),
  });

  const {values} = formik;
  const [expandImage, setexpandImage] = useState({
    expand: false,
    index: 0,
    image: null,
  });
  const [playerNav, setplayerNav] = useState({
    id: "",
    page: "",
  });
  useEffect(() => {
    let id = "";
    try {
      id = getParams(history.location.search).id[0];
      setplayerNav({...playerNav, id, page: history.location.pathname});
    } catch {
      id = "not_defined";
    }
    if (id === "not_defined") {
      history.push("/Not_Found");
    }
    dispatch(getPlayersData());
    dispatch(getPlayerKyc(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.kyc.name]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.kyc.documents]);

  const handleOpenDecisionDialog = (body, documentUuid, decisionType) => {
    setDecisionDetails((prev) => ({
      ...prev,
      body,
      documentUuid
    }))
    if (decisionType === DECISION_TYPE.APPROVE) {
      setOpenApprovalDialog(true)
    } else if (decisionType === DECISION_TYPE.REJECT) {
      setOpenRejectDialog(true)
    }
  }

  const handleCloseApprovalDialog = () => {
    setDecisionDetails(initialStates.decisionDetails)
    setOpenApprovalDialog(false)
  }

  const handleCloseRejectDialog = () => {
    setDecisionDetails(initialStates.decisionDetails)
    setOpenRejectDialog(false)
  }

  const handleApproval = (body, documentUuid) => {
    dispatch(documentApproval({ ...body, uuid: documentUuid}));
  };
  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("KYC")}
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("Total Players")}
                data={store.data.length}
                description=""
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Active Players")}
                data={store.data.filter((el) => el.status === "active").length}
                description={context.t("Total Active Players")}
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Inactive Players")}
                data={
                  store.data.filter((el) => el.status === "inactive").length
                }
                description={context.t("Total Inactive Players")}
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Blocked Players")}
                data={store.data.filter((el) => el.status === "blocked").length}
                description={context.t("Permanently Blocked Players")}
                Icon={ReportIcon}
              />
            }
          </Box>
          <Box sx={{
            display: "grid",
            gridTemplateColumns: "20% 80%",
            gridGap: "10px",
            [(theme) => theme.breakpoints.down("md")]: {
              gridTemplateColumns: "100%",
            },
          }}>
            <PlayerNavigation id={playerNav.id} currentPage={playerNav.page}/>
            <Box sx={(theme) => ({
              margin: theme.spacing(5, 0),
              "& .MuiSvgIcon-root": {color: theme.palette.primary.main},
              [theme.breakpoints.down("md")]: {
                margin: theme.spacing(1, 0),
                justifyContent: "center",
              },
            })}>
              <TextField
                disabled
                name="email"
                label={context.t("Name")}
                value={values.firstName}
                variant="outlined"
                sx={{
                  margin: (theme) => theme.spacing(1, 1, 1, 0),
                  minWidth: 350,
                }}
              />
              <Box>
                {store.kyc.documents !== undefined
                  ? store.kyc.documents.length !== 0
                    ? store.kyc.documents
                    .sort((a, b) => b.isLatest - a.isLatest)
                    .map((document, index) => (
                      <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}>
                        <Accordion
                          sx={(theme) => ({
                            background: theme.palette.background.paper,
                            color: theme.palette.primary.main,
                            boxShadow: "none",
                            position: "initial",
                            margin: theme.spacing(0.5, 2),
                            expanded: {
                              "&$expanded": {
                                margin: theme.spacing(0.5, 2),
                              },
                            }
                          })}
                          square={false}
                          defaultExpanded={
                            document.isLatest &&
                            !document.rejected &&
                            !(!document.rejected && document.isReviewed)
                          }
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>
                              {document.type.toUpperCase()} -{" "}
                              {new Date(
                                document.createdAt
                              ).toLocaleString()}
                              {document.isLatest &&
                              ` - ${context.t("Latest")}`}
                              {document.rejected &&
                              ` - ${context.t("Rejected")}`}
                              {!document.rejected &&
                              document.isReviewed &&
                              ` - ${context.t("Approved")}`}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                          >
                            {document.rejected && (
                              <>
                                <Typography variant="caption">
                                  {context.t("Reject Reason")}:{" "}
                                  {document.rejectionReason}
                                </Typography>
                                <br />
                              </>
                            )}
                            {
                             (document.isReviewed && !document.rejected) && document.expiryTime && (
                             <>
                               <Typography>
                                 <Typography variant="caption">
                                   {context.t("Expiration Date: ")}
                                 </Typography>
                                 <Typography variant="caption" color={document.isExpired ? "red": "green"}>
                                   {dayjs(document.expiryTime ?? dayjs()).local()?.format('YYYY-MM-DD')}
                                 </Typography>
                               </Typography>
                               <br />
                             </>
                            )
                            }
                            <Typography variant="caption">
                              *{context.t("Click on Image To Zoom")}
                            </Typography>
                            <Box sx={{
                              display: "flex",
                            }}>
                              {document.files.map((file, fileIndex) => (
                                <>
                                  <Box
                                    component="img"
                                    src={`${file.filePath}`}
                                    alt={file.fileName}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setexpandImage({
                                        expand: true,
                                        index,
                                        fileIndex,
                                        image: `${file.filePath}`,
                                      });
                                    }}
                                    sx={{
                                      width: 200,
                                      margin: (theme) => theme.spacing(1, 1, 1, 0),
                                      cursor: "pointer",
                                      "&:hover": {
                                        opacity: 0.4,
                                      },
                                    }}
                                  />

                                  {expandImage.expand &&
                                  expandImage.index === index &&
                                  expandImage.fileIndex === fileIndex && (
                                    <Box
                                      component="dialog"
                                      sx={{
                                        position: "fixed",
                                        border: "none",
                                        left: "20%",
                                        top: "10%",
                                      }}
                                      open
                                      key={expandImage.index}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setexpandImage({
                                          expand: false,
                                          image: null,
                                        });
                                      }}
                                    >
                                      <Box
                                        component="img"
                                        src={expandImage.image}
                                        alt={file.fileName}
                                        sx={{
                                          width: 600,
                                        }}
                                      />
                                    </Box>
                                  )}
                                </>
                              ))}
                            </Box>
                            {document.isLatest && !document.isReviewed && (
                              <div>
                                <Button
                                  variant="contained"
                                  sx={{
                                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                                  }}
                                  onClick={() =>
                                    handleOpenDecisionDialog(
                                      {
                                        method: "approve",
                                      },
                                      document.uuid,
                                      DECISION_TYPE.APPROVE
                                    )}
                                  color="primary"
                                >
                                  {context.t("Approve")}
                                </Button>
                                <Button
                                  variant="contained"
                                  sx={{
                                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                                  }}
                                  onClick={() =>
                                    handleOpenDecisionDialog(
                                      {
                                        method: "reject",
                                        reason: "Bad Quality",

                                      },
                                      document.uuid,
                                      DECISION_TYPE.REJECT
                                    )}
                                  color="primary"
                                >
                                  {context.t("Reject")}
                                </Button>
                              </div>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    ))
                    : "No Documents"
                  : "NO Documents"}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <ApproveDialog
        open={openApprovalDialog}
        handleClose={handleCloseApprovalDialog}
        decisionDetails={decisionDetails}
        handleApproval={handleApproval}
      />
      <RejectDialog
        open={openRejectDialog}
        handleClose={handleCloseRejectDialog}
        decisionDetails={decisionDetails}
        handleApproval={handleApproval}
      />
    </>
  );
};

PlayerKYC.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PlayerKYC;
