import * as constants from "./TransactionsConstants";
import {
  setNotificationMessage,
  setShowNotificationMessage,
} from "../../../../components/notifications/store/notificationActions";
import getConfig from "../../../../config/getConfig";
import { setLoading } from "../../../../components/loading/store/showLoadingActions";


export const getTransactionsData =
  (type = "deposit", pending = false) =>
  (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    dispatch(setLoading(true));

    fetch(
      `${getConfig().API_URL_PAYMENTS}/payments/all?type=${type}${
        pending ? "&pending=1" : ""
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.error) {
          throw new Error(json.error.message);
        }
        dispatch({
          type: constants.REQUEST_TRANSACTIONS_SUCCESS,
          payload: json,
        });
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(setNotificationMessage(error.message));
        dispatch(setShowNotificationMessage(true));
        dispatch(setLoading(false));
      });
  };
