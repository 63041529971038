import React from "react";
import PropTypes from "prop-types";
import Editor from "../editor";
import MultiSelector from "../multiselect";
import { Box } from "@mui/material";
const Responsible_Gaming = (_) => {
  return (
    <>
      <div>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          margin: "2.5rem 1.5rem",
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: "30.5rem",
          }}>
            <Box sx={{
              width: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "rgba(136, 136, 136, 0.5)",
              color: "rgba(136, 136, 136, 1)",
              fontSize: "1.05rem",
            }}>English</Box>
            <MultiSelector languages={_.languages} setActiveLanguage={_.setActiveLanguage} />
          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1.25rem",
            alignItems: "start",
          }}>
            <Editor data={_.english ? _.english : ""} language={"en_GB"} pageId={3} />
            <Editor
              data={_.alt ? _.alt : ""}
              language={_.activeLanguage === "en_GB" ? null : _.activeLanguage}
              pageId={3}
            />
          </Box>
        </Box>
      </div>
    </>
  );
};

Responsible_Gaming.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Responsible_Gaming;
