import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Avatar,
  Typography,
  Box
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { LogoutAction } from "../../../../containers/signin/login_page/store/LoginActions";

const ProfileTopNav = () => {
  const store = useSelector((state) => ({
    user: state.loginUser.user,
    role: state.loginUser.role
  }));
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const logout = (event) => {
    event.preventDefault();
    dispatch(LogoutAction(store.user.email));
  };

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handleProfileMenuOpen}
      >
        <AccountCircle/>
      </IconButton>
      <Box sx={{
        display: "none",
        padding: "5px 30px 5px 5px",
        [(theme) => theme.breakpoints.up("md")]: {
          display: "flex",
        },
      }}
        onClick={handleProfileMenuOpen}>
        Profile
      </Box>
      <Popover
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
        }}>
          <Avatar alt="Profile Picture" src={store.user?.profilePicture}/>
          <Box sx={{
            display: "grid",
            padding: "0 0 0 5px",
            justifyItems: "center",
          }}>
            <Typography variant="body1" noWrap>
              {`${store.user?.firstName} ${store.user?.lastName}`}
            </Typography>
            <Typography variant="caption" noWrap>
              {store.role}
            </Typography>
          </Box>
        </Box>

        <List component="nav" aria-label="profile loggedin navbar">
          <ListItem button component="a" href="/myProfile">
            <ListItemIcon>
              <AccountBoxIcon color="primary"/>
            </ListItemIcon>
            <ListItemText primary="My Profile"/>
          </ListItem>
          <Divider/>
          <ListItem button component="a" onClick={logout}>
            <ListItemIcon>
              <LockIcon color="primary"/>
            </ListItemIcon>
            <ListItemText primary="Logout"/>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default ProfileTopNav;
