import React from "react";
import { Card, CardActions, CardContent, Button, CardMedia } from "@mui/material";
import emailTemplate from "../../../assets/emailTemplate.png";
import { useSelector, useDispatch } from "react-redux";
import { setStepThreeData } from "./store/createCampaignActions";

export default function ImgMediaCard({renderSelect = true, bonusType}) {
  const store = useSelector((state) => ({
    chosenTemplate: state.campaignData.stepThreeData.chosenTemplate,
  }));
  const dispatch = useDispatch();

  return (
    <>
      {bonusType.map((type, i) => {
        return (
          <Card
            sx={{
              ...(store.chosenTemplate.id === type.id ?
                {
                  boxShadow: "0px 0px 1.5px 2.5px rgba(135,200,71,1)",
                  maxWidth: 445,
                  margin: "15px 10px",
                  "& .bodyText": {
                    color: "black",
                  },
                }
                : {
                  maxWidth: 445,
                  margin: "15px 10px",
                  "& .bodyText": {
                    color: "black",
                  },
                })
            }}
          >
            <CardContent>
              <CardMedia
                sx={{
                  height: 140,
                }}
                image={emailTemplate}
                title="Contemplative Reptile"
              />
            </CardContent>
            <CardActions>
              <Button size="small">Preview</Button>
              {renderSelect ? (
                <Button
                  size="small"
                  onClick={() => {
                    dispatch(setStepThreeData("chosenTemplate", bonusType[i]));
                  }}
                >
                  Select
                </Button>
              ) : (
                <></>
              )}
            </CardActions>
          </Card>
        );
      })}
    </>
  );
}
