import * as constants from "./gridPlayersConstants";
import {
  setNotificationMessage,
  setShowNotificationMessage,
} from "../../../../components/notifications/store/notificationActions";
import getConfig from "../../../../config/getConfig";
import { setLoading } from "../../../../components/loading/store/showLoadingActions";

export const getPlayersData = () => (dispatch, getState) => {
  const requestOptions = {
    method: "GET",
    credentials: "include",
  };
  dispatch(setLoading(true));

  fetch(`${getConfig().API_URL_ADMINS_PLAYERS}/players/get_all`, requestOptions)
    .then((response) => {
      if (response.status === 204) {
        return { error: "No Records" };
      }
      return response.json();
    })
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch({
        type: constants.REQUEST_PLAYERS_SUCCESS,
        payload: json,
      });
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};

export const getKycData = () => (dispatch, getState) => {
  const { token } = getState().loginUser;
  const requestOptions = {
    method: "GET",
    headers: { "x-access-token": `${token}` },
  };
  dispatch(setLoading(true));

  fetch(
    `${getConfig().API_URL_KYC}/kyc/get_all_kyc`,
    requestOptions
  )
    .then((response) => {
      if (response.status === 204) {
        return { error: "No Records" };
      }
      return response.json();
    })
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch({
        type: constants.REQUEST_KYC_SUCCESS,
        payload: json,
      });
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};
