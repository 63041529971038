import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  Checkbox,
  Button,
  Box, TextField
} from "@mui/material";
import Widget from "../../../components/widget/Widget";
import { adminsDataMock } from "./mock_data/AdminDashboardMock";
import GridConfig from "../../../components/grid/GridConfig";
import { GridConfigData, createBottomCalc } from "./grid_data/GridData";
import BaseReports from "../../../components/reports/BaseReports";
import { reportsDataMock } from "./mock_data/reportsMock";
import ReportTabs from "./ReportTabs";
import { getGameProvidersData } from "../../../store/game_providers/GameProvidersActions";
import { getGameTypesData } from "../../../store/games/GamesActions";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const drawerWidth = '270px';
const marginTop = '100px';

const AdminDashboard = (_, context) => {
  const [startDate, handleStartDateChange] = useState(new Date());
  const [endDate, handleEndDateChange] = useState(new Date());
  const [rowData, setRowData] = useState([]);
  const store = useSelector((state) => ({
    gameProviders: state.GameProviders.gameProviders,
    gameTypes: state.Games.gameTypes,
  }));
  const [checkedAll, setCheckedAll] = useState(true);

  const dispatch = useDispatch();
  // Form Data
  const depositProvidersList = store.gameProviders.map((el) => el.name);
  const [DepositProvider, setDepositProvider] = useState(depositProvidersList);
  const changeDepositProvider = (event) => {
    if (!checkedAll && event.target.value.includes("Select all")) {
      setCheckedAll(true);
      setDepositProvider(depositProvidersList);
    }
    if (checkedAll && event.target.value.includes("Select all")) {
      setCheckedAll(false);
      setDepositProvider([]);
    }
    if (!checkedAll && !event.target.value.includes("Select all")) {
      setCheckedAll(false);
      setDepositProvider(event.target.value);
    }
    if (checkedAll && !event.target.value.includes("Select all")) {
      setCheckedAll(false);
      setDepositProvider(event.target.value);
    }
    if (
      !checkedAll &&
      !event.target.value.includes("Select all") &&
      event.target.value.length === depositProvidersList.length
    ) {
      setCheckedAll(true);
      setDepositProvider(event.target.value);
    }
  };
  const [bonusCategory, setbonusCategory] = useState("All");
  const changebonusCategory = (event) => {
    setbonusCategory(event.target.value);
  };
  const filterData = () => {
    let initData = [];
    let finaldata = [];
    DepositProvider.forEach((provider) => {
      initData.push(adminsDataMock.filter((el) => el.brand === provider));
    });
    initData.forEach((comp) => {
      finaldata.push(comp[0]);
    });

    setRowData(finaldata);
  };
  const pinnedBottomRowData = createBottomCalc(rowData);
  const handleSubmit = (e) => {
    e.preventDefault();
    filterData();
  };

  // Grid
  const onGridReady = (params) => {
  };

  const onProviderSelected = (e) => {
  };

  useEffect(() => {
    dispatch(getGameProvidersData());
    dispatch(getGameTypesData());
    setRowData(adminsDataMock);
    setDepositProvider(depositProvidersList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.gameProviders.length]);
  return (
    <>
      <Box sx={(theme) => ({
        marginTop,
        marginLeft: drawerWidth,
        [theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      })}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            Home - Admin Dashboard
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name="Total GGR"
                data="24839"
                description="In a 24-h period"
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name="Total NGR"
                data="13586"
                description="In a 24-h period"
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name="CPA"
                data="38.1"
                description="Average Per User: 13"
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name="CHURN"
                data="1.6%"
                description="In a 24-h period"
                Icon={AccountBalanceIcon}
              />
            }
          </Box>
          <form className="admin-form" onSubmit={handleSubmit}>
            <Box sx={(theme) => ({
              margin: theme.spacing(5, 0),
              display: "flex",
              flexWrap: "wrap",
              [theme.breakpoints.down("md")]: {
                margin: theme.spacing(1, 0),
                justifyContent: "center",
              },
              "& .MuiSvgIcon-root": {color: theme.palette.primary.main},
            })}>
              <FormControl
                sx={{
                  margin: (theme) => theme.spacing(1, 1, 1, 0),
                  minWidth: 350,
                  maxWidth: 350
                }}
                variant="outlined">
                <InputLabel id="game-provider">{context.t("Game Provider")}</InputLabel>
                <Select
                  labelId="game-provider"
                  label={context.t("Game Provider")}
                  multiple
                  value={DepositProvider}
                  onChange={changeDepositProvider}
                  renderValue={(selected) => selected.join(", ")}
                >
                  <MenuItem key={"Select all"} value={"Select all"}>
                    <Checkbox
                      checked={checkedAll}
                      sx={{
                        "& .MuiSvgIcon-root": {color: (theme) => theme.palette.primary.main},
                      }}
                    />
                    <ListItemText
                      primary={
                        checkedAll
                          ? context.t("Select None")
                          : context.t("Select all")
                      }
                    />
                  </MenuItem>
                  {depositProvidersList.map((provider) => (
                    <MenuItem key={provider} value={provider}>
                      <Checkbox
                        checked={DepositProvider.indexOf(provider) > -1}
                        sx={{
                          "& .MuiSvgIcon-root": {color: (theme) => theme.palette.primary.main},
                        }}
                      />
                      <ListItemText primary={provider}/>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  margin: (theme) => theme.spacing(1, 1, 1, 0),
                  minWidth: 350,
                  maxWidth: 350
                }}
                variant="outlined">
                <InputLabel id="game-types">{context.t("Game Types")}</InputLabel>
                <Select
                  labelId="game-types"
                  value={bonusCategory}
                  onChange={changebonusCategory}
                  label={context.t("Game Types")}
                >
                  <MenuItem value={"All"}>{context.t("All")}</MenuItem>
                  {store.gameTypes.map((el) => (
                    <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <DatePicker
                autoOk
                views={['year', 'month', 'day']}
                openTo="year"
                variant="inline"
                inputVariant="outlined"
                label={context.t("Start Date")}
                format="MM/dd/yyyy"
                value={startDate}
                onChange={(date) => handleStartDateChange(date)}
                renderInput={(params) => <TextField
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                    maxWidth: 350,
                    width: '100%',
                  }}
                  {...params}
                />}
              />
              <DatePicker
                autoOk
                views={['year', 'month', 'day']}
                openTo="year"
                variant="inline"
                inputVariant="outlined"
                label={context.t("End Date")}
                format="MM/dd/yyyy"
                value={endDate}
                onChange={(date) => handleEndDateChange(date)}
                renderInput={(params) => <TextField
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                    maxWidth: 350,
                    width: '100%',
                  }}
                  color="secondary"
                  {...params}
                />}
              />
            </Box>
            <Button
              variant="contained"
              sx={(theme) => ({
                padding: theme.spacing(2),
                marginRight: '120px',
                minWidth: 220,
                float: "right",
                color: theme.palette.primary.light,
                background: theme.palette.success.main,
                "&:hover": {
                  background: theme.palette.success.dark,
                },
                [theme.breakpoints.down("sm")]: {
                  marginRight: 20,
                },
              })}
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Box>
        <Box sx={(theme) => ({
          display: "grid",
          margin: theme.spacing(10, 4),
          gridTemplateColumns: "65% 35%",
          gridGap: "10px",
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "100%",
          },
        })}>
          <div>
            <div className="ag-theme-material">
              <div style={{height: "250px"}}>
                <GridConfig
                  props={{
                    GridConfigData,
                    onGridReady,
                    onRowSelected: onProviderSelected,
                    rowData: [...rowData, ...pinnedBottomRowData],
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <BaseReports
              type="line"
              title="7 day GGR report"
              data={reportsDataMock.overallGGR.line}
              label="Net Game Revenue"
            />
          </div>
        </Box>
      </Box>
      <div>
        <ReportTabs/>
      </div>
    </>
  );
};

AdminDashboard.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AdminDashboard;
