import React from "react";
import BonusTypeCard from "./createCampaignTemplatesCard";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";

const bonusTemplates = [
  {
    id: 1,
    image:
      "https://www.getvero.com/wp-content/uploads/2019/03/promotional-email-example-unbounce@2x.jpg",
  },
  {
    id: 2,
    image:
      "https://www.getvero.com/wp-content/uploads/2019/03/promotional-email-example-unbounce@2x.jpg",
  },
];

const StepThree = () => {
  return (
    <>
      <Box sx={{
        display: "grid",
        gridTemplateColumns: "45% 5% 45%",
        justifyItems: "center",
      }}>
        <Box sx={{
          maxHeight: "80vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
          <h3>Select Template</h3>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "100%",
            }}
          >
            <BonusTypeCard bonusType={bonusTemplates} />
          </div>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
        }}>
          <Box sx={{
            borderLeft: "1px solid black",
            minHeight: "20vh",
            margin: "0 0 0 5px",
          }} />
          <h5>Or</h5>
          <Box sx={{
            borderLeft: "1px solid black",
            minHeight: "20vh",
            margin: "0 0 0 5px",
          }} />
        </Box>
        <Box sx={{
          maxHeight: "80vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
          <h3>Create Template</h3>
          <Box sx={{
            margin: "40% 0",
          }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                margin: "0 10px",
              }}
            >
              Upload Template
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

StepThree.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default StepThree;
