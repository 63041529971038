import * as constants from "./filtersConstants";

export const setCurrentFilter = (filter) => ({
  type: constants.CHANGE_FILTER,
  payload: filter,
});

export const removeFilter = (filter) => ({
  type: constants.REMOVE_FILTER,
  payload: filter,
});


export const addFilter = (filter) => ({
    type: constants.ADD_FILTER,
    payload: filter,
  });


