const columnDefs = (context) => [
  {
    headerName: context.t("Name"),
    field: "name",
    minWidth: 140,
    checkboxSelection: true,

    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: [
        "contains",
        "equals",
        "notEqual",
        "notContains",
        "startsWith",
        "endsWith",
      ],
    },
  },

  {
    headerName: context.t("Game Provider"),
    field: "gameProvider",
    valueGetter: function checkField(params) {
      return params.data?.gameProvider?.name || "none";
    },
    minWidth: 140,
    // filter: "none",
    cellClass: "cell-default-style",
  },

  {
    headerName: context.t("Type"),
    field: "gameType",
    // filter: "none",
    valueGetter: function checkField(params) {
      return params.data?.gameType?.name || "none";
    },
    minWidth: 140,
    cellClass: "cell-default-style",
  },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};

const floatingFilter = true;

export const rowSelection = "multiple";

// // use the server-side row model
// let rowModelType = "serverSide";
// let serverSideStoreType = "full"
let animateRows = true;
let sideBar = false;

export const GridConfigData = {
  columnDefs,
  defaultColDef,
  floatingFilter,
  rowSelection,
  sideBar,
  animateRows,
};
