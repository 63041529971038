import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Widget from "../../../components/widget/Widget";
import { campaignsReportsChartsDataMock } from "./mock_data/CampaignsReportsChartsDataMock";
import { campaignReportsDataMock } from "./mock_data/CampaignsReportsDataMock";
import GridConfig from "../../../components/grid/GridConfig";
import { GridConfigData } from "./grid_data/GridData";
import BaseReports from "../../../components/reports/BaseReports";
import { getGameProvidersData } from "../../../store/game_providers/GameProvidersActions";
import { getGameTypesData } from "../../../store/games/GamesActions";
import EditCampaign from "./edit_campaign/EditCampaign";
import {
  Box,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  Checkbox,
  Button
} from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

const CampaignsReports = (_, context) => {
  const store = useSelector((state) => ({
    gameProviders: state.GameProviders.gameProviders,
    gameTypes: state.Games.gameTypes,
  }));
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState({});
  const [rowData, setRowData] = useState([]);
  const [checkedAll, setCheckedAll] = useState(true);
  const [startDate, handleStartDateChange] = useState(new Date());
  const [endDate, handleEndDateChange] = useState(new Date());
  const [bonusCategory, setBonusCategory] = useState("All");
  const [openEditCampaign, setOpenEditCampaign] = useState(false);
  const [campaign, setCampaign] = useState({
    data: null,
    disabled: true,
  });
  const depositProvidersList = store.gameProviders.map((el) => el.name);
  const [DepositProvider, setDepositProvider] = useState(depositProvidersList);

  useEffect(() => {
    dispatch(getGameProvidersData());
    dispatch(getGameTypesData());
    setRowData(campaignReportsDataMock);
    setDepositProvider(depositProvidersList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onRowSelected = (e) => {
    const focusedCell = gridApi.getFocusedCell();
    const selectedInfo = gridApi.getDisplayedRowAtIndex(focusedCell.rowIndex);

    selectedInfo.selected
      ? setCampaign({data: selectedInfo.data, disabled: false})
      : setCampaign({data: null, disabled: true});
  };

  const changeDepositProvider = (event) => {
    if (!checkedAll && event.target.value.includes("Select all")) {
      setCheckedAll(true);
      setDepositProvider(depositProvidersList);
    }
    if (checkedAll && event.target.value.includes("Select all")) {
      setCheckedAll(false);
      setDepositProvider([]);
    }
    if (!checkedAll && !event.target.value.includes("Select all")) {
      setCheckedAll(false);
      setDepositProvider(event.target.value);
    }
    if (checkedAll && !event.target.value.includes("Select all")) {
      setCheckedAll(false);
      setDepositProvider(event.target.value);
    }
    if (
      !checkedAll &&
      !event.target.value.includes("Select all") &&
      event.target.value.length === depositProvidersList.length
    ) {
      setCheckedAll(true);
      setDepositProvider(event.target.value);
    }
  };

  const changebonusCategory = (event) => {
    setBonusCategory(event.target.value);
  };
  const filterData = () => {
    let initData = [];
    let finaldata = [];
    DepositProvider.forEach((provider) => {
      initData.push(
        campaignReportsDataMock.filter((el) => el.brand === provider)
      );
    });
    initData.forEach((comp) => {
      finaldata.push(comp[0]);
    });

    setRowData(finaldata);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    filterData();
  };

  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            Campaigns Reports
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name="Active Campaigns"
                data="12"
                description="Total campaigns: 38"
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name="Bonus given in 24h"
                data="12565"
                description="Amount given to 382 players"
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name="Bonus spent in 24h"
                data="3159"
                description="Players who met wagger req: 42"
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name="Bonuses claimed  in 24h"
                data="482"
                description="Bonuses claimed this week: 4892"
                Icon={AccountBalanceIcon}
              />
            }
          </Box>
          <form className="admin-form" onSubmit={handleSubmit}>
            <Box sx={(theme) => ({
              margin: theme.spacing(5, 0),
              display: "flex",
              flexWrap: "wrap",
              [theme.breakpoints.down("md")]: {
                margin: theme.spacing(1, 0),
                justifyContent: "center",
              },
              "& .MuiSvgIcon-root": {color: theme.palette.primary.main},
            })}>
              <FormControl
                sx={{
                  margin: (theme) => theme.spacing(1, 1, 1, 0),
                  minWidth: 350,
                  maxWidth: 350,
                }}
                variant="outlined">
                <InputLabel>{context.t("Game Provider")}</InputLabel>
                <Select
                  multiple
                  value={DepositProvider}
                  onChange={changeDepositProvider}
                  renderValue={(selected) => selected.join(", ")}
                  labelWidth={120}
                >
                  <MenuItem key={"Select all"} value={"Select all"}>
                    <Checkbox
                      checked={checkedAll}
                      sx={{
                        "& .MuiSvgIcon-root": {color: (theme) => theme.palette.primary.main},
                      }}
                    />
                    <ListItemText
                      primary={
                        checkedAll
                          ? context.t("Select None")
                          : context.t("Select all")
                      }
                    />
                  </MenuItem>
                  {depositProvidersList.map((provider) => (
                    <MenuItem key={provider} value={provider}>
                      <Checkbox
                        checked={DepositProvider.indexOf(provider) > -1}
                        sx={{
                          "& .MuiSvgIcon-root": {color: (theme) => theme.palette.primary.main},
                        }}
                      />
                      <ListItemText primary={provider}/>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  margin: (theme) => theme.spacing(1, 1, 1, 0),
                  minWidth: 350,
                  maxWidth: 350,
                }}
                variant="outlined">
                <InputLabel>{context.t("Game Types")}</InputLabel>
                <Select
                  value={bonusCategory}
                  onChange={changebonusCategory}
                  labelWidth={120}
                >
                  <MenuItem value={"All"}>{context.t("All")}</MenuItem>
                  {store.gameTypes.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <DatePicker
                autoOk
                views={['year', 'month', 'day']}
                openTo="year"
                label={context.t("Start Date")}
                format="MM/dd/yyyy"
                value={startDate}
                onChange={(date) => handleStartDateChange(date)}
                renderInput={(params) => <TextField
                  color="secondary"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                    maxWidth: 350,
                  }}
                  {...params}
                />}
              />
              <DatePicker
                autoOk
                views={['year', 'month', 'day']}
                openTo="year"
                label={context.t("End Date")}
                format="MM/dd/yyyy"
                value={endDate}
                minDate={startDate}
                onChange={(date) => handleEndDateChange(date)}
                renderInput={(params) => <TextField
                  color="secondary"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                    maxWidth: 350,
                  }}
                  {...params}
                />}
              />
            </Box>
            <Button
              variant="contained"
              sx={(theme) => ({
                padding: theme.spacing(2),
                marginRight: '120px',
                minWidth: 220,
                float: "right",
                color: theme.palette.primary.light,
                background: theme.palette.success.main,
                "&:hover": {
                  background: theme.palette.success.dark,
                },
                [theme.breakpoints.down("sm")]: {
                  marginRight: 20,
                },
              })}
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Box>
        <Box sx={{
          display: "grid",
          margin: (theme) => theme.spacing(5, 4),
          gridTemplateColumns: "100%",
          gridGap: "10px",
        }}>
          <Box sx={(theme) => ({
            display: "flex",
            justifyContent: "flex-end",
            margin: theme.spacing(1, 0),
            [theme.breakpoints.down("sm")]: {
              flexWrap: "wrap",
            },
          })}>
            <Box sx={{
              textAlign: "right",
              [(theme) => theme.breakpoints.down("sm")]: {
                textAlign: "center",
              },
            }}>
              <Button
                variant="contained"
                sx={(theme) => ({
                  padding: theme.spacing(1, 2),
                  margin: theme.spacing(1, 1),
                  minWidth: 200,
                  color: theme.palette.primary.light,
                  background: theme.palette.secondary.dark,
                  "&:hover": {
                    background: theme.palette.primary.dark,
                  },
                })}
                disabled={campaign.disabled}
                onClick={(e) => setOpenEditCampaign(true)}
              >
                {context.t("Campaign Details")}
              </Button>
              <Button
                variant="contained"
                sx={(theme) => ({
                  padding: theme.spacing(1, 2),
                  minWidth: 200,
                  color: theme.palette.primary.light,
                  background: theme.palette.error.main,
                  "&:hover": {
                    background: theme.palette.error.dark,
                  },
                })}
                disabled={campaign.disabled}
                onClick={(e) => setOpenEditCampaign(true)}
              >
                {context.t("Deactivate Campaign")}
              </Button>
            </Box>
          </Box>
          {openEditCampaign ? (
            <EditCampaign
              editCampaign={openEditCampaign}
              setOpenEditCampaign={setOpenEditCampaign}
              data={campaign.data}
            />
          ) : (
            <></>
          )}
          <div>
            <div className="ag-theme-material">
              <Box sx={{
                height: "50vh",
              }}>
                <GridConfig
                  props={{
                    GridConfigData,
                    onGridReady,
                    onRowSelected,
                    rowData: [...rowData],
                  }}
                />
              </Box>
            </div>
          </div>
        </Box>
      </Box>
      <Box sx={{
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={(theme) => ({
          margin: theme.spacing(5),
          display: "grid",
          gridTemplateColumns: "50% 50%",
          gridGap: "5px",
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "100%",
          },
        })}>
          <div>
            <BaseReports
              type="bar"
              title="CTR vs Bonus claim Report"
              data={campaignsReportsChartsDataMock.newreg.bar}
            />
          </div>
          <div>
            <BaseReports
              type="pie"
              title="Popular games"
              data={campaignsReportsChartsDataMock.popularGames.pie}
            />
          </div>
          <div>
            <BaseReports
              type="line"
              title="Campaign NGR"
              data={campaignsReportsChartsDataMock.campaignNGR.line}
            />
          </div>
          <div>
            <BaseReports
              type="line"
              title="Bonus spent vs Bonus given"
              data={campaignsReportsChartsDataMock.bonusSpent.line}
            />
          </div>
        </Box>
      </Box>
    </>
  );
};

CampaignsReports.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CampaignsReports;
