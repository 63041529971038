import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ReportIcon from "@mui/icons-material/Report";
import { MuiTelInput } from 'mui-tel-input';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Widget from "../../../components/widget/Widget";
import GridConfig from "../../../components/grid/GridConfig";
import PlayerNavigation from "../../../components/player_nav/PlayerNavigation";
import { GridConfigData } from "./grid_data/GridData";
import { getPlayerInfo, UpdatePlayer } from "../store/player_actions/playerActions";
import { getPlayersData } from "../store/grid_player_load/gridPlayersActions";
import { getParams } from "../../../helpers/getParams";
import { countries, countryToFlag } from "../../../helpers/countries";
import { getPlayerKyc } from "../KYC/store/kycActions";
import {
  Box,
  Autocomplete,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Button
} from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

const EditPlayer = ({_, history}, context) => {
  const store = useSelector((state) => ({
    data: state.PlayersData.players,
    player: state.PlayerData.player,
    loginUser: state.loginUser,
    kyc: state.PlayerKyc.playerKyc,
  }));
  const dispatch = useDispatch();
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: store.player?.firstName || "",
      lastName: store.player?.lastName || "",
      username: store.player?.username || "",
      email: store.player?.email || "",
      phoneNumber: store.player?.phoneNumber || "",
      currency: store.player.accounts
        ? store.player.accounts[0].currency.code
        : "",
      sex: store.player?.sex || "",
      status: store.player.status?.name || "",
      country: store.player?.country || null,
      city: store.player?.city || "",
      address: store.player?.address || "",
      referral: store.player?.referral || "",
      marketingTag: store.player?.marketingTag || "",
      dob: store.player?.dob || null,
      loginStatus: store.player?.loginStatus || "",
      createdAt: store.player?.createdAt || "",
      updatedAt: store.player?.updatedAt || "",
      verifiedAt: store.player?.verifiedAt || null,
      kycStatus: store.player?.kyc?.approved || false,
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
      city: Yup.string().required("City is required"),
      address: Yup.string().required("Address is required"),
      country: Yup.string().required("Country is required"),
      firstName: Yup.string().required("Name is required"),
      lastName: Yup.string().required("Last Name is required"),
    }),
  });

  const {
    values,
    initialValues,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = formik;

  const [editPlayer, setEditPlayer] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (editPlayer) {
      let diff = Object.keys(values).reduce((diff, key) => {
        if (initialValues[key] === values[key]) return diff;
        return {
          ...diff,
          [key]: values[key],
        };
      }, {});
      dispatch(UpdatePlayer(playerNav.id, diff));
    }
    setEditPlayer((prevValue) => !prevValue);
  };
  const [playerNav, setplayerNav] = useState({
    id: "",
    page: "",
  });
  useEffect(() => {
    let id = "";
    try {
      id = getParams(history.location.search).id[0];

      setplayerNav({...playerNav, id, page: history.location.pathname});
    } catch {
      id = "not_defined";
    }
    if (id === "not_defined") {
      history.push("/Not_Found");
    }
    dispatch(getPlayersData());
    dispatch(getPlayerInfo(id));
    dispatch(getPlayerKyc(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.player.id]);
  // Grid

  const onGridReady = (params) => {
  };
  const onRowSelected = (e) => {
  };
  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            Player Account
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("Total Players")}
                data={store.data.length}
                description=""
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Active Players")}
                data={store.data.filter((el) => el.status === "active").length}
                description={context.t("Total Active Players")}
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Inactive Players")}
                data={
                  store.data.filter((el) => el.status === "inactive").length
                }
                description={context.t("Total Inactive Players")}
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Blocked Players")}
                data={store.data.filter((el) => el.status === "blocked").length}
                description={context.t("Permanently Blocked Players")}
                Icon={ReportIcon}
              />
            }
          </Box>
          <Box sx={{
            display: "grid",
            gridTemplateColumns: "20% 80%",
            gridGap: "10px",
            [(theme) => theme.breakpoints.down("md")]: {
              gridTemplateColumns: "100%",
            },
          }}>
            <PlayerNavigation id={playerNav.id} currentPage={playerNav.page}/>
            <form className="admin-form" onSubmit={handleSubmit}>
              <Box sx={(theme) => ({
                margin: theme.spacing(5, 0),
                display: "flex",
                flexWrap: "wrap",
                "& .MuiSvgIcon-root": {color: theme.palette.primary.main},
                [theme.breakpoints.down("md")]: {
                  margin: theme.spacing(1, 0),
                  justifyContent: "center",
                },
              })}>
                <TextField
                  required
                  name="firstName"
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName ? errors.firstName : ""}
                  label={context.t("Name")}
                  onBlur={handleBlur}
                  value={values.firstName}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={
                    editPlayer ? (store.kyc.idApproved ? true : false) : true
                  }
                />

                <TextField
                  required
                  name="lastName"
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName ? errors.firstName : ""}
                  label={context.t("Last Name")}
                  onBlur={handleBlur}
                  value={values.lastName}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={
                    editPlayer ? (store.kyc.idApproved ? true : false) : true
                  }
                />
                <TextField
                  name="username"
                  error={touched.username && Boolean(errors.username)}
                  helperText={touched.username ? errors.username : ""}
                  label={context.t("Username")}
                  onBlur={handleBlur}
                  value={values.username}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={!editPlayer}
                />
                <TextField
                  required
                  name="email"
                  type="email"
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email ? errors.email : ""}
                  label={context.t("Email")}
                  onBlur={handleBlur}
                  value={values.email}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={!editPlayer}
                />
                <MuiTelInput
                  variant="outlined"
                  name="phoneNumber"
                  error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                  helperText={touched.phoneNumber ? errors.phoneNumber : ""}
                  label={context.t("Phone")}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                  onChange={(newValue) => {
                    setFieldValue("phoneNumber", newValue ? newValue : "");
                  }}
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={!editPlayer}
                />
                <DatePicker
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  required
                  disable
                  autoOk
                  views={['year', 'month', 'day']}
                  openTo="year"
                  disabled={
                    editPlayer ? (store.kyc.idApproved ? true : false) : true
                  }
                  label={context.t("Date Of Birth")}
                  format="MM/dd/yyyy"
                  name="dob"
                  error={touched.dob && Boolean(errors.dob)}
                  helperText={touched.dob ? errors.dob : ""}
                  onBlur={handleBlur}
                  value={values.dob}
                  onChange={(newValue) => {
                    setFieldValue("dob", newValue ? newValue : "");
                  }}
                  renderInput={(params) => <TextField
                    sx={{
                      margin: (theme) => theme.spacing(1, 1, 1, 0),
                      minWidth: 350,
                      maxWidth: 350,
                    }}
                    error={touched.dob && Boolean(errors.dob)}
                    helperText={touched.dob ? errors.dob : ""}
                    {...params}
                  />}
                />
                <TextField
                  name="currency"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  label={context.t("Currency")}
                  value={values.currency}
                  variant="outlined"
                  labelWidth={120}
                  disabled
                />
                <FormControl
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={!editPlayer}
                >
                  <InputLabel id="sex">{context.t("Sex")}</InputLabel>
                  <Select
                    labelId="sex"
                    label={context.t("Sex")}
                    value={values.sex}
                    onChange={(_, newValue) => {
                      setFieldValue(
                        "sex",
                        newValue ? newValue.props.value : "male"
                      );
                    }}
                    labelWidth={60}
                  >
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={!editPlayer}
                >
                  <InputLabel id="status">{context.t("Status")}</InputLabel>
                  <Select
                    labelId="status"
                    label={context.t("Status")}
                    value={values.status}
                    onChange={(_, newValue) => {
                      setFieldValue(
                        "status",
                        newValue ? newValue.props.value : "active"
                      );
                    }}
                    labelWidth={60}
                  >
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"inactive"}>Inactive</MenuItem>
                    <MenuItem value={"blocked"}>Blocked</MenuItem>
                  </Select>
                </FormControl>
                <Autocomplete
                  disabled={
                    editPlayer
                      ? store.kyc.addressApproved
                        ? true
                        : false
                      : true
                  }
                  options={countries}
                  value={
                    values.country !== null
                      ? countries.filter(
                        (country) => country.label === values.country
                      )[0]
                      : ""
                  }
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  name="country"
                  onChange={(_, newValue) => {
                    setFieldValue("country", newValue ? newValue.label : "");
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option, state) => (
                    <li {...props}>
                      <Box component="span" mr={1}>{countryToFlag(option.code)}</Box>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      variant="outlined"
                      label={context.t("Country")}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <TextField
                  disabled={
                    editPlayer
                      ? store.kyc.addressApproved
                        ? true
                        : false
                      : true
                  }
                  name="city"
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city ? errors.city : ""}
                  label={context.t("City")}
                  onBlur={handleBlur}
                  value={values.city}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                />
                <TextField
                  disabled={
                    editPlayer
                      ? store.kyc.addressApproved
                        ? true
                        : false
                      : true
                  }
                  required
                  name="address"
                  error={touched.address && Boolean(errors.address)}
                  helperText={touched.address ? errors.address : ""}
                  label={context.t("Address")}
                  onBlur={handleBlur}
                  value={values.address}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                />
                <TextField
                  name="referral"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  label={context.t("Referral")}
                  value={values.referral}
                  variant="outlined"
                  labelWidth={120}
                  disabled
                />
                <TextField
                  name="marketingTag"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  label={context.t("Marketing Tag")}
                  value={values.marketingTag}
                  variant="outlined"
                  labelWidth={120}
                  disabled
                />
                <TextField
                  name="loginStatus"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  label={context.t("Logged In")}
                  value={values.loginStatus ? "Online" : "Offline"}
                  variant="outlined"
                  labelWidth={120}
                  disabled
                />
                <TextField
                  name="createdAt"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  label={context.t("Registration Time")}
                  value={new Date(values.createdAt).toLocaleDateString(
                    _,
                    options
                  )}
                  variant="outlined"
                  labelWidth={120}
                  disabled
                />
                <TextField
                  name="updatedAt"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  label={context.t("Last Update Time")}
                  value={new Date(values.updatedAt).toLocaleDateString(
                    _,
                    options
                  )}
                  variant="outlined"
                  labelWidth={120}
                  disabled
                />
                <TextField
                  name="verifiedAt"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  label={context.t("Account Verified")}
                  value={
                    values.verifiedAt !== null
                      ? new Date(values.verifiedAt).toLocaleDateString(
                        _,
                        options
                      )
                      : "Not Verified"
                  }
                  variant="outlined"
                  labelWidth={120}
                  disabled
                />
                <TextField
                  name="kycStatus"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  label={context.t("KYC")}
                  value={
                    values.kycStatus
                      ? context.t("Approved")
                      : context.t("Not Approved")
                  }
                  variant="outlined"
                  labelWidth={120}
                  disabled
                />
              </Box>
              {store?.loginUser?.role === "admin" && (
                <Button
                  variant="contained"
                  disabled={editPlayer && !(isValid && dirty)}
                  sx={(theme) => ({
                    padding: theme.spacing(2),
                    marginRight: '120px',
                    minWidth: 220,
                    float: "right",
                    color: theme.palette.primary.light,
                    background: theme.palette.success.main,
                    "&:hover": {
                      background: theme.palette.success.dark,
                    },
                    [theme.breakpoints.down("sm")]: {
                      marginRight: 20,
                    },
                  })}
                  type="submit"
                >
                  {editPlayer ? context.t("Submit") : context.t("Edit")}
                </Button>
              )}
            </form>
          </Box>
          <Typography variant="h6" noWrap paragraph>
            {context.t("Login Records")}:
          </Typography>
          <div>
            <div className="ag-theme-material">
              <Box sx={{
                height: "50vh",
              }}>
                <GridConfig
                  props={{
                    GridConfigData,
                    onGridReady,
                    onRowSelected,
                    rowData: store.player.loginHistory,
                  }}
                />
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

EditPlayer.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default EditPlayer;
