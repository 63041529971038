import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { resetPassword } from "../store/admin_actions/adminActions";
import { checkToken } from "../../../signin/login_page/store/LoginActions";

const ResetPassword = ({open, setOpen, data}, context) => {
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(checkToken(resetPassword(data.uuid)));
    setOpen(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setOpen(false);
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <>
      <Dialog open={open} onClose={handleCancel} maxWidth="xs">
        <DialogTitle sx={(theme) => ({
          color: theme.palette.primary.light,
          background: theme.palette.primary.main,
        })}>
          {context.t("Reset Password")}
        </DialogTitle>
        <DialogContent sx={{
          display: "flex",
          flexDirection: "column",
          whiteSpace: "normal",
        }}>
          <Typography variant="body1" mt={2}>
            {`${context.t(
              "Are you sure you want to send the recovery password email to: "
            )} ${data.firstName} ${data.lastName}`}
          </Typography>
        </DialogContent>
        <DialogActions sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button onClick={handleSubmit} color="primary">
            Send
          </Button>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ResetPassword.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ResetPassword;
