import React from "react";
import PropTypes from "prop-types";
import PersonIcon from "@mui/icons-material/Person";
import PaymentIcon from "@mui/icons-material/Payment";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import BlockIcon from "@mui/icons-material/Block";
import { Box, Link } from "@mui/material";

const PlayerNavigation = ({id, currentPage}, context) => {
  const menuItems = [
    {
      icon: PersonIcon,
      path: "/playerProfile",
      text: context.t("Profile"),
    },
    {
      icon: VerifiedUserIcon,
      path: "/playerKyc",
      text: context.t("KYC"),
    },
    {
      icon: PaymentIcon,
      path: "/playerPayments",
      text: context.t("Payments"),
    },
    {
      icon: PaymentIcon,
      path: "/playerInGameTransactions",
      text: context.t("In-Game"),
    },
    {
      icon: BlockIcon,
      path: "/playerRestrictions",
      text: context.t("Restrictions"),
    },
  ];
  const renderMenu = (menu) => {
    if (menu.path === currentPage) {
      return (
        <>
          <Box sx={(theme) => ({
            margin: theme.spacing(2),
            padding: theme.spacing(2, 2),
            background: theme.palette.primary.main,
            color: theme.palette.primary.light,
            borderRadius: "10px",
          })}>
            <Box sx={{
              display: "flex",
              alignItems: "center",

            }}>
              <menu.icon fontSize="small" color="secondary"/>
              <Box component="span" ml={1}>{menu.text}</Box>
            </Box>
          </Box>
        </>
      );
    }
    return (
      <>
        <Box sx={(theme) => ({
          margin: theme.spacing(2),
          padding: theme.spacing(2, 2),
          background: theme.palette.background.paper,
          color: theme.palette.primary.main,
          borderRadius: "10px",
        })}>
          <Link href={`${menu.path}?id=${id}`} sx={{
            textDecoration: "none",
            color: "inherit",
          }}>
            <Box sx={{
              display: "flex",
              alignItems: "center",
            }}>
              <menu.icon fontSize="small" color="primary"/>
              <Box component="span" ml={1}>{menu.text}</Box>
            </Box>
          </Link>
        </Box>
      </>
    );

  };
  return (
    <>
      <Box sx={(theme) => ({
        margin: theme.spacing(4, 0),
        borderRight: `1px solid ${theme.palette.primary.main}`,
        [theme.breakpoints.down("md")]: {
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          borderRight: "none",
        },
      })}>
        {menuItems.map((menuEl) => renderMenu(menuEl))}
      </Box>
    </>
  );
};
PlayerNavigation.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PlayerNavigation;
