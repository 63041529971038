const getConfig = () => {
    return {
        API_URL_AUTH: process.env.REACT_APP_API_URL_AUTH,
        API_URL_BONUSES: process.env.REACT_APP_API_URL_BONUSES,
        API_URL_GAME_PROVIDERS: process.env.REACT_APP_API_URL_GAME_PROVIDERS,
        API_URL_MASTER: process.env.REACT_APP_API_URL_MASTER,
        API_URL_PAYMENTS: process.env.REACT_APP_API_URL_PAYMENTS,
        API_URL_ADMINS_PLAYERS: process.env.REACT_APP_API_URL_ADMINS_PLAYERS,
        API_URL_TRANSACTIONS: process.env.REACT_APP_API_URL_TRANSACTIONS,
        API_URL_USERS: process.env.REACT_APP_API_URL_USERS,
        API_URL_KYC: process.env.REACT_APP_API_URL_KYC,
        DOMAIN: process.env.REACT_APP_DOMAIN
    }
}

export default getConfig;
