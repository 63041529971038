import React from "react";
import PropTypes from "prop-types";
import { DialogTitle, DialogContent, Dialog } from "@mui/material";
import Stepper from "./StepperApplyBonus"

const ApplyBonus = ({open, setOpen}, context) => {
  const handleGoBack = (e) => {
    e.preventDefault();
    setOpen(false);
  };
  return (
    <>
      <Dialog open={open} maxWidth="xl">
        <DialogTitle sx={(theme) => ({
          color: theme.palette.primary.light,
          background: theme.palette.primary.main,
        })}>
          {context.t("Apply New Bonus")}
        </DialogTitle>
        <DialogContent sx={{
          display: "flex",
          flexDirection: "column",
          whiteSpace: "nowrap",
        }}>

          <Stepper cancel={handleGoBack}/>
        </DialogContent>
      </Dialog>
    </>
  );
};

ApplyBonus.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ApplyBonus;
