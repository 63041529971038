import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReportIcon from "@mui/icons-material/Report";

import Widget from "../../../../components/widget/Widget";
import BaseReports from "../../../../components/reports/BaseReports";
import { campaignsReportsChartsDataMock } from "../mock_data/CampaignsReportsChartsDataMock";
import {
  Box,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

const EditCampaign = ({editCampaign, setOpenEditCampaign, data}, context) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      brand: "",
      status: "",
      ctr: "",
      bonusgiven: "",
      bonusspent: "",
      startdate: "",
      enddate: "",
      city: "",
      role: "",
    },

    validationSchema: Yup.object().shape({}),
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = formik;

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenEditCampaign(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setOpenEditCampaign(false);
  };
  useEffect(() => {
    formik.setValues({
      name: data ? data.name : "",
      brand: data ? data.brand : "",
      status: data ? data.status.name : "",
      ctr: data ? data.ctr : "",
      bonusgiven: data ? data.bonusgiven : "",
      bonusspent: data ? data.bonusspent : "",
      startdate: data ? data.startdate : "",
      enddate: data ? data.enddate : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <>
      <Dialog open={editCampaign} onClose={handleCancel} maxWidth="lg">
        <DialogTitle sx={(theme) => ({
          color: theme.palette.primary.light,
          background: theme.palette.primary.main,
        })}>
          {context.t("Campaign Details")}
        </DialogTitle>
        <DialogContent sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("Total Bonuses Given")}
                data="12"
                description="Total Campaign CTR: 1.9%"
                Icon={ReportIcon}
              />
            }
            {
              <Widget
                name={context.t("Bonus given in 24hs")}
                data="12565"
                description={context.t("Amount given to 382 players")}
                Icon={ReportIcon}
              />
            }
            {
              <Widget
                name={context.t("Bonus spent in 24h")}
                data="3159"
                description={context.t("Players who met wagger req: 42")}
                Icon={ReportIcon}
              />
            }
            {
              <Widget
                name={context.t("Bonuses claimed  in 24h")}
                data="482"
                description={context.t("Bonuses claimed this week: 4892")}
                Icon={ReportIcon}
              />
            }
          </Box>
          <Box sx={{
            display: "grid",
            gridTemplateColumns: "100%",
            gridGap: "10px",
          }}>
            <form className="admin-form">
              <Box sx={(theme) => ({
                margin: theme.spacing(5, 0),
                display: "flex",
                flexWrap: "wrap",
                "& .MuiSvgIcon-root": {color: theme.palette.primary.main},
                [theme.breakpoints.down("md")]: {
                  margin: theme.spacing(1, 0),
                  justifyContent: "center",
                },
              })}>
                <TextField
                  required
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name ? errors.name : ""}
                  label={context.t("Campaign Name")}
                  onBlur={handleBlur}
                  value={values.name}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={!editCampaign}
                />
                <FormControl
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={!editCampaign}
                >
                  <InputLabel>{context.t("Status")}</InputLabel>
                  <Select
                    value={values.status}
                    onChange={(_, newValue) => {
                      setFieldValue(
                        "status",
                        newValue ? newValue.props.value : "active"
                      );
                    }}
                    labelWidth={60}
                  >
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"expired"}>Expired</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  required
                  name="brand"
                  error={touched.brand && Boolean(errors.brand)}
                  helperText={touched.brand ? errors.brand : ""}
                  label={context.t("Brand")}
                  onBlur={handleBlur}
                  value={values.brand}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={true}
                />
                <TextField
                  required
                  name="ctr"
                  error={touched.ctr && Boolean(errors.ctr)}
                  helperText={touched.ctr ? errors.ctr : ""}
                  label={context.t("CTR %")}
                  onBlur={handleBlur}
                  value={values.ctr}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={true}
                />
                <TextField
                  required
                  name="bonusgiven"
                  error={touched.bonusgiven && Boolean(errors.bonusgiven)}
                  helperText={touched.bonusgiven ? errors.bonusgiven : ""}
                  label={context.t("Bonus Given")}
                  onBlur={handleBlur}
                  value={values.bonusgiven}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={true}
                />
                <TextField
                  required
                  name="bonusspent"
                  error={touched.bonusspent && Boolean(errors.bonusspent)}
                  helperText={touched.bonusspent ? errors.bonusspent : ""}
                  label={context.t("Bonus Spent")}
                  onBlur={handleBlur}
                  value={values.bonusspent}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={true}
                />
                <TextField
                  required
                  name="startdate"
                  error={touched.startdate && Boolean(errors.startdate)}
                  helperText={touched.startdate ? errors.startdate : ""}
                  label={context.t("Start Date")}
                  onBlur={handleBlur}
                  value={values.startdate}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={true}
                />
                <TextField
                  required
                  name="enddate"
                  error={touched.enddate && Boolean(errors.enddate)}
                  helperText={touched.enddate ? errors.enddate : ""}
                  label={context.t("End Date")}
                  onBlur={handleBlur}
                  value={values.enddate}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  disabled={true}
                />
              </Box>
            </form>

            <Box sx={{
              [(theme) => theme.breakpoints.down("sm")]: {
                marginLeft: 0,
              },
            }}>
              <Box sx={(theme) => ({
                margin: theme.spacing(5),
                display: "grid",
                gridTemplateColumns: "50% 50%",
                gridGap: "5px",
                [theme.breakpoints.down("sm")]: {
                  gridTemplateColumns: "100%",
                },
              })}>
                <div>
                  <BaseReports
                    type="bar"
                    title="CTR vs Bonus claim Report"
                    data={campaignsReportsChartsDataMock.newreg.bar}
                  />
                </div>
                <div>
                  <BaseReports
                    type="pie"
                    title="Popular games"
                    data={campaignsReportsChartsDataMock.popularGames.pie}
                  />
                </div>
                <div>
                  <BaseReports
                    type="line"
                    title="Campaign NGR"
                    data={campaignsReportsChartsDataMock.campaignNGR.line}
                  />
                </div>
                <div>
                  <BaseReports
                    type="line"
                    title="Bonus spent vs Bonus given"
                    data={campaignsReportsChartsDataMock.bonusSpent.line}
                  />
                </div>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={!(isValid && dirty)}
          >
            Save
          </Button>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

EditCampaign.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default EditCampaign;
