import React from "react";
import {CssBaseline, Box} from "@mui/material";
import TopNavBar from './TopNavBar'
import SideNav from "./SideNav"

const Navigation = () => {
  return (
    <Box sx={{
      display: "flex",
    }}>
      <CssBaseline/>
      <TopNavBar/>
      <SideNav/>
    </Box>
  );
};

export default Navigation;
