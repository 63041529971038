import * as constants from "./inGameTransactionsConstants";

const initialState = {
  inGameTransactions: [],
};

export const InGameTransactions = (state = initialState, action = {}) => {
  switch (action.type) {

    case constants.REQUEST_INGAME_TRANSACTIONS_SUCCESS:
      return { ...state, inGameTransactions: action.payload.transactions};

    default:
      return state;
  }
};
