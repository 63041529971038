import React, {useMemo} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import PropTypes from "prop-types";
import './Grid.scss'
import { localeTextAGGrid } from "../../translations/agGridTranslations";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(
  "Airhead_Ventures_Ltd_pantheraplatform_single_1_Devs__10_January_2021_[v2]_MTYxMDIzNjgwMDAwMA==f2dc4ccbfd110ee12c39ae34a6eef741"
);

const onFirstDataRendered = (params) => {
  params.api.sizeColumnsToFit();
};

const onGridSizeChanged = (params) => {
  params.api.sizeColumnsToFit();
};

const onRowDataChanged = (params) => {
  params.api.sizeColumnsToFit();
};

const GridConfig = (props, context) => {
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  let rowSelection = "single";
  let columnDefs = [];
  let defaultColDef = {};
  let floatingFilter = false;
  let rowClassRules = {};
  let pagination = true;
  let pinnedBottomRowData = null;
  let paginationAutoPageSize = true;
  let colResizeDefault = "shift";
  let sideBar = {
    toolPanels: [
      "filters",
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true,
          suppressColumnFilter: false,
          suppressColumnSelectAll: false,
          suppressColumnExpandAll: false,
        },
      },
    ],
  };

  const { GridConfigData, onGridReady, onRowSelected, rowData, getRowStyle } =
    props.props;

  const localeText = localeTextAGGrid(context);

  const initGridData = {
    rowSelection,
    columnDefs,
    defaultColDef,
    onGridReady,
    floatingFilter,
    onRowSelected,
    onRowDataChanged,
    rowClassRules,
    getRowStyle,
    rowData,
    onFirstDataRendered,
    onGridSizeChanged,
    pagination,
    paginationAutoPageSize,
    sideBar,
    localeText,
    colResizeDefault,
    pinnedBottomRowData,
  };

  const modifiedGridData = useMemo(() => ({
    ...initGridData,
    ...GridConfigData,
    columnDefs: GridConfigData.columnDefs(context),
  }), [initGridData, GridConfigData])


  return (
    <>
      <div
        className="ag-legacy"
        style={gridStyle}
      >
        <AgGridReact {...modifiedGridData} />
      </div>
    </>
  );
};
GridConfig.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default GridConfig;
