import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Typography, Button, Box } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Widget from "../../../components/widget/Widget";
import GridConfig from "../../../components/grid/GridConfig";
import { GridConfigData } from "./grid_data/GridData";
import { getBonusRecordsData } from "../store/bonus_records/BonusRecordsActions";
import ReviewBonusRecord from "./review_bonus_record/ReviewBonusRecord";
import BaseReports from "../../../components/reports/BaseReports";
import { reportsDataMock } from "./mock_data/reportsMock";

const drawerWidth = '270px';
const marginTop = '100px';

const BonusesReports = (_, context) => {
  const store = useSelector((state) => ({
    bonusRecords: state.BonusRecords.bonusRecords,
  }));
  const [gridApi, setGridApi] = useState({});
  const [bonusRecordInfo, setbonusRecordInfo] = useState({
    data: null,
    disabled: true,
  });
  const [reviewBonusRecord, setreviewBonusRecord] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBonusRecordsData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Grid
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const onRowSelected = (e) => {
    const focusedCell = gridApi.getFocusedCell();
    const selectedInfo = gridApi.getDisplayedRowAtIndex(focusedCell.rowIndex);
    selectedInfo.selected
      ? setbonusRecordInfo({ data: selectedInfo.data, disabled: false })
      : setbonusRecordInfo({ data: null, disabled: true });
  };

  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("Bonuses Reports")}
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("Bonus given in 24h")}
                data={store.bonusRecords.length}
                description=""
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name={context.t("Bonus spent in 24h")}
                data={store.bonusRecords.length}
                description=""
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name={context.t("Bonuses claimed in 24h")}
                data={store.bonusRecords.length}
                description=""
                Icon={AccountBalanceIcon}
              />
            }
          </Box>
        </Box>
        <Box sx={{
          display: "grid",
          margin: (theme) => theme.spacing(10, 4),
          gridTemplateColumns: "100%",
        }}>
          <Box sx={{
            justifySelf: "end",
            margin: (theme) => theme.spacing(1, 0),
          }}>
            <Button
              variant="contained"
              sx={(theme) => ({
                margin: theme.spacing(0, 0, 0, 1),
                padding: theme.spacing(1, 2),
                minWidth: 200,
                color: theme.palette.primary.light,
                background: theme.palette.secondary.dark,
              })}
              disabled={bonusRecordInfo.disabled}
              onClick={(e) => setreviewBonusRecord(true)}
            >
              {context.t("Show Performance Report")}
            </Button>

            <ReviewBonusRecord
              open={reviewBonusRecord}
              setOpen={setreviewBonusRecord}
              data={bonusRecordInfo.data}
            />
          </Box>
          <div>
            <div className="ag-theme-material">
              <Box sx={{
                height: "50vh",
              }}>
                {store.bonusRecords.length === 0 ? (
                  <div>
                    <Typography variant="h5" noWrap paragraph>
                      {context.t("No Records")}
                    </Typography>
                  </div>
                ) : (
                  <GridConfig
                    props={{
                    GridConfigData,
                    onGridReady,
                    onRowSelected,
                    rowData: store.bonusRecords,
                  }}
                />
                )}

              </Box>
            </div>
          </div>
          <Box sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 50%)",
            [(theme) => theme.breakpoints.down("md")]: {
              gridTemplateColumns: "100%",
            },
          }}>
            <Box sx={{
              padding: (theme) => theme.spacing(2)
            }}>
              <BaseReports
                type="bar"
                title="CTR vs Claim Report"
                data={reportsDataMock.newreg.bar}
              />
            </Box>
            <Box sx={{
              padding: (theme) => theme.spacing(2)
            }}>
              <BaseReports
                type="line"
                title="Spent vs Given Report"
                data={reportsDataMock.bonusSpent.line}
              />
            </Box>
            <Box sx={{
              padding: (theme) => theme.spacing(2)
            }}>
              <BaseReports
                type="pie"
                title="Game Providers Report"
                data={reportsDataMock.popularGames.pie}
              />
            </Box>
            <Box sx={{
              padding: (theme) => theme.spacing(2)
            }}>
              <BaseReports
                type="line"
                title="NGR Report"
                data={reportsDataMock.bonusNGR.line}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

BonusesReports.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BonusesReports;
