const columnDefs = (context) => [
  {
    headerName: context.t("Name"),
    valueGetter: function combineField(params) {
      return params.data.firstName === null ? `_` : `${params.data.firstName} ${params.data.lastName}`;
    },
    minWidth: 240,
    checkboxSelection: true,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Email"),
    field: "email",
    minWidth: 190,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Status"),
    valueGetter: function combineField(params) {
      return params.data.status === null ? `none` :  params.data.status.name;
    },
    minWidth: 140,
    cellClass: "cell-default-style",
    chartDataType: "series",
  },
  // {
  //   headerName: context.t("Affiliate Id"),
  //   field: "affiliateId",
  //   cellClass: "cell-default-style",
  //   chartDataType: "series",
  //   filter: "agTextColumnFilter",
  //   filterParams: {
  //     filterOptions: ["equals", "lessThan", "greaterThan"],
  //   },
  //   hide: true,
  // },
  {
    headerName: context.t("Country"),
    field: "country",
    minWidth: 140,
    cellClass: "cell-default-style",
    chartDataType: "series",
  },
  {
    headerName: context.t("Registration Time"),
    valueGetter: function checkField(params) {
      return new Date(params.data.createdAt).toLocaleString();
    },
    minWidth: 140,
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: 'agDateColumnFilter',
    hide: true,
  },
  {
    headerName: context.t("Last Login Time"),
    valueGetter: function checkField(params) {
      return params.data.loginHistory.length === 0 ?  `No Records` : new Date(params.data.loginHistory[0].login_time).toDateString();
    },
    minWidth: 140,
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
    },
    hide: true,
  },
  {
    headerName: context.t("Cash Balance"),
    valueGetter: function checkField(params) {
      return params.data.accounts === null ?  0 : params.data.accounts.filter((el) => el.type.name=== "current")[0].balance;
    },
    minWidth: 160,
    maxWidth: 160,
    cellClass: "cell-default-style",
    chartDataType: "series",
    sortable: true,
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
  },
  {
    headerName: context.t("Blocked Balance"),
    valueGetter: function checkField(params) {
      return params.data.accounts === null ?  0 : params.data.accounts.filter((el) => el.type.name=== "blocked")[0].balance;
    },
    minWidth: 160,
    maxWidth: 160,
    cellClass: "cell-default-style",
    chartDataType: "series",
    sortable: true,
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
  },
  {
    headerName: context.t("Promo Balance"),
    valueGetter: function checkField(params) {
      return params.data.accounts === null ?  0 : params.data.accounts.filter((el) => el.type.name=== "bonus")[0].balance;
    },
    minWidth: 160,
    maxWidth: 160,
    cellClass: "cell-default-style",
    chartDataType: "series",
    sortable: true,
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
  },
  {
    headerName: context.t("Validated"),
    field: "verifiedAt",
    valueGetter: function checkField(params) {
      return params.data.verifiedAt === null ?  `Not Verified` : new Date(params.data.verifiedAt).toDateString();
    },
    minWidth: 140,
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
    },
    hide: true,
  },
  // {
  //   headerName: context.t("Site ID"),
  //   field: "siteID",
  //   minWidth: 140,
  //   cellClass: "cell-default-style",
  //   chartDataType: "series",
  //   sortable: true,
  //   filter: "agTextColumnFilter",
  //   filterParams: {
  //     filterOptions: ["contains"],
  //   },
  //   hide: true,
  // },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};
const floatingFilter = true;

const rowSelection = "multiple";

export const GridConfigData = {
  columnDefs,
  defaultColDef,
  floatingFilter,
  rowSelection,
};
