import React from "react";
import PropTypes from "prop-types";
import ErrorIcon from "@mui/icons-material/Error";
import { Typography, Link, Box } from "@mui/material";

const drawerWidth = '270px';

const NotFound = (_, context) => {
  return (
    <>
      <Box sx={{
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          minHeight: '100vh',
          margin: (theme) => theme.spacing(0, 20),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <ErrorIcon color="primary" style={{fontSize: 140}}/>
          <Typography variant="h4" paragraph sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: 'center'
          }}>
            {context.t("Sorry but we could not find the page you are looking for")}
          </Typography>
          <Link
            href="/"
            variant="body2"
            color="primary"
          >
            Go back to dashboard
          </Link>
        </Box>
      </Box>
    </>
  );
};

NotFound.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default NotFound;
