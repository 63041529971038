import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { deactivateBonusRecord } from "../../containers/bonuses/store/bonus_records/BonusRecordsActions";

const DeactivateBonus = (props, context) => {
  const {open, close, data} = props;
  const dispatch = useDispatch();

  const handleApprove = (e) => {
    e.preventDefault();
    dispatch(deactivateBonusRecord(data.uuid));
    close();
  };
  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle>
        {context.t("Deactivate")}: {data.name}
      </DialogTitle>
      <DialogContent>
        <div>
          <Typography>
            {context.t("Are you sure you want to deactivate thish bonus?")}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          {context.t("Cancel")}
        </Button>
        <Button onClick={handleApprove} color="primary">
          {context.t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeactivateBonus.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DeactivateBonus;
