export const REQUEST_GAMES_PENDING = 'REQUEST_GAMES_PENDING'
export const REQUEST_GAMES_SUCCESS = 'REQUEST_GAMES_SUCCESS'
export const REQUEST_GAMES_FAILED = 'REQUEST_GAMES_FAILED'

export const REQUEST_GAME_TYPES_PENDING = 'REQUEST_GAME_TYPES_PENDING'
export const REQUEST_GAME_TYPES_SUCCESS = 'REQUEST_GAME_TYPES_SUCCESS'
export const REQUEST_GAME_TYPES_FAILED = 'REQUEST_GAME_TYPES_FAILED'

export const REQUEST_GAME_CONDITIONS_PENDING = 'REQUEST_GAME_CONDITIONS_PENDING'
export const REQUEST_GAME_CONDITIONS_SUCCESS = 'REQUEST_GAME_CONDITIONS_SUCCESS'
export const REQUEST_GAME_CONDITIONS_FAILED = 'REQUEST_GAME_CONDITIONS_FAILED'
