import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import JSON_Editor from "../../components/jsoneditor";
import MultiSelector from "../../components/multiselect";
import { getLanguages } from "../languages/store/LanguageActions";
import { getLocaleText, updateLocaleText } from "./store/translationActions";

const drawerWidth = '270px';
const marginTop = '100px';

const Translation = (_, context) => {
  const [englishJSON, setEnglishJSON] = useState(null);
  const [altJSON, setAltJSON] = useState(null);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const store = useSelector((state) => ({
    languages: state.Languages.languages,
    translations: state.Translations.allTranslations,
  }));
  const [activeLanguage, setActiveLanguage] = useState("en_GB");
  const [key_, setKey_] = useState(1);
  const localRef = useRef("");

  useEffect(() => {
    dispatch(getLanguages());
    dispatch(getLocaleText());
    setEnglishJSON(store.translations);
  }, []);

  useEffect(() => {
    setEnglishJSON(store.translations.en_GB);
    let ar = [];
    for (let key in store.translations.en_GB) {
      ar.push(key);
    }
    localRef.current = ar;
  }, [store.translations]);

  useEffect(() => {
    setSearchValue("");
    for (const property in store.translations) {
      if (property === activeLanguage) {
        setAltJSON(store.translations[property]);
      }
    }
  }, [activeLanguage]);

  useEffect(() => {
    for (const property in store.translations) {
      if (store.translations[property] === altJSON) {
        setKey_((prevState) => {
          return ++prevState;
        });
      }
    }
  }, [altJSON, store.translations]);

  const getOptions = () => {
    if (store.languages) {
      let temp = store.languages.filter((item) => item.lanCode !== "en_GB");
      temp = temp.filter((item) => item.enable !== false);
      return temp.map((item) => {
        if (item.lanCode !== "en_GB") {
          return {
            value: item.lanCode,
            label: `${item.language} - ${item.country}`,
          };
        }
      });
    }
    return [];
  };

  const altChange = (value) => {
    setAltJSON(value);
  };

  const engChange = (value) => {
    setEnglishJSON(value);
  };

  useEffect(() => {
    reOrderItems();
    return () => {};
  }, [searchValue, store.translations]);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const reOrderItems = () => {
    if (searchValue) {
      let local = [];
      let i = 0;
      for (let key in englishJSON) {
        local.push({
          key,
          value:
            key.trim().toLowerCase().indexOf(searchValue.trim().toLowerCase()) >= 0
              ? key.trim().toLowerCase().indexOf(searchValue.trim().toLowerCase())
              : 10000,
          index: i++,
        });
      }
      const result = local.sort((a, b) => a.value - b.value);
      let enJson = {};
      let exJSON = {};
      console.log(result);
      for (let i = 0; i < result.length; i++) {
        if (englishJSON) enJson[result[i].key] = englishJSON[result[i].key];
        if (altJSON) exJSON[result[i].key] = altJSON[result[i].key];
      }
      setEnglishJSON(enJson);
      setAltJSON(exJSON);
      setTimeout(() => {
        setKey_((prevState) => {
          return ++prevState;
        });
      }, 0);
    }
  };

  const rearrange = (data) => {
    let obj = {};
    console.log(localRef.current);
    for (let i = 0; i < localRef.current.length; i++) {
      obj[localRef.current[i]] = data[localRef.current[i]];
    }
    console.log(obj);
    return obj;
  };
  return (
    <Box sx={{
      marginTop,
      marginLeft: drawerWidth,
      [(theme) => theme.breakpoints.down("sm")]: {
        marginLeft: 0,
      },
    }}>
      <Box sx={{
        margin: (theme) => theme.spacing(0, 4),
        display: "flex",
        flexDirection: "column",
      }}>
        <Typography variant="h5" noWrap paragraph>
          TEXT
        </Typography>
        <Box sx={{
          display: "flex",
          gap: "0.75rem",
        }}>
          <Box sx={{
            width: "50%",
          }}>
            <Box sx={{
              width: "50%",
              display: "flex",
              padding: "14px 0px",
              alignItems: "center",
              justifyContent: "center",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "rgba(136, 136, 136, 0.5)",
              color: "rgba(136, 136, 136, 1)",
              fontSize: "1.05rem",
            }}>English</Box>
            <div>
              <Button
                sx={(theme) => ({
                  margin: "10px 0px",
                  padding: theme.spacing(1, 2),
                  minWidth: 200,
                  color: theme.palette.primary.light,
                  background: theme.palette.secondary.dark,
                })}
                onClick={() => {
                  dispatch(
                    updateLocaleText({
                      lanCode: "en_GB",
                      text: rearrange(englishJSON),
                    })
                  );
                }}
              >
                Submit
              </Button>
            </div>
          </Box>
          <Box sx={{
            width: "50%",
          }}>
            <Box sx={{
              width: "50%",
            }}>
              <MultiSelector languages={getOptions()} setActiveLanguage={setActiveLanguage} />
            </Box>
            <div>
              <Button
                sx={(theme) => ({
                  margin: "10px 0px",
                  padding: theme.spacing(1, 2),
                  minWidth: 200,
                  color: theme.palette.primary.light,
                  background: theme.palette.secondary.dark,
                })}
                onClick={() => {
                  dispatch(
                    updateLocaleText({
                      lanCode: store.languages.filter(
                        (element) => element.lanCode === activeLanguage
                      )[0].lanCode,
                      text: rearrange(altJSON),
                    })
                  );
                }}
              >
                Submit
              </Button>
            </div>
          </Box>
        </Box>
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          type="text"
          name="search"
          sx={{
            border: "none",
            margin: "20px 0px",
            borderRadius: "0.25rem",
          }}
          onChange={handleChange}
        />
        <Box sx={{
          display: "flex",
          gap: "0.75rem",
        }}>
          <Box sx={{
            width: "50%",
          }}>
            {englishJSON ? (
              <JSON_Editor value={englishJSON} key_={key_ + 1} onChange={engChange} />
            ) : null}
          </Box>
          <Box sx={{
            width: "50%",
          }}>
            {altJSON ? <JSON_Editor value={altJSON} key_={key_} onChange={altChange} /> : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Translation;
