import jwt_decode from "jwt-decode";

import * as constants from "./LoginConstants";
import {
  setNotificationMessage,
  setShowNotificationMessage,
} from "../../../../components/notifications/store/notificationActions";
import getConfig from "../../../../config/getConfig";
import { setLoading } from "../../../../components/loading/store/showLoadingActions";

export const LoginAction = (params) => (dispatch) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": `application/json`, Accept: "application/json" },
    credentials: "include",
    body: JSON.stringify(params),
  };
  dispatch(setLoading(true));
  fetch(`${getConfig().API_URL_AUTH}/auth/signin`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      let role = "guest";
      try {
        let decoded = jwt_decode(json.token);
        role = decoded.role;
        if (role === "player") {
          throw new Error("Wrong Account")
        }
        dispatch({
          type: constants.TOKEN_EXPIRATION_TIME,
          payload: decoded.exp * 1000,
        });
      } catch {
        role = "guest";
        throw new Error("Invalid Credentials")
      }

      dispatch({ type: constants.REQUEST_SIGNIN_SUCCESS, payload: json });
      dispatch({ type: constants.SET_USER_ROLE, payload: role });
      dispatch(getCurrentUserInfo());
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};
export const checkToken = (action) => (dispatch, getState) => {
  const { tokenExpiration } = getState().loginUser;
  const TokenValid = new Date(new Date().getTime()) < new Date(tokenExpiration);
  if (!TokenValid) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": `application/json`,
        Accept: "application/json",
      },
      credentials: "include",
    };
    fetch(`${getConfig().API_URL_AUTH}/auth/refreshToken`, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.error) {
          throw new Error(json.error);
        }
        try {
          let decoded = jwt_decode(json.token);
          dispatch({
            type: constants.TOKEN_EXPIRATION_TIME,
            payload: decoded.exp * 1000,
          });
        } catch {
          dispatch({ type: constants.TOKEN_EXPIRATION_TIME, payload: null });
        }
        if (action) {
          dispatch(action);
        }
      })
      .catch((error) => {
        dispatch(setNotificationMessage(error.message));
        dispatch(setShowNotificationMessage(true));
        dispatch(LogoutAction());
      });
  } else if (action) {
      dispatch(action);
    }
};

export const LogoutAction = () => (dispatch) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": `application/json` },
    credentials: "include",
  };
  fetch(`${getConfig().API_URL_AUTH}/auth/signout`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch({ type: constants.REQUEST_SIGNOUT_SUCCESS });
    })
    .catch((error) => {
      dispatch({ type: constants.REQUEST_SIGNOUT_SUCCESS });
    });
};

export const getCurrentUserInfo = () => (dispatch) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": `application/json`,
      Accept: "application/json",
    },
    credentials: "include",
  };

  fetch(`${getConfig().API_URL_USERS}/admins/getSelf`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch({
        type: constants.REQUEST_GET_USER_INFO,
        payload: json,
      });
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};
export const getCurrentUserSessions = (params, filters) => (dispatch) => {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(filters),
  };

  fetch(
    `${getConfig().API_URL_AUTH}/auth/getSelfSessions?skip=${
      params.request.startRow
    }&take=${params.request.endRow - params.request.startRow}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error.message);
      }
      params.successCallback(json.sessions, json.amount);
    })
    .catch((error) => {
      params.failCallback();
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};
