const columnDefs = (context) => [
  {
    headerName: context.t("Status"),
    field: "loginStatus",
    valueGetter: function checkField(params) {
      return params.data?.loginStatus ? "Active" : "Inactive" || "none";
    },
    filter: "agSetColumnFilter",
    filterParams: {
      values: ["Active", "Inactive"],
    },
    minWidth: 140,
    cellClass: "cell-default-style",
  },

  {
    headerName: context.t("IP"),
    field: "ip",
    valueGetter: function checkField(params) {
      return params.data?.ip || "none";
    },
    minWidth: 140,
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: [
        "contains",
        "equals",
        "notEqual",
        "notContains",
        "startsWith",
        "endsWith",
      ],
    },
    cellClass: "cell-default-style",
  },
  {
    headerName: context.t("Started At"),
    field: "createdAt",
    valueGetter: function checkField(params) {
      return new Date(params.data?.createdAt).toLocaleString() || "none";
    },
    filter: "agDateColumnFilter",
    filterOptions: [
      "greaterThan",
      "greaterThanOrEqual",
      "lessThan",
      "lessThanOrEqual",
      "inRange",
    ],
    minWidth: 140,
    cellClass: "cell-default-style",
  },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};

const floatingFilter = true;

export const rowSelection = "single";

// use the server-side row model
let rowModelType = "serverSide";
let serverSideStoreType = "partial";

let animateRows = true;

export const GridConfigData = {
  columnDefs,
  defaultColDef,
  rowModelType,
  serverSideStoreType,
  floatingFilter,
  rowSelection,
  animateRows,
};
