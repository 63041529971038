import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { addLanguage, disableLanguage, enableLanguage, getLanguages } from "./store/LanguageActions";
import Autocomplete from "@mui/lab/Autocomplete";
import {
  setNotificationMessage,
  setShowNotificationMessage,
} from "../../components/notifications/store/notificationActions";
import { Box, Typography, Button, TextField } from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

const Languages = (_, context) => {
  const store = useSelector((state) => ({
    lang: state.Languages.languages,
    stt: state,
  }));
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  useEffect(() => {
    dispatch(
      getLanguages({
        all: true,
      })
    );
  }, []);

  const insertLanguage = () => {
    if (selectedLanguage) {
      dispatch(addLanguage({lanCode: selectedLanguage.lanCode}));
      setSelectedLanguage(null);
    } else {
      dispatch(setNotificationMessage("Please select a language"));
      dispatch(setShowNotificationMessage(true));
    }
  };

  const handleAutoCompleteChange = (e, value) => {
    if (!value) return;
    setSelectedLanguage(value);
  };
  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("Languages")}
          </Typography>
          <div>
            <Autocomplete
              sx={{
                "& .MuiSvgIcon-root": {color: (theme) => theme.palette.primary.main},
              }}
              options={store.lang.filter((option) => option.status === false)}
              getOptionLabel={(option) => `${option.language} - ${option.country}`}
              getOptionSelected={(option, value) => (option.id = value.id)}
              onChange={handleAutoCompleteChange}
              value={selectedLanguage}
              renderInput={(params) => (
                <TextField {...params} label="Search" variant="outlined" fullWidth/>
              )}
            />
            <Button
              sx={(theme) => ({
                padding: theme.spacing(1, 2),
                minWidth: 80,
                color: theme.palette.primary.light,
                background: theme.palette.secondary.dark,
                margin: "5px 0px",
              })}
              onClick={() => {
                insertLanguage();
              }}
            >
              ADD
            </Button>
            {store.lang &&
            store.lang.map(({lanCode, language, country, status, enable}, key) => {
              if (status === true) {
                return (
                  <Box
                    key={key}
                    sx={{
                      backgroundColor: "#c7c7c7",
                      width: "250px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0.25rem 0.5rem",
                      flexDirection: "row",
                      margin: "5px 0px",
                      fontWeight: "500",
                      borderRadius: "0.5rem",
                      fontSize: "15px",
                    }}
                  >
                    <span>{`${language} - ${country}`}</span>
                    <div style={{display: "flex"}}>
                      {!enable ? <Box
                        component="button"
                        type="button"
                        onClick={() => {
                          dispatch(enableLanguage({lanCode}));
                        }}
                        sx={{
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "rgb(209, 255, 235)",
                        }}
                      >
                        Enable
                      </Box> : null}
                      {enable ? <Box
                        component="button"
                        type="button"
                        onClick={() => {
                          dispatch(disableLanguage({lanCode}));
                        }}
                        sx={{
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "rgb(250, 177, 194)",
                        }}
                      >
                        Disable
                      </Box> : null}
                    </div>
                  </Box>
                );
              }
            })}
          </div>
        </Box>
      </Box>
    </>
  );
};

Languages.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Languages;
