import React from "react";
import { Box, TextField, Button } from "@mui/material";
import MultiSelector from "../multiselect";
import { useSelector } from "react-redux";

const GlossaryFormContent = ({
  data,
  setData,
  setLanguage,
  submit,
}) => {
  const store = useSelector((state) => ({
    lang: state.Languages.languages,
  }));
  const getOptions = () => {
    if (store.lang) {
      const filtered = store.lang.filter((item) => item.lanCode !== "en_GB");
      const final = filtered.filter((item) => item.enable===true)
      // {console.log(final)}
      return final.map((item) => ({
        value: item.lanCode,
        label: `${item.language} - ${item.country}`,
      }));
    }
    return [];
  };
  const handleChange = (event, code) => {
    if (code === "heading") {
      setData({ ...data, heading: event.target.value });
    } else {
      setData({ ...data, description: event.target.value });
    }
  };

  return (
    <form onSubmit={submit}>
      <Box
        component="form"
        // sx={{
        //   "& .MuiTextField-root": { m: 1, width: "25ch" },
        // }}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ marginBottom: "30px" }}>
          <MultiSelector
            languages={getOptions()}
            setActiveLanguage={setLanguage}
          />
        </Box>
        <Box sx={{ marginBottom: "30px" }}>
          <TextField
            id="outlined-multiline-flexible"
            label="Multiline"
            multiline
            maxRows={4}
            value={data.heading}
            onChange={(e) => {
              handleChange(e, "heading");
            }}
          />
        </Box>

        <Box sx={{ marginBottom: "30px" }}>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={10}
            defaultValue="Default Value"
            value={data.description}
            onChange={(e) => {
              handleChange(e, "description");
            }}
          />
        </Box>
        <div>
          <Button variant="outlined" type="submit">
            Save
          </Button>
        </div>
      </Box>
    </form>
  );
};
export default GlossaryFormContent;
