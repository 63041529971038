const columnDefs = (context) => [
  {
    headerName: context.t("Login Time"),
    // field: "login_time",
    valueGetter: function checkField(params) {
      return new Date(params.data.loginTime);
    },
    minWidth: 140,
    cellClass: "cell-default-style",
    filter: "agDateColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: [
        "lessThan",
        "lessThanOrEqual",
        "greaterThan",
        "greaterThanOrEqual",
        "inRange",
      ],
    },
  },
  {
    headerName: context.t("IP"),
    valueGetter: function checkField(params) {
      return params.data?.metadata[0]?.ip || "no data";
    },
    minWidth: 140,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Country"),
    valueGetter: function checkField(params) {
      return params.data?.metadata[0]?.country || "no data";
    },
    minWidth: 140,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};
const floatingFilter = true;

const rowSelection = "single";

export const GridConfigData = {
  columnDefs,
  defaultColDef,
  floatingFilter,
  rowSelection,
  // components,
};
