import * as constants from "./BonusEntriesConstants";

const initialState = {
  bonusEntries: [],
  activeBonusesFTD: 0,
  activeBonusesRELOAD: 0,
  activeBonuses: 0,
  total: 0,
};

export const BonusEntries = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_BONUS_ENTRIES_SUCCESS:
      return {
        ...state,
        bonusEntries: action.payload.bonuses,
        activeBonusesFTD: action.payload.activeBonusesFTD,
        activeBonusesRELOAD: action.payload.activeBonusesRELOAD,
        activeBonuses: action.payload.activeBonuses,
        total: action.payload.amount
      };

    default:
      return state;
  }
};
