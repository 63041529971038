import React, { useEffect, useState } from 'react';
import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import GetTextPlugin from "./plugins/GetTextPlugin";
import "./styles.css"
import { useDispatch } from 'react-redux';
import { addPageDescription, updatePageDescription } from '../../containers/pages/store/PageActions';
import { setLoading } from '../loading/store/showLoadingActions';
import { setNotificationMessage, setShowNotificationMessage } from '../notifications/store/notificationActions';

function Placeholder() {
    return <div className="editor-placeholder">Enter some rich text...</div>;
}

export default function Editor({ data, language, pageId }) {
    const [internalEditorState, setInternalEditorState] = useState();
    const [HTML, setHTML] = useState(data !== "" ? data : `<p class="editor-paragraph"><br></p>`)
    const [firstLoad, setFirstLoad] = useState(true);
    const dispatch = useDispatch();
    // console.log(data)
    const editorConfig = {
        theme: ExampleTheme,
        onError(error) {
            throw error;
        },
        _editorState: internalEditorState,
        get editorState() {
            return this._editorState;
        },
        set editorState(value) {
            this._editorState = value;
        },
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
        ],
    };


    useEffect(() => {
        setHTML(data)
    }, [data])

    const handleSubmit = () => {
        if (language) {
            // console.log(language)
            dispatch(addPageDescription({ lanCode: language, name: "", description: HTML, id: pageId }))
        } else {
            dispatch(setLoading(false));
            dispatch(setShowNotificationMessage(true));
            // dispatch(setNotificationSeverity("success"));
            dispatch(setNotificationMessage("Please select a language!"));
        }
    }

    const handleUpdate = () => {
        dispatch(updatePageDescription({ lanCode: language, description: HTML, pageId }))
    }

    return (
      <>
        <div className='main'>
          <LexicalComposer initialConfig={editorConfig}>
            <div className="editor-container">
              <ToolbarPlugin />
              <div className="editor-inner">
                <RichTextPlugin
                  contentEditable={<ContentEditable className="editor-input" />}
                  placeholder={<Placeholder />}
                            />
                <HistoryPlugin />
                <AutoFocusPlugin />
                <CodeHighlightPlugin />
                <ListPlugin />
                {firstLoad ?
                  <GetTextPlugin data={data} setHTML={setHTML} /> : null}
                <OnChangePlugin
                  ignoreInitialChange
                  onChange={(editorState, editor) => {
                                    setInternalEditorState(editorState);
                                    setHTML(editor.getRootElement().innerHTML)
                                }} />
                <LinkPlugin />
                <AutoLinkPlugin />
                <ListMaxIndentLevelPlugin maxDepth={7} />
                <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
              </div>
            </div>
          </LexicalComposer>
          <div style={{ height: "5rem" }}>
            <button
                        // className={classes.editButton}
              className="button_"
              onClick={() => { data === "" ? handleSubmit() : handleUpdate() }}
                    >
              Save
            </button>
          </div>
        </div>
      </>
    );
}
