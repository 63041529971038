import * as constants from "./GamesConstants";

const initialState = {
  games: [],
  gameTypes: [],
  gameConditions: [],
  isLoading: false,
};

export const Games = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_GAME_TYPES_PENDING:
      return { ...state, isLoading: true };
    case constants.REQUEST_GAME_TYPES_SUCCESS:
      return { ...state, gameTypes: action.payload, isLoading: false };
    case constants.REQUEST_GAME_TYPES_FAILED:
      return { ...state, isLoading: false };
    case constants.REQUEST_GAMES_PENDING:
      return { ...state, isLoading: true };
    case constants.REQUEST_GAMES_SUCCESS:
      return { ...state, games: action.payload, isLoading: false };
    case constants.REQUEST_GAMES_FAILED:
      return { ...state, isLoading: false };
    case constants.REQUEST_GAME_CONDITIONS_PENDING:
      return { ...state, isLoading: true };
    case constants.REQUEST_GAME_CONDITIONS_SUCCESS:
      return { ...state, gameConditions: action.payload, isLoading: false };
    case constants.REQUEST_GAME_CONDITIONS_FAILED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
