import * as constants from "./ForgotPasswordConstants";
import {
  setNotificationMessage,
  setShowNotificationMessage,
  setNotificationSeverity,
} from "../../../../components/notifications/store/notificationActions";
import getConfig from "../../../../config/getConfig";
import { setLoading } from "../../../../components/loading/store/showLoadingActions";

export const FPRequest = (params) => (dispatch) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": `application/json` },
    body: JSON.stringify(params),
  };
  dispatch({ type: constants.REQUEST_FP_PENDING });
  fetch(`${getConfig().API_URL_USERS}/micro_users/reset_password/forgot_password`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch(setNotificationMessage(json.message));
      dispatch(setNotificationSeverity("success"));
      dispatch(setShowNotificationMessage(true));
      dispatch({ type: constants.REQUEST_FP_SUCCESS, payload: json });
    })
    .catch((error) => {
      dispatch({
        type: constants.REQUEST_FP_ERROR,
        payload: error.message,
      });
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};

export const ResetPassRequest = (params) => (dispatch) => {
  const token = params.token;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": `application/json` },
    body: JSON.stringify({ password: params.password }),
  };
  dispatch({ type: constants.REQUEST_RP_PENDING });
  fetch(`${getConfig().API_URL_USERS}/micro_users/reset_password/reset_password/${token}`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch(setNotificationMessage(json.message));
      dispatch(setNotificationSeverity("success"));
      dispatch(setShowNotificationMessage(true));
      dispatch({ type: constants.REQUEST_FP_SUCCESS, payload: json });
    })
    .catch((error) => {
      dispatch({
        type: constants.REQUEST_FP_ERROR,
        payload: error.message,
      });
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};

export const ForgotPasswordRequest = (params) => (dispatch) => {
  const { setForm, setForgotPass, email } = params;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": `application/json` },
    body: JSON.stringify({ email }),
  };
  dispatch({ type: constants.REQUEST_FP_PENDING });
  dispatch(setLoading(true));
  fetch(`${getConfig().API_URL_USERS}/admins/forgotPassword`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch(setNotificationMessage("Please check your email"));
      dispatch(setNotificationSeverity("success"));
      dispatch(setShowNotificationMessage(true));
      dispatch({ type: constants.REQUEST_FP_SUCCESS, payload: "Please check your email" });
      dispatch(setLoading(false));
      setTimeout(() => {
        dispatch(setLoading(true));
      }, 1500);
      setTimeout(() => {
        setForm({ forgotPassEmail: "", email: "", password: "" });
        setForgotPass(false);
        dispatch(setLoading(false));
      }, 3000);
    })
    .catch((error) => {
      dispatch({
        type: constants.REQUEST_FP_ERROR,
        payload: "An unknown error has occured",
      });
      dispatch(setNotificationMessage("An unknown error has occured"));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};

export const ResetPasswordRequest = (params) => (dispatch) => {
  const { newPassword, confirmPassword, email, token } = params;
  if (newPassword === "" || confirmPassword === "") {
    dispatch(setNotificationMessage("One or more of the fields is empty"));
    dispatch(setShowNotificationMessage(true));
    return;
  }
  if (newPassword.length < 8) {
    dispatch(setNotificationMessage("Password must contain at least 8 characters"));
    dispatch(setShowNotificationMessage(true));
    return;
  }
  if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/.test(newPassword)) {
    dispatch(setNotificationMessage("Password must contain at least one letter and one number"));
    dispatch(setShowNotificationMessage(true));
    return;
  }
  if (newPassword !== confirmPassword) {
    dispatch(setNotificationMessage("Your passwords do not match"));
    dispatch(setShowNotificationMessage(true));
    return;
  }

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password: newPassword, token }),
  };
  // API call can go here
  fetch(`${getConfig().API_URL_USERS}/admins/reset_password_outside`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      dispatch(setNotificationMessage("Your password was successfully reset!"));
      dispatch(setNotificationSeverity("success"));
      dispatch(setShowNotificationMessage(true));
      params.setLoading(false);
      params.history.push("/auth");
    })
    .catch((error) => {
      dispatch(setNotificationMessage("An unknown error has occured"));
      dispatch(setNotificationSeverity("error"));
      dispatch(setShowNotificationMessage(true));
    });
};

export const ValidateResetPasswordRequest = (params) => (dispatch) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: params.email, token: params.token }),
  };
  fetch(`${getConfig().API_URL_USERS}/admins/email`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.isExist === false) {
        params.setFormVisibility(false);
      } else {
        params.setFormVisibility(true);
      }
      params.setLoading(false);
    })
    .catch((error) => {
      params.setFormVisibility(false);
      params.setLoading(false);
    });
};
