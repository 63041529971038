import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-scroll";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import EuroIcon from "@mui/icons-material/Euro";
import Widget from "../../../components/widget/Widget";
import GridConfig from "../../../components/grid/GridConfig";
import { GridConfigData } from "./grid_data/GridData";
import { GridConfigDataER } from "./grid_data/ExchangeRatesGridConfig";
import { GridConfigDataEX } from "./grid_data/Extremums";
import Extremums from "./extremums/Extremums";
import ExchangeRates from "./exchange_rates/ExchangeRates";
import {
  getPlatformConfig,
  getPlatformCurrencies,
  getExchangeRates,
  updateCurrencyAvailability,
} from "./store/currenciesActions";
import { Box, Typography, Button, Divider, styled, StepConnector } from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

const CustomLink = styled(Link)(({theme}) => ({
  cursor: "pointer",
  alignSelf: "flex-end",
  fontSize: "15px",
  display: "flex",
  borderRadius: "5px",
  margin: theme.spacing(0.2, 0),
  padding: theme.spacing(1, 2),
  "&:hover": {
    background: theme.palette.primary.dark,
  },
  "&.active": {
    width: "100%",
    background: theme.palette.primary.dark,
  },
}));

const PageNav = [
  {to: "currencies", name: "Currency Management"},
  {to: "exRates", name: "Exchange Rates"},
  {to: "extremums", name: "Currency Extremums"},
];

const PlayerActivity = (_, context) => {
  const store = useSelector((state) => ({
    baseCurrency: state.PlatformConfig.baseCurrency,
    enabledCurrencies: state.PlatformConfig.enabledCurrencies,
    disabledCurrencies: state.PlatformConfig.disabledCurrencies,
    exchangeRates: state.PlatformConfig.exchangeRates,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPlatformConfig());
    dispatch(getPlatformCurrencies());
    dispatch(getExchangeRates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Base Currency
  // const [open, setOpen] = useState(false);
  // Exchange Rates
  const [openER, setOpenER] = useState(false);
  const [openEX, setOpenEX] = useState(false);

  // Currency Management
  const [selectedCurrency, setselectedCurrency] = useState({
    code: null,
    disabled: true,
  });
  // Currency Extremums
  const [selectedCurrencyEX, setselectedCurrencyEX] = useState(undefined);
  const enableCurrency = (e) => {
    e.preventDefault();
    dispatch(
      updateCurrencyAvailability({
        code: selectedCurrency.code,
        isEnabled: true,
      })
    );
  };

  // Grid
  const [gridApi, setGridApi] = useState({});
  const [gridApiEX, setGridApiEX] = useState({});
  const onGridReady = (params) => {
  };
  const onGridReadyCurrencies = (params) => {
    setGridApi(params.api);
  };
  const onGridReadyCurrenciesEX = (params) => {
    setGridApiEX(params.api);
  };
  const onRowSelected = (e) => {
  };
  const onRowSelectedCurrencies = (e) => {
    const focusedCell = gridApi.getFocusedCell();
    const selectedInfo = gridApi.getDisplayedRowAtIndex(focusedCell.rowIndex);
    selectedInfo.selected && !selectedInfo.data.isEnabled
      ? setselectedCurrency({code: selectedInfo.data.code, disabled: false})
      : setselectedCurrency({code: null, disabled: true});
  };
  const onRowSelectedCurrenciesEX = (e) => {
    const focusedCell = gridApiEX.getFocusedCell();
    const selectedInfo = gridApiEX.getDisplayedRowAtIndex(focusedCell.rowIndex);
    selectedInfo.selected
      ? setselectedCurrencyEX(selectedInfo.data)
      : setselectedCurrencyEX(undefined);
  };
  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("Platform Currencies Managment")}
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("Base Currency")}
                data={store.baseCurrency}
                description="Platform Currency"
                Icon={EuroIcon}
              />
            }
            {
              <Widget
                name={context.t("Enabled Currencies")}
                data={store.enabledCurrencies.length}
                description={store.enabledCurrencies
                .map((x) => x.code)
                .join(", ")}
                Icon={PeopleAltIcon}
              />
            }
          </Box>
        </Box>
        <Box sx={{
          display: "grid",
          gridTemplateColumns: "20% 80%",
          [(theme) => theme.breakpoints.down("md")]: {
            gridTemplateColumns: "100%",
          },
        }}>
          <Box sx={(theme) => ({
            borderRight: `1px solid ${theme.palette.primary.main}`,
            [theme.breakpoints.down("md")]: {
              borderRight: "none",
            },
          })}>
            <Box sx={(theme) => ({
              position: "fixed",
              margin: theme.spacing(0, 2),
              [theme.breakpoints.down("md")]: {
                position: "initial",
              },
            })}>
              {PageNav.map((navEl) => (
                <CustomLink
                  to={navEl.to}
                  spy={true}
                  smooth={true}
                  offset={-220}
                  duration={300}
                  isDynamic={true}
                >
                  {context.t(navEl.name)}
                </CustomLink>
              ))}
            </Box>
          </Box>

          <div>
            <Box sx={{
              margin: (theme) => theme.spacing(10, 4),
              gridTemplateColumns: "100%",
            }} id="currencies">
              <Typography variant="h4" noWrap paragraph>
                {context.t("Currency Managment")}:
              </Typography>
              <Typography variant="body1" paragraph>
                In this section, you can select the currencies which are going
                to be available on your client. The activated currencies will be
                available for registration on the client. Already created users
                couldn't change their currency. Activated currencies will be
                added to the reports.
              </Typography>
              <Typography variant="body2">Important!</Typography>
              <Typography variant="body2">
                * You cannot disable selected currencies.
              </Typography>
              <Typography variant="body2" paragraph>
                * It is necessary to add a currency rate if it differs from the
                base currency of the platform.
              </Typography>
              <Box sx={{
                textAlign: "end",
                margin: (theme) => theme.spacing(1, 0),
              }}>
                <Button
                  variant="contained"
                  disabled={selectedCurrency.disabled}
                  onClick={enableCurrency}
                >
                  {context.t("Enable Currency")}
                </Button>
              </Box>
              <div className="ag-theme-material">
                <Box sx={{
                  height: "55vh",
                }}>
                  {store.enabledCurrencies.length === 0 &&
                  store.disabledCurrencies.length === 0 ? (
                    <div>
                      <Typography variant="h5" noWrap paragraph>
                        Loading ...
                      </Typography>
                    </div>
                  ) : (
                    <GridConfig
                      props={{
                        GridConfigData,
                        onGridReady: onGridReadyCurrencies,
                        onRowSelected: onRowSelectedCurrencies,
                        rowData: [
                          ...store.enabledCurrencies,
                          ...store.disabledCurrencies,
                        ],
                      }}
                    />
                  )}
                </Box>
              </div>
            </Box>
            <Divider/>

            {/* EXCHANGE RATES */}
            <Box sx={{
              margin: (theme) => theme.spacing(10, 4),
              gridTemplateColumns: "100%",
            }} id="exRates">
              <Typography variant="h4" noWrap paragraph>
                {context.t("Exchange Rates")}:
              </Typography>
              <Typography variant="body1" paragraph>
                In this section, you can select the exchange rates available on
                the platform. The changes are reflected in the reports. When the
                exchange rate changes, the old rate becomes deactivated.
              </Typography>
              <Typography variant="body2" paragraph>
                * All transactions made with an active rate will be saved with
                this rate.
              </Typography>
              <Box sx={{
                textAlign: "end",
                margin: (theme) => theme.spacing(1, 0),
              }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenER(true);
                  }}
                >
                  {context.t("Add New Rate")}
                </Button>
                <ExchangeRates open={openER} setOpen={setOpenER}/>
              </Box>
              <div className="ag-theme-material">
                <Box sx={{
                  height: "55vh",
                }}>
                  {store.enabledCurrencies.length === 0 &&
                  store.disabledCurrencies.length === 0 ? (
                    <div>
                      <Typography variant="h5" noWrap paragraph>
                        Loading ...
                      </Typography>
                    </div>
                  ) : (
                    <GridConfig
                      props={{
                        GridConfigData: GridConfigDataER,
                        onGridReady,
                        onRowSelected,
                        rowData: store.exchangeRates,
                      }}
                    />
                  )}
                </Box>
              </div>
            </Box>

            {/* CURRENCY  EXTREMUMS */}
            <Box sx={{
              margin: (theme) => theme.spacing(10, 4),
              gridTemplateColumns: "100%",
            }} id="extremums">
              <Typography variant="h4" noWrap paragraph>
                {context.t("Currency Extremums")}:
              </Typography>
              <Typography variant="body1" paragraph>
                In this section, you can manipulate the min and max of the
                currency. The following options are available for change:
              </Typography>
              <Typography variant="body2">
                1. max Conversion Limit - Maximum of the money which can be
                converted from bonus to real money
              </Typography>
              <Typography variant="body2">
                2. min Deposit - Minimum amount for a deposit on Operator
                Website
              </Typography>
              <Typography variant="body2">
                3. min Deposit - Maximum amount for a deposit on Operator
                Website
              </Typography>
              <Typography variant="body2">
                4. min Deposit BO - Minimum amount for a deposit range in bonus
                creation section
              </Typography>
              <Typography variant="body2">
                5. max Deposit BO - Maximum amount for a deposit range in bonus
                creation section
              </Typography>
              <Typography variant="body2">
                6. min Wagering Contribution - Minimum amount for a Wagering
                Contribution range in bonus creation section
              </Typography>
              <Typography variant="body2">
                7. max Wagering Contribution - Maximum amount for a Wagering
                Contribution range in bonus creation section
              </Typography>
              <Typography variant="body2" paragraph>
                8. Total Bonus Cap - Maximum amount for a Bonus
              </Typography>
              <Box sx={{
                textAlign: "end",
                margin: (theme) => theme.spacing(1, 0),
              }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenEX(true);
                  }}
                >
                  {context.t("Edit Currency")}
                </Button>
                <Extremums open={openEX} setOpen={setOpenEX} data={selectedCurrencyEX}/>
              </Box>
              <div className="ag-theme-material">
                <Box sx={{
                  height: "55vh",
                }}>
                  {store.enabledCurrencies.length === 0 &&
                  store.disabledCurrencies.length === 0 ? (
                    <div>
                      <Typography variant="h5" noWrap paragraph>
                        Loading ...
                      </Typography>
                    </div>
                  ) : (
                    <GridConfig
                      props={{
                        GridConfigData: GridConfigDataEX,
                        onGridReady: onGridReadyCurrenciesEX,
                        onRowSelected: onRowSelectedCurrenciesEX,
                        rowData: [
                          ...store.enabledCurrencies,
                          ...store.disabledCurrencies,
                        ],
                      }}
                    />
                  )}
                </Box>
              </div>
            </Box>
          </div>
        </Box>
      </Box>
    </>
  );
};

PlayerActivity.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PlayerActivity;
