const columnDefs = (context) => [
  {
    headerName: context.t("Name"),
    valueGetter: function combineField(params) {
      return params.data.firstName === null ? `_` : `${params.data.firstName} ${params.data.lastName}`;
    },
    checkboxSelection: true,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Country"),
    field: "country",
    cellClass: "cell-default-style",
    chartDataType: "series",
  },
  {
    headerName: context.t("Cash Balance"),
    valueGetter: function checkField(params) {
      return params.data.accounts === null ?  0 : params.data.accounts.filter((el) => el.type.name=== "current")[0].balance;
    },
    cellClass: "cell-default-style",
    chartDataType: "series",
    sortable: true,
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
  },
  {
    headerName: context.t("Promo Balance"),
    valueGetter: function checkField(params) {
      return params.data.accounts === null ?  0 : params.data.accounts.filter((el) => el.type.name=== "bonus")[0].balance;
    },
    cellClass: "cell-default-style",
    chartDataType: "series",
    sortable: true,
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
  },

  {
    headerName: context.t("Email"),
    field: "email",
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Status"),
    valueGetter: function combineField(params) {
      return params.data.status === null ? `none` :  params.data.status.name;
    },
    cellClass: "cell-default-style",
    chartDataType: "series",
    hide: true,
  },
  {
    headerName: context.t("Marketing Tag"),
    valueGetter: function combineField(params) {
      return params.data.marketingTag === null ? `-` :  params.data.status.name;
    },
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
    hide: true,
  },
  {
    headerName: context.t("Country"),
    field: "country",
    cellClass: "cell-default-style",
    chartDataType: "series",
    hide: true,
  },
  {
    headerName: context.t("KYC"),
    field: "kycStatus",
    cellClass: "cell-default-style",
    chartDataType: "series",
  },
  //   {
  //     headerName: context.t("KYC ID"),
  //     field: "KYCid",
  //     cellClass: "cell-default-style",
  //     chartDataType: "series",
  //     filter: "agTextColumnFilter",
  //     sortable: true,
  //     filterParams: {
  //       filterOptions: ["equals", "lessThan", "greaterThan"],
  //     },
  //     hide: true,
  //   },
  //   {
  //     headerName: context.t("Payment Method"),
  //     field: "paymentMethodType",
  //     cellClass: "cell-default-style",
  //     chartDataType: "series",
  //     filter: "agTextColumnFilter",
  //     filterParams: {
  //       filterOptions: ["contains"],
  //     },
  //     hide: true,
  //   },
  //   {
  //     headerName: context.t("Site ID"),
  //     field: "siteID",
  //     cellClass: "cell-default-style",
  //     chartDataType: "series",
  //     filter: "agNumberColumnFilter",
  //     sortable: true,
  //     filterParams: {
  //       filterOptions: ["equals", "lessThan", "greaterThan"],
  //     },
  //     hide: true,
  //   },
  //   {
  //     headerName: context.t("Registration Time"),
  //     field: "registrationTime",
  //     cellClass: "cell-default-style",
  //     chartDataType: "series",
  //     filter: "agTextColumnFilter",
  //     filterParams: {
  //       filterOptions: ["contains"],
  //     },
  //     hide: true,
  //   },
  {
    headerName: context.t("Last Login Time"),
    valueGetter: function checkField(params) {
      return params.data.loginHistory.length === 0
        ? `No Records`
        : new Date(params.data.loginHistory[0].login_time).toDateString();
    },
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
    },
    hide: true,
  },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};
const floatingFilter = true;

const rowSelection = "single";

export const GridConfigData = {
  columnDefs,
  defaultColDef,
  floatingFilter,
  rowSelection,
};
