import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { UpdateUserPicture } from "../admin_management/store/admin_actions/adminActions";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import { checkToken } from "../../signin/login_page/store/LoginActions";
import { Box, Button } from "@mui/material";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageEdit
);
const MyProfile = ({editPicture, seteditPicture}, context) => {
  const state = useSelector((state) => ({
    user: state.loginUser.user,
  }));
  const dispatch = useDispatch();
  const [imgCollection, setImgCollection] = useState("");

  const cancel = () => {
    seteditPicture(false);
    setImgCollection("");
  };

  const onFileChange = (files) => {
    if (files[0] && files[0].file.name) {
      let items = files.map((fileItem) => fileItem.file);
      setImgCollection([items]);
    } else {
      setImgCollection("");
    }
  };

  const updatePhoto = (e) => {
    e.preventDefault();
    let formData = new FormData();
    for (let img in imgCollection) {
      formData.append("imgCollection", imgCollection[0][img]);
    }

    formData.append("folder", "profile_picture");
    dispatch(checkToken(UpdateUserPicture(formData)));
    seteditPicture(false);
    setImgCollection("");
  };

  return (
    <Box sx={(theme) => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRight: `1px solid ${theme.palette.primary.main}`,
      flexDirection: "column",
    })}>
      <Box sx={{
        display: "grid",
        justifyItems: "center",
      }}>
        <Box
          component="img"
          src={
            state.user.profilePicture === null
              ? "https://i2.wp.com/brookfieldsschool.org/wp-content/uploads/2019/03/blank-profile-picture-973460_960_720.png?fit=720%2C720&ssl=1&w=640"
              : state.user.profilePicture
          }
          alt="profile"
          sx={{
            width: "250px",
            height: "250px",
            borderRadius: "2%",
            justifyContent: "center",
          }}
        />

        {editPicture ? (
          <>
            <Box component="form" onSubmit={updatePhoto} sx={{
              margin: (theme) => theme.spacing(8, 1),
            }}>
              <div className="filepond-wrapper">
                <FilePond
                  files={imgCollection}
                  labelIdle={`Drag & Drop your picture or <span class="filepond--label-action">Browse</span>`}
                  allowRevert={false}
                  allowImagePreview={false}
                  acceptedFileTypes={["image/png", "image/jpeg"]}
                  maxFileSize={"5MB"}
                  allowMultiple={false}
                  server={null}
                  instantUpload={false}
                  onupdatefiles={(fileItems) => onFileChange(fileItems)}
                />
              </div>
              <Button
                variant="contained"
                sx={(theme) => ({
                  margin: theme.spacing(1, 1),
                  padding: theme.spacing(1),
                  minWidth: 150,
                  color: theme.palette.primary.light,
                  background: theme.palette.success.main,
                  "&:hover": {
                    background: theme.palette.success.dark,
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginRight: 20,
                  },
                })}
                type="submit"
              >
                {context.t("Submit")}
              </Button>
              <Button
                variant="contained"
                sx={(theme) => ({
                  margin: theme.spacing(1, 1),
                  padding: theme.spacing(1),
                  minWidth: 150,
                  color: theme.palette.primary.light,
                  background: theme.palette.error.main,
                  "&:hover": {
                    background: theme.palette.error.dark,
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginRight: 20,
                  },
                })}
                onClick={(el) => cancel()}
              >
                {context.t("Cancel")}
              </Button>
            </Box>
          </>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

MyProfile.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MyProfile;
