import {
  setNotificationMessage,
  setShowNotificationMessage,
  setNotificationSeverity,
} from "../../../../../components/notifications/store/notificationActions";
import getConfig from "../../../../../config/getConfig";
import { setLoading } from "../../../../../components/loading/store/showLoadingActions";
import { getCurrentUserInfo } from "../../../../signin/login_page/store/LoginActions"

export const UpdateUser = (uuid, body) => (dispatch) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };
  dispatch(setLoading(true));

  fetch(`${getConfig().API_URL_USERS}/admins/user/${uuid}`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error.message);
      }
      dispatch(setNotificationMessage("User Was Successfully Updated"));
      dispatch(setNotificationSeverity("success"));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};

export const UpdateUserPicture = (body) => (dispatch) => {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    body,
  };
  fetch(`${getConfig().API_URL_USERS}/admins/user/update_photo`, requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      if (json.error) {
        throw new Error(json.error.message);
      }
      dispatch(setNotificationMessage("Player Was Successfully Updated"));
      dispatch(setNotificationSeverity("success"));
      dispatch(setShowNotificationMessage(true));
      dispatch(getCurrentUserInfo());

    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};

export const updateCurrentUserInfo = (params) => (dispatch) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify(params),
  };
  fetch(`${getConfig().API_URL_USERS}/admins/updateSelf`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch(setNotificationMessage("Player Was Successfully Updated"));
      dispatch(setNotificationSeverity("success"));
      dispatch(getCurrentUserInfo());
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};

export const resetPassword = (id) => (dispatch) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };
  fetch(`${getConfig().API_URL_USERS}/admins/reset_password/${id}`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch(setNotificationMessage("Password Was Successfully Reseted"));
      dispatch(setNotificationSeverity("success"));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};



export const CreateUser = (body) => (dispatch) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };
  fetch(`${getConfig().API_URL_USERS}/admins/create_user`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch(setNotificationMessage("User Was Successfully Created"));
      dispatch(setNotificationSeverity("success"));
      dispatch(setShowNotificationMessage(true));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};
