import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Button, TextField, Typography, Box, InputAdornment, IconButton } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import claw from "../../../assets/claw.png";
import { PageNotifications } from "../../../components/notifications/notificationsService";
import {
  ResetPasswordRequest,
  ValidateResetPasswordRequest,
} from "./store/ForgotPasswordActions";
import { useParams, useHistory } from "react-router-dom";
import LoadingComponent from "../../../components/loading/Loading";

const ResetPassword = () => {
  const state = useSelector((state) => ({
    menuOpen: state.changeMenuOpen.menuOpen,
    showNotificationMessage: state.showNotification.showNotificationMessage,
  }));
  const [formVisibility, setFormVisibility] = useState(false);
  const [loading, setLoading] = useState(true);
  const {email, token} = useParams();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    newPassword: "",
    confirmPassword: "",
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const history = useHistory();

  useEffect(() => {
    dispatch(
      ValidateResetPasswordRequest({
        email,
        token,
        setFormVisibility,
        setLoading,
      })
    );
  }, [email, token]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      ResetPasswordRequest({
        newPassword: form.newPassword,
        confirmPassword: form.confirmPassword,
        email,
        token,
        setLoading,
        history
      })
    );
  };

  return (
    <>
      {state.showNotificationMessage ? <PageNotifications/> : ""}
      {loading ? (
        <LoadingComponent/>
      ) : formVisibility ? (
        <Box sx={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          background: (theme) => theme.palette.background.default,
          backgroundImage: `url(${claw})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
        }}>
          <Box sx={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
            alignItems: "center",
          }}>
            <Box sx={(theme) => ({
              padding: theme.spacing(2),
              borderRadius: "10px",
              background: theme.palette.background.paper,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            })}>
              <Avatar sx={(theme) => ({
                background: theme.palette.primary.main,
                margin: theme.spacing(1),
              })}>
                <LockOutlinedIcon/>
              </Avatar>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              <Box
                component="form"
                sx={(theme) => ({
                  width: "350px",
                  marginTop: theme.spacing(2),
                  "& .MuiSvgIcon-root": {color: theme.palette.primary.main},
                })}
                noValidate
                onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={form.newPassword}
                  onChange={(newPassword) =>
                    setForm({...form, newPassword: newPassword.target.value})}
                  name="newpassword"
                  label="New Password"
                  id="newpassword"
                  // autoComplete="current-password"
                  type={form.showNewPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setForm({...form, showNewPassword: !form.showNewPassword})}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {form.showNewPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={form.password}
                  onChange={(confirmPassword) =>
                    setForm({...form, confirmPassword: confirmPassword.target.value})}
                  name="confirmPassword"
                  label="Confirm Password"
                  id="confirmPassword"
                  // autoComplete="current-password"
                  type={form.showConfirmPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setForm({...form, showConfirmPassword: !form.showConfirmPassword})}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {form.showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                    margin: (theme) => theme.spacing(3, 0, 2),
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Box>
          </Box>
          {/* <LoadingComponent /> */}
        </Box>
      ) : (
        <Box sx={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          background: (theme) => theme.palette.background.default,
          backgroundImage: `url(${claw})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
        }}>
          <Box sx={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
            alignItems: "center",
          }}>
            <Box sx={(theme) => ({
              padding: theme.spacing(2),
              borderRadius: "10px",
              background: theme.palette.background.paper,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            })}>
              <Avatar sx={(theme) => ({
                background: theme.palette.primary.main,
                margin: theme.spacing(1),
              })}>
                <LockOutlinedIcon/>
              </Avatar>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              <div>It seems you do not have access to this page!</div>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default ResetPassword;
