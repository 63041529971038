import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@mui/material";

const BonusDetails = (props, context) => {
  const {open, close, data} = props;
  const options = {year: "numeric", month: "numeric", day: "numeric"};
  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle>{data.name}</DialogTitle>
      <DialogContent>
        <div>
          <Typography color="textSecondary">
            {context.t("Name")}:<span> {data.name}</span>
          </Typography>
          <Typography
            sx={{
              "& .status": {
                color: data.status ? "green" : "red",
              },
            }}
            color="textSecondary"
          >
            {context.t("Status")}:
            <span className="status"> {data.status} </span>
          </Typography>
          <Typography color="textSecondary">
            {context.t("Bonus Percentage")}:<span> {data.percentage}</span>
          </Typography>
          <Typography color="textSecondary">
            {context.t("Maximum Bonus Amount")}:<span> {data.maxAmount}</span>
          </Typography>
          <Typography color="textSecondary">
            {context.t("Duration Per Player (in days)")}:
            <span> {data.duration ?? "Not Specified"}</span>
          </Typography>
          <Typography color="textSecondary">
            {context.t("Amount of Free Spins")}:
            <span> {data.freeSpins ?? "No Free Spins"}</span>
          </Typography>
          <Typography color="textSecondary">
            {context.t("Wagering Requirement")}:
            <span> x{data.wageringRequirement}</span>
          </Typography>
          <Typography color="textSecondary">
            {context.t("Duration")}:
            <span>
              {" "}
              {new Date(data.startDate).toLocaleString("en-US", options)} -
              {new Date(data.endDate).toLocaleString("en-US", options)}
            </span>
          </Typography>
          <Typography color="textSecondary">
            {context.t("Code")}:<span> {data.code}</span>
          </Typography>
        </div>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            padding: data.description ? (theme) => theme.spacing(0, 2, 2, 2) : 0,
          }}
        >
          {context.t("Description")}:
        </DialogContentText>
        <Box component="img" src={data.image} sx={{maxWidth: "400px",}}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BonusDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BonusDetails;
