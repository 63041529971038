import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "redux-i18n";
import {
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  FormControl,
  Select,
  Button,
  MenuItem
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { getLanguages } from "../../../../containers/languages/store/LanguageActions";

const ChooseLanguage = (_, context) => {
  const [open, setOpen] = useState(false);
  const store = useSelector((state) => ({
    lang: state.i18nState.lang,
    languages: state.Languages.languages,
  }));
  const dispatch = useDispatch();
  const handleChange = (event) => {
    console.log(event.target.value)
    dispatch(setLanguage(event.target.value));
  };
  useEffect(() => {
    dispatch(setLanguage("en_GB"));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    dispatch(getLanguages({all: true}));
  }, []);

  return (
    <>
      <IconButton onClick={handleClickOpen} sx={{
        color: "white",
      }}>
        <LanguageIcon/>
      </IconButton>
      <Box sx={{
        padding: "5px 30px 5px 5px",
        display: "none",
        [(theme) => theme.breakpoints.up("md")]: {
          display: "flex",
        },
      }} onClick={handleClickOpen}>
        Select Language
      </Box>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>{context.t("Choose Language")}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{
            display: "flex",
            flexWrap: "wrap",
          }}>
            <FormControl sx={{
              padding: (theme) => theme.spacing(1),
              minWidth: 220,
            }}>
              <InputLabel> {context.t("Language")}</InputLabel>

              {store.languages.length ? (
                <Select value={store.lang} onChange={handleChange}>
                  {store.languages.map((item, key) => {
                    if (item.status === true) {
                      return (
                        <MenuItem key={key} value={item.lanCode}>
                          {`${item.language} - ${item.country}`}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              ) : (
                "Loading"
              )}
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {context.t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
ChooseLanguage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ChooseLanguage;
