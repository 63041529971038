import * as constants from "./playerConstatnts";
import {
  setNotificationMessage,
  setShowNotificationMessage,
  setNotificationSeverity,
} from "../../../../components/notifications/store/notificationActions";
import getConfig from "../../../../config/getConfig";
import { setLoading } from "../../../../components/loading/store/showLoadingActions";
import { getPlayersData } from "../grid_player_load/gridPlayersActions";

export const getPlayerInfo = (uuid) => (dispatch, getState) => {
  const requestOptions = {
    method: "GET",
    credentials: "include",
  };
  if (uuid === undefined) {
    return;
  }
  dispatch(setLoading(true));
  fetch(`${getConfig().API_URL_ADMINS_PLAYERS}/players/${uuid}`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch({
        type: constants.REQUEST_PLAYER_SUCCESS,
        payload: json,
      });
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};

export const UpdatePlayer = (uuid, body) => (dispatch, getState) => {
  const { token } = getState().loginUser;
  const requestOptions = {
    method: "PUT",
    headers: {
      "x-access-token": `${token}`,
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };
  fetch(`${getConfig().API_URL_ADMINS_PLAYERS}/players/update/${uuid}`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch(setNotificationMessage("Player Was Successfully Updated"));
      dispatch(setNotificationSeverity("success"));
      dispatch(setShowNotificationMessage(true));
      dispatch(getPlayerInfo());
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};

export const BulkUpdatePlayer = (body) => (dispatch, getState) => {
  const { token } = getState().loginUser;
  const requestOptions = {
    method: "PUT",
    headers: {
      "x-access-token": `${token}`,
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };
  fetch(`${getConfig().API_URL_ADMINS_PLAYERS}/players/bulk_update`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch(setNotificationMessage("Players Were Successfully Updated"));
      dispatch(setNotificationSeverity("success"));
      dispatch(setShowNotificationMessage(true));
      dispatch(getPlayersData());
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};
