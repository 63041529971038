export const GameTypesMock = [
  {
    id: "c7f1a085-1afd-4de5-923f-03e87ace9759",
    name: "Slots",
  },
  {
    id: "2dae0d88-1b3d-442e-90af-0ef4c3a5ba5d",
    name: "Blackjack",
  },
  {
    id: "33754360-d50f-4a34-b00e-7856365b6631",
    name: "Baccarat",
  },
  {
    id: "29e97baf-f969-4ec5-ac5a-304cf1beec1b",
    name: "Poker",
  },
  {
    id: "90dd6008-72aa-48d2-a742-6438c98eab8e",
    name: "Socket Games",
  },
  {
    id: "d8a54075-2142-47c2-8742-3f2a16b57a8c",
    name: "Scratch card",
  },
  {
    id: "d0bf4147-0948-489a-96ba-fd78cd0367d2",
    name: "Keno",
  },
  {
    id: "0b3443ad-fc9b-4e5b-bc5a-6ccb251748c0",
    name: "Roulette",
  },
  {
    id: "653869c2-3241-4436-8c81-3f89572ee9ac",
    name: "Video Poker",
  },
  {
    id: "8ca40ac1-ba3a-4739-bf3c-731f3f80b838",
    name: "Instant",
  },
  {
    id: "3deee59b-bd15-4996-bb8e-cfb3a88ab5ad",
    name: "Table",
  },
];
