import React from "react";

import GridConfig from "../../../../../components/grid/GridConfig";
import { GridConfigData as BonusesGrid } from "../grid_data/BonusesGrid";

const Grid = ({ bonuses, store }) => {
  return (
    <>
      <GridConfig
        props={{
          GridConfigData: BonusesGrid,
          onGridReady: bonuses.onBonusesGridReady,
          onRowSelected: bonuses.onBonusesRowSelected,
          rowData: store.bonusRecords,
        }}
      />
    </>
  );
};

export default Grid;
