import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  FormControl,
  Select,
  Button,
  MenuItem,
  TextField
} from "@mui/material";

import { addExchangeRate } from "../store/currenciesActions";

const ExchangeRates = ({open, setOpen}, context) => {
  const store = useSelector((state) => ({
    baseCurrency: state.PlatformConfig.baseCurrency,
    enabledCurrencies: state.PlatformConfig.enabledCurrencies,
    disabledCurrencies: state.PlatformConfig.disabledCurrencies,
  }));
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    dispatch(addExchangeRate(values));
    setOpen(false);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      base: store.baseCurrency || null,
      quote: null,
      rate: 1,
    },

    validationSchema: Yup.object().shape({
      base: Yup.string().required("Base Currency is required"),
      quote: Yup.string()
      .notOneOf([Yup.ref("base"), null], "Passwords must match")
      .required("Quote Currency is required"),
      rate: Yup.number().required("Rate is required"),
    }),
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = formik;

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>{context.t("Add New Exchange Rate")}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{
            display: "flex",
          }}>
            <FormControl sx={{
              padding: (theme) => theme.spacing(1),
              minWidth: 220,
            }}>
              <InputLabel> {context.t("Choose Base Currency")}</InputLabel>
              <Select
                value={values.base}
                onChange={(_, newValue) => {
                  setFieldValue("base", newValue.props.value);
                }}
              >
                {[...store.disabledCurrencies, ...store.enabledCurrencies]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((currency) => (
                  <MenuItem value={currency.code}>{currency.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{
              padding: (theme) => theme.spacing(1),
              minWidth: 220,
            }}>
              <InputLabel> {context.t("Choose Quote Currency")}</InputLabel>
              <Select
                value={values.quote}
                onChange={(_, newValue) => {
                  setFieldValue("quote", newValue.props.value);
                }}
              >
                {[...store.disabledCurrencies, ...store.enabledCurrencies]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((currency) => (
                  <MenuItem value={currency.code}>{currency.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              required
              name="rate"
              error={touched.rate && Boolean(errors.rate)}
              helperText={touched.rate ? errors.rate : ""}
              label={context.t("Rate")}
              type="number"
              InputProps={{inputProps: {min: 0.0, max: 10000}}}
              onBlur={handleBlur}
              value={values.rate}
              onChange={handleChange}
              sx={{
                padding: (theme) => theme.spacing(1),
                minWidth: 220,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            disabled={!(isValid && dirty)}
            color="primary"
          >
            {context.t("Submit")}
          </Button>
          <Button onClick={handleClose} color="primary">
            {context.t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
ExchangeRates.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ExchangeRates;
