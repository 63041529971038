import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Widget from "../../../components/widget/Widget";
import GridConfig from "../../../components/grid/GridConfig";
import { GridConfigData } from "./grid_data/GridData";
import { getPlayersData } from "../../player_management/store/grid_player_load/gridPlayersActions";

const drawerWidth = '270px';
const marginTop = '100px';

const PlayerActivity = (_, context) => {
  const store = useSelector((state) => ({
    data: state.PlayersData.players,
  }));
  const dispatch = useDispatch();
  //   const [gridApi, setGridApi] = useState({});
  useEffect(() => {
    dispatch(getPlayersData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Grid
  const onGridReady = (params) => {
    // setGridApi(params.api);
  };
  const onRowSelected = (e) => {
    // const selectedRows = gridApi.getSelectedRows();
  };
  //   let a = store.data.map((el) => el.loginStatus);
  //   let b = a.map((element) => element[0]);
  //   let c = b.filter(el=> el.login_time)
  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("Player Activity Report")}
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("Active Last Hour")}
                data={store.data.map((el) => el.loginStatus).length}
                description="Currently Online"
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Active in 24hrs")}
                data={store.data.map((el) => el.loginStatus).length}
                description="Higher then ususal 24hr activity"
                Icon={PeopleAltIcon}
              />
            }
          </Box>
        </Box>
        <Box sx={{
          display: "grid",
          margin: (theme) => theme.spacing(10, 4),
          gridTemplateColumns: "100%",
        }}>
          <div>
            <div className="ag-theme-material">
              <Box sx={{
                height: "50vh",
              }}>
                <GridConfig
                  props={{
                    GridConfigData,
                    onGridReady,
                    onRowSelected,
                    rowData: store.data,
                  }}
                />
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

PlayerActivity.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PlayerActivity;
