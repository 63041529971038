import * as constants from "./LanguageConstants";

const initialState = {
  languages: [],
  selectedLanguage: {},
  translations: [],
};

export const getLanguages = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_GET_LANGUAGE_SUCCESS:
      return { languages: action.payload };
    default:
      return state;
  }
};

export const getTranslations = (state = initialState, action = {}) => {
  // console.log(action.payload)
  switch (action.type) {
    case constants.REQUEST_GET_TRANSLATION_SUCCESS:
      return { ...state, translations: action.payload };
    default:
      return state;
  }
};

export const setLanguage = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_SET_LANGUAGE:
      return { ...state, selectedLanguage: action.payload };
    default:
      return state;
  }
};
