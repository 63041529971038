const columnDefs = (context) => [
    {
        headerName: context.t("Created Date"),
        field: "createdAt",
        cellClass: "cell-default-style",
        filter: "agDateColumnFilter",
        minWidth: 200,
        filterParams: {
          cellHeight: 20,
          debounceMs: 100,
          filterOptions: ["inRange"],
          comparator(filterLocalDateAtMidnight, cellValue) {
            let dateAsString = cellValue;
            let dateParts = dateAsString.split("/");
            let cellDate = new Date(
              Number(dateParts[2].slice(0, 4)),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
        },
      },
      {
        headerName: context.t("Transaction ID"),
        field: "uuid",
        cellClass: "cell-default-style",
        filter: "agNumberColumnFilter",
        filterParams: {
          cellHeight: 20,
          debounceMs: 100,
          filterOptions: ["equals", "lessThan", "greaterThan"],
        },
      },
      {
        headerName: context.t("Transaction Name"),
        valueGetter: function GetField(params) {
          return params.data.game.name;
        },
        cellClass: "cell-default-style",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
        },
      },
      {
        headerName: context.t("Type"),
        valueGetter: function GetField(params) {
          return params.data.freebetId === null ? "Bet" : "Free Bet";
        },
        cellClass: "cell-default-style",
      },
      {
        headerName: context.t("Unique Ref"),
        field: "ref",
        cellClass: "cell-default-style",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals", "lessThan", "greaterThan"],
        },
      },
      {
        headerName: context.t("Balance Type"),
        valueGetter: function GetField(params) {
          return params.data.isBonus ? "Promo" : "Cash";
        },
        cellClass: "cell-default-style",
      },
      {
        headerName: context.t("Amount"),
        field: "amount",
        cellClass: "cell-default-style",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals", "lessThan", "greaterThan"],
        },
      },
      {
        headerName: context.t("Result"),
        valueGetter: function GetField(params) {
          return params.data.type ? params.data.type.type === "bet" ? "loss" : params.data.type.type : '';
        },
        cellClass: "cell-default-style",
      },
      {
        headerName: context.t("Currency"),
        valueGetter: function GetField(params) {
          return params.data.currency.code;
        },
        cellClass: "cell-default-style",
      },
  ];
  const defaultColDef = {
    resizable: true,
    filter: true,
  };
  const floatingFilter = true;

  const rowSelection = "single";

  const rowClassRules = {
    "row-green": "data.amount > 0",
    "row-red": "data.amount < 0",
  };

  export const GridConfigData = {
    columnDefs,
    defaultColDef,
    floatingFilter,
    rowSelection,
    rowClassRules
  };
