import React from "react";
import ChangeThemeButton from "./Theme/ChangeThemeButton";
import ProfileTopNav from "./Profile/Profile";
import Glossary from "./Glossary/Glossary";
import ChooseLanguage from "./ChooseLanguage/ChooseLanguage"

const MenuTopNav = () => {
  return (
    <>
      <Glossary />
      <ChangeThemeButton />
      <ChooseLanguage />
      <ProfileTopNav />
    </>
  );
};

export default MenuTopNav;
