import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

const Review = ({data}, context) => {
  return (
    <Box sx={(theme) => ({
      marginTop: 4,
      display: "grid",
      gridTemplateColumns: "30% 70%",
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "100%",
      },
    })}>
      <Box sx={(theme) => ({
        borderRight: `1px solid ${theme.palette.primary.main}`,
      })}>
        <Typography variant="h5" noWrap paragraph>
          {context.t("Bonus Detail")}:
        </Typography>
        <Typography variant="body1" noWrap paragraph>
          {data.bonusRecord.bonus.name}
        </Typography>
      </Box>
      <Box sx={{
        margin: (theme) => theme.spacing(0, 2)
      }}>
        <Typography variant="h5" noWrap paragraph>
          {context.t("Players Details")}:
        </Typography>
        {data.playerIds.info.map((el) => (
          <Typography variant="body1" paragraph>
            {el.firstName} {el.lastName} - {el.email}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

Review.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default Review;
