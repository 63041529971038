import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { getGlossaries, updateGlossary, addGlossaries } from "../../containers/glossary/store/GlossaryActions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from "@mui/material";
import { getLanguages } from "../../containers/languages/store/LanguageActions";
import AddGlossary from "./addGlossary";
import GlossaryFormContent from "./glossayFormContent";

const DisplayGlossary = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    glossaries: state.Glossaries.EN,
    lang: state.Languages.languages,
    glossariesALT: state.Glossaries.ALT,
  }));
  const [glossaryId, setGlossaryId] = useState(null);
  const [language, setAddLanguageStatus] = useState(null);
  const [EN, setEN] = useState({
    heading: "",
    description: "",
  });
  const [ALT, setALT] = useState({
    heading: "",
    description: "",
  });

  // Glossary API call for language except EN
  useEffect(() => {
    if (language) {
      dispatch(getGlossaries(language, "CODE"));
    }
  }, [language]);

  // Update Glossary when language is changed
  useEffect(() => {
    let result;
    if (store.glossariesALT?.content?.length) {
      result = store.glossariesALT.content.filter((item) => item.id === glossaryId);
      if (result.length) {
        setALT({
          heading: result[0].heading,
          description: result[0].content,
        });
      } else {
        setALT({
          heading: "",
          description: "",
        });
      }
    } else {
      setALT({
        heading: "",
        description: "",
      });
    }
  }, [store.glossariesALT]);

  // set state variable for EN
  const handleChangeEN = (event, code) => {
    if (code === "heading") {
      setEN({...EN, heading: event.target.value});
    } else {
      setEN({...EN, description: event.target.value});
    }
  };

  // set state variable for other language
  const handleChangeALT = (event, code) => {
    if (code === "heading") {
      setALT({...ALT, heading: event.target.value});
    } else {
      setALT({...ALT, description: event.target.value});
    }
  };

  useEffect(() => {
    // Get glossary for EN language
    dispatch(getGlossaries("en_GB"));
    // Get all available language
    dispatch(getLanguages({all: true}));
  }, []);

  //
  useEffect(() => {
    let result;
    if (store.glossaries?.content) {
      result = store.glossaries.content.filter((item) => item.id === glossaryId);
      if (glossaryId) {
        setEN({
          heading: result[0].heading,
          description: result[0].content,
        });
      }
    }
  }, [glossaryId]);

  const editAction = (e, id) => {
    console.log("edit", glossaryId);
    setGlossaryId(id);
  };

  const addItemToGlossary = ({heading, description}, language) => {
    dispatch(
      addGlossaries({
        id: glossaryId,
        lanCode: language,
        content: {heading, content: description},
      })
    );
  };
  const updateItemInGlossary = ({heading, description}, language) => {
    dispatch(
      updateGlossary({
        id: glossaryId,
        lanCode: language,
        content: {heading, content: description},
      })
    );
  };
  const submit = (e) => {
    e.preventDefault();
    updateItemInGlossary(EN, "en_GB");
  };
  const submitALT = (e) => {
    e.preventDefault();
    if (!store.glossariesALT.content) {
      addItemToGlossary(ALT, language);
    } else {
      const result = store.glossariesALT.content.filter((item) => item.id === glossaryId);
      if (result.length) {
        updateItemInGlossary(ALT, language);
      } else {
        addItemToGlossary(ALT, language);
      }
    }
  };
  return (
    <div>
      {!glossaryId ? (
        <>
          <AddGlossary/>
          {store?.glossaries?.content && (
            <TableContainer component={Paper}>
              <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {store.glossaries.content.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {border: 0},
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {item.heading}
                      </TableCell>
                      {/* <TableCell align="right">{row.heading}</TableCell> */}
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          onClick={(e) => {
                            editAction(e, item.id);
                          }}
                        >
                          Update
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      ) : (
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1.25rem",
        }}>
          <form onSubmit={submit}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <Box sx={{
                marginBottom: "30px",
                border: "1px solid gray",
                borderRadius: "4px",
                padding: "16px 16px",
              }}>English</Box>
              <Box sx={{
                marginBottom: "30px",
              }}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Multiline"
                  multiline
                  maxRows={4}
                  value={EN.heading}
                  name="heading"
                  onChange={(e) => {
                    handleChangeEN(e, "heading");
                  }}
                />
              </Box>

              <Box sx={{
                marginBottom: "30px",
              }}>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={10}
                  name="description"
                  defaultValue="Default Value"
                  value={EN.description}
                  onChange={(e) => {
                    handleChangeEN(e, "description");
                  }}
                />
              </Box>
              <Box sx={{
                marginBottom: "30px",
              }}>
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </Box>
            </Box>
          </form>
          <GlossaryFormContent
            data={ALT}
            setData={setALT}
            language={language}
            setLanguage={setAddLanguageStatus}
            submit={submitALT}
          />
        </Box>
      )}
    </div>
  );
};

export default DisplayGlossary;
