import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Slider,
  Typography,
  Button,
  TextField,
  FormControl,
  Input,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getGameProvidersData } from "../../../../../store/game_providers/GameProvidersActions";
import {
  getGameTypesData,
  getGameConditionsData,
} from "../../../../../store/games/GamesActions";
import { getPlatformCurrencies } from "../../../../settings/currencies/store/currenciesActions";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import { registerPlugin } from "react-filepond";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageEdit
);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxWidth: 250,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
const bonusPercentage = [
  {
    value: 50,
    label: "50%",
  },
  {
    value: 100,
    label: "100%",
  },
  {
    value: 150,
    label: "150%",
  },
  {
    value: 200,
    label: "200%",
  },
];
const wagger = [
  {
    value: 30,
    label: "x30",
  },
  {
    value: 35,
    label: "x35",
  },
  {
    value: 40,
    label: "x40",
  },
  {
    value: 45,
    label: "x45",
  },
];

const BonusInformation = ({bonusData, gamesFilter}, context) => {
  const {bonusType, setBonusType} = bonusData;
  const {gamesFilters, setGamesFilters} = gamesFilter;
  const store = useSelector((state) => ({
    gameProviders: state.GameProviders.gameProviders,
    gameTypes: state.Games.gameTypes,
    gameConditions: state.Games.gameConditions,
    enabledCurrencies: state.PlatformConfig.enabledCurrencies,
  }));

  const dispatch = useDispatch();
  const depositProvidersList = store.gameProviders.map((el) => el.name);
  const [DepositProvider, setDepositProvider] = useState(
    gamesFilters.gameProvider
  );
  const [bonusCategory, setbonusCategory] = useState(gamesFilters.gameType);
  const [checkedAll, setCheckedAll] = useState(false);
  const [currency, setCurrency] = useState(null);
  const changebonusCategory = (event) => {
    setbonusCategory(event.target.value);
    setGamesFilters({...gamesFilters, gameType: event.target.value});
  };
  useEffect(() => {
    dispatch(getGameProvidersData());
    dispatch(getGameTypesData());
    dispatch(getGameConditionsData());
    dispatch(getPlatformCurrencies());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeDepositProvider = (event) => {
    if (!checkedAll && event.target.value.includes("Select all")) {
      setCheckedAll(true);
      setDepositProvider(depositProvidersList);
      setGamesFilters({...gamesFilters, gameProvider: depositProvidersList});
    }
    if (checkedAll && event.target.value.includes("Select all")) {
      setCheckedAll(false);
      setDepositProvider([]);
      setGamesFilters({...gamesFilters, gameProvider: []});
    }
    if (!checkedAll && !event.target.value.includes("Select all")) {
      setCheckedAll(false);
      setDepositProvider(event.target.value);
      setGamesFilters({...gamesFilters, gameProvider: event.target.value});
    }
    if (checkedAll && !event.target.value.includes("Select all")) {
      setCheckedAll(false);
      setDepositProvider(event.target.value);
      setGamesFilters({...gamesFilters, gameProvider: event.target.value});
    }
    if (
      !checkedAll &&
      !event.target.value.includes("Select all") &&
      event.target.value.length === depositProvidersList.length
    ) {
      setCheckedAll(true);
      setDepositProvider(event.target.value);
      setGamesFilters({...gamesFilters, gameProvider: event.target.value});
    }
  };
  const handleChange = (event) => {
    event.preventDefault();
    const field =
      event.target.id !== undefined ? event.target.id : event.target.name;
    const value = event.target.value;
    setBonusType((prevState) => ({...prevState, [field]: value}));
  };

  const handleCurrencyChange = (event) => {
    event.preventDefault();
    setBonusType({...bonusType, currency: event.target.value});
    const cur = store.enabledCurrencies.find(
      (currency) => currency.code === event.target.value
    );
    setCurrency(cur);
  };

  const range = (min, max) => {
    const step = (max - min) / 3;
    const len = Math.floor((max - min) / step) + 1;
    const arr = Array(len)
    .fill()
    .map((_, idx) => ({
      value: Math.ceil((min + idx * step) / 10) * 10,
      label: `${Math.ceil((min + idx * step) / 10) * 10}  ${currency.code}`,
    }));
    return arr;
  };
  const configureStep = (value) => {
    // https://stackoverflow.com/questions/6665997/switch-statement-for-greater-than-less-than
    if (value > 1000) {
      return 10;
    }
    if (value > 10000) {
      return 100;
    }
    if (value > 100000) {
      return 1000;
    }
    return 1;
  };

  const imagePreView = (event) => {
    if (event.currentTarget.files[0] instanceof Blob) {
      setBonusType((preV) => ({
        ...preV,
        image: event.currentTarget.files[0],
      }));
      const reader = new FileReader();
      reader.onloadend = (event) => {
        const img = new Image();
        img.src = reader.result;
        setBonusType((preV) => ({...preV, previewImage: reader.result}));
      };
      reader.readAsDataURL(event.currentTarget.files[0]);
    }
  };

  return (
    <>
      <Box sx={{
        display: "grid",
        justifyItems: "center",
        "& .MuiSvgIcon-root": {color: (theme) => theme.palette.primary.main},
      }}>
        <Typography variant="h5" sx={{
          justifySelf: "start",
          marginBottom: 3,
        }}>
          {context.t("Bonus Information")}:
        </Typography>
        <Box sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 50%)",
          gridTemplateAreas: "'column column'",
          justifyItems: "center",
          marginBottom: (theme) => theme.spacing(5),
          [(theme) => theme.breakpoints.down("md")]: {
            gridTemplateColumns: "100%",
            gridTemplateAreas: "'column'",
          },
        }}>
          <TextField
            id="name"
            label={context.t("Bonus Type Name")}
            value={bonusType.name}
            onChange={handleChange}
            type="text"
            required
            sx={{
              margin: (theme) => theme.spacing(1, 1, 1, 0),
              minWidth: 300,
              maxWidth: 300,
            }}
          />
          <FormControl
            sx={{
              margin: (theme) => theme.spacing(1, 1, 1, 0),
              minWidth: 300,
              maxWidth: 300,
            }}
            variant="outlined"
            required
          >
            <InputLabel id="game-providers">{context.t("Game Providers")}</InputLabel>
            <Select
              labelId="game-providers"
              label={context.t("Game Providers")}
              multiple
              value={DepositProvider}
              onChange={changeDepositProvider}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              <MenuItem key={"Select all"} value={"Select all"}>
                <Checkbox checked={checkedAll} sx={{
                  "& .MuiSvgIcon-root": {color: (theme) => theme.palette.primary.main},
                }}/>
                <ListItemText
                  primary={
                    checkedAll
                      ? context.t("Select None")
                      : context.t("Select all")
                  }
                />
              </MenuItem>
              {depositProvidersList.map((provider) => (
                <MenuItem key={provider} value={provider}>
                  <Checkbox
                    checked={DepositProvider.indexOf(provider) > -1}
                    sx={{
                      "& .MuiSvgIcon-root": {color: (theme) => theme.palette.primary.main},
                    }}
                  />
                  <ListItemText primary={provider}/>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              margin: (theme) => theme.spacing(1, 1, 1, 0),
              minWidth: 300,
              maxWidth: 300,
            }}>
            <InputLabel required id="game-types">{context.t("Game Types")}</InputLabel>
            <Select
              labelId="game-types"
              label={context.t("Game Types")}
              value={bonusCategory}
              onChange={changebonusCategory}
              labelWidth={120}
            >
              <MenuItem value={"All"}>{context.t("All")}</MenuItem>
              {store.gameTypes.map((el) => (
                <MenuItem value={el.name} key={el.name}>
                  {el.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              margin: (theme) => theme.spacing(1, 1, 1, 0),
              minWidth: 300,
              maxWidth: 300,
            }}>
            <InputLabel required id="deposit-condition">{context.t("Deposit Condition")}</InputLabel>
            <Select
              label={context.t("Deposit Condition")}
              labelId="deposit-condition"
              value={bonusType.condition}
              onChange={(event) =>
                setBonusType({...bonusType, condition: event.target.value})}
              labelWidth={120}
            >
              {store.gameConditions.map((el) => (
                <MenuItem value={el.condition} key={el.condition}>
                  {el.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DatePicker
            autoOk
            required
            views={['year', 'month', 'day']}
            openTo="year"
            disablePast={true}
            label={context.t("Bonus Start Date")}
            format="MM/dd/yyyy"
            value={bonusType.startDate}
            onChange={(date) => {
              date = new Date(date.toDate().setHours(0, 0, 0, 0));
              return setBonusType({...bonusType, startDate: date});
            }}
            renderInput={(params) => <TextField
              sx={{
                margin: (theme) => theme.spacing(1, 1, 1, 0),
                minWidth: 300,
                maxWidth: 300,
              }}
              color="secondary"
              {...params}
            />}
          />
          <DatePicker
            autoOk
            required
            views={['year', 'month', 'day']}
            openTo="year"
            disablePast={true}
            label={context.t("Bonus End Date")}
            format="MM/dd/yyyy"
            value={bonusType.endDate}
            onChange={(date) => {
              date = new Date(date.toDate().setHours(23, 59, 59, 999));
              return setBonusType({...bonusType, endDate: date});
            }}
            renderInput={(params) => <TextField
              sx={{
                margin: (theme) => theme.spacing(1, 1, 1, 0),
                minWidth: 300,
                maxWidth: 300,
              }}
              color="secondary"
              {...params}
            />}
          />
          <FormControl
            sx={{
              margin: (theme) => theme.spacing(1, 1, 1, 0),
              minWidth: 300,
              maxWidth: 300,
            }}>
            <InputLabel required id="currency">{context.t("Currency")}</InputLabel>
            <Select
              labelId="currency"
              label={context.t("Currency")}
              value={bonusType.currency}
              onChange={(event) => handleCurrencyChange(event)}
              labelWidth={120}
            >
              {store.enabledCurrencies.map((currency) => (
                <MenuItem value={currency.code}>{currency.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="approveLimit"
            value={bonusType.approveLimit}
            onChange={handleChange}
            label={context.t("Total Bonus Cap")}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
                max: currency?.maxApproveLimit || 10000,
              },
            }}
            required
            sx={{
              margin: (theme) => theme.spacing(1, 1, 1, 0),
              minWidth: 300,
              maxWidth: 300,
            }}
          />
          <TextField
            id="code"
            value={bonusType.code}
            onChange={handleChange}
            label={context.t("Promo Code")}
            type="text"
            required
            sx={{
              margin: (theme) => theme.spacing(1, 1, 1, 0),
              minWidth: 300,
              maxWidth: 300,
            }}
          />
          <TextField
            id="duration"
            value={bonusType.duration}
            onChange={handleChange}
            label={context.t("Duration Per Player (in days)")}
            type="number"
            required
            InputProps={{inputProps: {min: 0, max: 10000}}}
            sx={{
              margin: (theme) => theme.spacing(1, 1, 1, 0),
              minWidth: 300,
              maxWidth: 300,
            }}
          />
          <TextField
            id="freeSpins"
            value={bonusType.freeSpins}
            onChange={handleChange}
            label={context.t("Amount of Free Spins")}
            type="number"
            InputProps={{inputProps: {min: 0, max: 10000}}}
            sx={{
              margin: (theme) => theme.spacing(1, 1, 1, 0),
              minWidth: 300,
              maxWidth: 300,
            }}
          />
          <TextField
            id="maxConversionLimit"
            value={currency?.maxConversionLimit || 0}
            label={context.t("Maximum Conversion Limit")}
            disabled
            sx={{
              margin: (theme) => theme.spacing(1, 1, 1, 0),
              minWidth: 300,
              maxWidth: 300,
            }}
          />
          <TextField
            value={bonusType.description}
            onChange={handleChange}
            margin="dense"
            id="description"
            label={context.t("Bonus Description")}
            multiline
            required
            rows={4}
            sx={{
              gridColumnStart: "column-start",
              gridColumnEnd: "column-end",
              width: "100%",
            }}
          />
          <Box sx={{
            gridColumnStart: "column-start",
            gridColumnEnd: "column-end",
            width: "100%",
          }}>
            {bonusType.previewImage && (
              <Box
                component="img"
                src={bonusType.previewImage}
                sx={{
                  marginTop: "20px",
                  width: "300px",
                  height: "250px",
                  borderRadius: "2%",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}/>
            )}
          </Box>
          <Box sx={{
            width: "100%",
            marginTop: "20px",
            display: "flex",
            flexDirection: "row",
          }}>
            <Button
              variant="contained"
              component="label"
              sx={{
                marginRight: "20px",
              }}
            >
              {bonusType.previewImage ? "Change" : "Add Image"}
              <input
                type="file"
                hidden
                onChange={(event) => {
                  imagePreView(event);
                }}
              />
            </Button>
            {bonusType.previewImage && (
              <Button
                variant="contained"
                component="label"
                sx={{
                  marginRight: "20px",
                }}
                onClick={() => {
                  setBonusType((preV) => ({
                    ...bonusType,
                    image: "",
                    previewImage: "",
                  }));
                }}
              >
                Remove
              </Button>
            )}
          </Box>
        </Box>
        {currency !== null && (
          <>
            <Typography variant="h5" sx={{
              justifySelf: "start",
            }}>
              {context.t("Select Bonus Parameters")}:
            </Typography>
            <Box sx={{
              width: "75%",
              marginBottom: (theme) => theme.spacing(5),
            }}>
              <Typography
                variant="body1"
                sx={{
                  margin: (theme) => theme.spacing(2, 0, 5, 0),
                }}
              >
                {context.t("Select Bonus Percentage")}
              </Typography>
              <Slider
                value={bonusType.percentage}
                onChange={(_, newValue) =>
                  setBonusType({...bonusType, percentage: newValue})}
                valueLabelFormat={bonusPercentage.value}
                step={5}
                valueLabelDisplay="on"
                marks={bonusPercentage}
                max={200}
                min={50}
              />
              <Typography
                variant="body1"
                sx={{
                  margin: (theme) => theme.spacing(2, 0, 5, 0),
                }}
              >
                {context.t("Select Wagering Requirement")}
              </Typography>
              <Slider
                value={bonusType.wageringRequirement}
                onChange={(_, newValue) =>
                  setBonusType({...bonusType, wageringRequirement: newValue})}
                valueLabelFormat={wagger.value}
                step={1}
                valueLabelDisplay="on"
                marks={wagger}
                max={45}
                min={30}
              />
              <Typography
                variant="body1"
                sx={{
                  margin: (theme) => theme.spacing(2, 0, 5, 0),
                }}
              >
                {context.t("Select Maximum Bonus Amount")}
              </Typography>
              <Slider
                value={bonusType.maxAmount}
                onChange={(_, newValue) =>
                  setBonusType({...bonusType, maxAmount: newValue})}
                step={configureStep(currency.maxMaxDeposit)}
                valueLabelDisplay="on"
                marks={range(currency.minMaxAmount, currency.maxMaxDeposit)}
                max={currency.maxMaxDeposit}
                min={currency.minMaxAmount}
              />
              <Typography
                variant="body1"
                sx={{
                  margin: (theme) => theme.spacing(2, 0, 5, 0),
                }}
              >
                {context.t("Select Maximum Bonus Bet")}
              </Typography>
              <Slider
                value={bonusType.maxWageringContribution}
                onChange={(_, newValue) =>
                  setBonusType({
                    ...bonusType,
                    maxWageringContribution: newValue,
                  })}
                step={configureStep(currency.maxMaxWageringContribution)}
                valueLabelDisplay="on"
                marks={range(
                  currency.minMaxWageringContribution,
                  currency.maxMaxWageringContribution
                )}
                max={currency.maxMaxWageringContribution}
                min={currency.minMaxWageringContribution}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

BonusInformation.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BonusInformation;
