import React from "react";
import PropTypes from "prop-types";
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";


const ReviewWithdraw = ({open, setOpenEditAdmin, withdrawal}, context) => {
  const handleGoBack = (e) => {
    e.preventDefault();
    setOpenEditAdmin(false);
  };
  console.log(withdrawal)
  return (
    <>
      <Dialog open={open} maxWidth="sm">
        <DialogTitle sx={(theme) => ({
          color: theme.palette.primary.light,
          background: theme.palette.primary.main,
        })}>
          {context.t("Review Withdrawal")}
        </DialogTitle>
        <DialogContent sx={{
          display: "flex",
          flexDirection: "column",
          whiteSpace: "nowrap",
        }}>
          <form className="admin-form">
            <Box sx={(theme) => ({
              margin: theme.spacing(2, 0),
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            })}>
              <div>
                <Typography variant="body1" noWrap paragraph>
                  {context.t("Player Information")}:
                </Typography>
                <TextField
                  id="email"
                  label={context.t("Email")}
                  value={withdrawal?.player.email || ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                />
                <TextField
                  id="address"
                  label={context.t("Player Address")}
                  value={withdrawal?.player.address || "-"}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                />
                <TextField
                  id="kycStatus"
                  label={context.t("KYC")}
                  value={withdrawal?.player.kycStatus ? 'Verified ' : 'Not Verified' || ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                />
                <TextField
                  id="currency_player"
                  label={context.t("Player Currency")}
                  value={withdrawal?.player?.currency?.code || ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                />
              </div>
              <div>
                <Typography variant="body1" noWrap paragraph>
                  {context.t("Withdrawal Information")}:
                </Typography>
                <TextField
                  id="amount"
                  label={context.t("Withdrawal Amount")}
                  value={withdrawal !== null ? withdrawal.amount : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                />
                <TextField
                  id="fee_source"
                  label={context.t("Payment Type")}
                  value={withdrawal !== null ? withdrawal.source : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                />
                <TextField
                  id="transactionPaymentiqId"
                  label={context.t("Transaction ID")}
                  value={
                    withdrawal !== null ? withdrawal.ref : ""
                  }
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                />
                <TextField
                  id="createdAt"
                  label={context.t("Request Date")}
                  value={withdrawal !== null ? withdrawal.createdAt : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                />

              </div>
            </Box>
          </form>
        </DialogContent>
        <DialogActions sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button
            target="_blank"
            href="https://test-bo.paymentiq.io/#es-approve/search/"
            color="primary"
          >
            Approve
          </Button>
          <Button onClick={handleGoBack} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ReviewWithdraw.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ReviewWithdraw;
