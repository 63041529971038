import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Typography, TextField, InputAdornment, IconButton, Button } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { updateCurrentUserInfo } from "../admin_management/store/admin_actions/adminActions";
import { checkToken } from "../../signin/login_page/store/LoginActions";

const MyProfile = ({setchangePassword, changePassword}, context) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },

    validationSchema: Yup.object().shape({
      password: Yup.string()
      .min(8, "Password must contain at least 8 characters")
      .required("Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/,
        "Password must contain at least one letter and one number"
      ),
      passwordConfirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleReset,
    isValid,
    dirty,
  } = formik;

  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(checkToken(updateCurrentUserInfo({password: values.password})));
    setchangePassword(false);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setchangePassword(false);
    handleReset(e);
  };

  return (
    <>
      {changePassword ? (
        <Box sx={(theme) => ({
          margin: theme.spacing(12, 0),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          "& .MuiSvgIcon-root": {color: theme.palette.primary.main},
        })}>
          <Typography variant="h5" component="h2">
            Change Your Password
          </Typography>
          <TextField
            required
            variant="outlined"
            sx={{
              margin: (theme) => theme.spacing(1, 1, 1, 0),
              minWidth: 350,
              maxWidth: 350,
            }}
            label={context.t("Choose a password")}
            name="password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.password ? errors.password : ""}
            error={touched.password && Boolean(errors.password)}
            fullWidth
            color="secondary"
            autoComplete="off"
          />
          <TextField
            required
            variant="outlined"
            sx={{
              margin: (theme) => theme.spacing(1, 1, 1, 0),
              minWidth: 350,
              maxWidth: 350,
            }}
            label={context.t("Confirm Password")}
            name="passwordConfirmation"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={values.passwordConfirmation}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.passwordConfirmation ? errors.passwordConfirmation : ""
            }
            error={
              touched.passwordConfirmation &&
              Boolean(errors.passwordConfirmation)
            }
            fullWidth
            color="secondary"
            autoComplete="off"
          />
          <Box sx={{
            margin: (theme) => theme.spacing(0, 10),
            float: "right",
          }}>
            <Button
              variant="contained"
              sx={(theme) => ({
                margin: theme.spacing(1, 1),
                padding: theme.spacing(1),
                minWidth: 150,
                color: theme.palette.primary.light,
                background: theme.palette.success.main,
                "&:hover": {
                  background: theme.palette.success.dark,
                },
                [theme.breakpoints.down("sm")]: {
                  marginRight: 20,
                },
              })}
              type="submit"
              onClick={handleSubmit}
              disabled={!(isValid && dirty)}
            >
              {context.t("Submit")}
            </Button>
            <Button
              variant="contained"
              sx={(theme) => ({
                margin: theme.spacing(1, 1),
                padding: theme.spacing(1),
                minWidth: 150,
                color: theme.palette.primary.light,
                background: theme.palette.error.main,
                "&:hover": {
                  background: theme.palette.error.dark,
                },
                [theme.breakpoints.down("sm")]: {
                  marginRight: 20,
                },
              })}
              onClick={handleCancel}
            >
              {context.t("Cancel")}
            </Button>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

MyProfile.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MyProfile;
