import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardActions, CardContent, Button, Typography } from "@mui/material";
import BonusDetails from "./bonusDetails";
import CloneModal from "./cloneBonus";
import DeactivateBonus from "./deactivateBonus";

export default function ImgMediaCard({bonusType}, context) {
  const [open, setOpen] = useState(false);
  const [openClone, setOpenClone] = useState(false);
  const [openDeactivate, setOpenDeactivate] = useState(false);

  const [bonus, setBonus] = useState({});
  const handleModal = () => {
    setOpen(!open);
  };
  const handleClone = () => {
    setOpenClone(!openClone);
  };
  const handleDeactivate = () => {
    setOpenDeactivate(!openDeactivate);
  };
  const options = {year: "numeric", month: "numeric", day: "numeric"};

  return (
    <>
      {bonusType.map((type, i) => {
        return (
          <Card key={type.id} sx={{
            width: "95%",
            paddingBottom: "0px",
            margin: "15px 10px",
            "& .bodyText": {
              color: (theme) => theme.palette.secondary.dark,
            },
          }}>
            <CardContent sx={{
              pb: 0
            }}>
              <div
                style={{display: "block", justifyContent: "space-between"}}
              >
                <div>
                  {" "}
                  <Typography
                    sx={{
                      fontWeight: "500",
                    }}
                    color="textSecondary"
                    gutterBottom
                  >
                    {context.t("Name")}:
                    <br/>
                    <span className="bodyText">{type.name}</span>
                  </Typography>
                </div>
                <div>
                  <Typography
                    sx={{
                      "& .status": {
                        color: type.status === "active"
                          ? "green"
                          : "red",
                      },
                    }}
                    color="textSecondary"
                  >
                    {context.t("Status")}:{" "}
                    <span className="status">{context.t(type.status)} </span>
                  </Typography>
                </div>

                <div>
                  <Typography color="textSecondary">
                    {context.t("Currency")}:{" "}
                    <span className="bodyText">
                      {type.currency?.name || "EUR"}
                    </span>
                  </Typography>
                </div>
                <div>
                  <Typography color="textSecondary">
                    {context.t("Maximum Bonus Amount")}:{" "}
                    <span className="bodyText">{type.maxAmount}</span>
                  </Typography>
                </div>
                <div>
                  <Typography color="textSecondary">
                    {context.t("Bonus Percentage")}:{" "}
                    <span className="bodyText">{type.percentage}</span>
                  </Typography>
                </div>
                <div>
                  <Typography color="textSecondary">
                    {context.t("Wagering Requirement")}:{" "}
                    <span className="bodyText">
                      x{type.wageringRequirement}
                    </span>
                  </Typography>
                </div>
                <div>
                  <Typography color="textSecondary">
                    {context.t("Duration")}:
                    <br/>
                    <span className="bodyText">
                      {new Date(type.startDate).toLocaleString(
                        "en-US",
                        options
                      )}{" "}
                      -{" "}
                      {new Date(type.endDate).toLocaleString("en-US", options)}
                    </span>
                    <br/>
                  </Typography>
                </div>
              </div>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={() => {
                  setBonus(bonusType[i]);
                  handleModal();
                }}
              >
                {context.t("More Info")}
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setBonus(bonusType[i]);
                  handleClone();
                }}
              >
                {context.t("Clone")}
              </Button>
              {type.status === "active" ?? <Button
                size="small"
                onClick={() => {
                  setBonus(bonusType[i]);
                  handleDeactivate();
                }}
              >
                {context.t("Deactivate")}
              </Button>}
            </CardActions>
          </Card>
        );
      })}
      <BonusDetails open={open} close={handleModal} data={bonus}/>
      <CloneModal open={openClone} close={handleClone} data={bonus}/>
      <DeactivateBonus
        open={openDeactivate}
        close={handleDeactivate}
        data={bonus}
      />
    </>
  );
}

ImgMediaCard.contextTypes = {
  t: PropTypes.func.isRequired,
};
