import React from "react";
import PropTypes from "prop-types";
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";

const LoadingComponent = () => {
  return (
    <Box sx={{
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      zIndex: 2000,
      position: 'fixed',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }}>
      {/* LOADING ... */}
      <CircularProgress size={100}/>
    </Box>
  );
};

LoadingComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default LoadingComponent;
