import { lightBlue, red } from "@mui/material/colors";

export const themesConfig = {
  legacy: {
    palette: {
      mode: "light",
      primary: {
        light: "#EFF3F6",
        main: "#1E1B4D",
        dark: "#d1d3d6",
      },
      secondary: {
        light: '#0E0C28',
        main: '#F7F7F7',
        dark: "#1E1B4D",
        contrastText: lightBlue[800],
      },
      background: {
        paper: "#F7F7F7",
        default: "#EFF3F6",
      },
      error: red,
    },
    props: {
      MuiSvgIcon: {
        htmlColor: "#FFFFFF",
      },
    },
    status: {
      danger: "orange",
    },
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            color: 'rgba(255, 255, 255, 0.7)',
            '&.Mui-focused': {
              transform: 'translate(0, 1.5px) scale(0.75)',
              transformOrigin: 'top left',
            },
          },
          outlined: {
            transform: 'translate(0, 20px) scale(1)',
          },
          shrink: {
            transform: 'translate(0, 1.5px) scale(0.75) !important',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&:hover': {},
            '& .Mui-disabled': {
              '-webkit-text-fill-color': 'rgba(255, 255, 255, 0.5) !important',
            },
            '::before': {
              borderColor: 'rgba(255, 255, 255, 0.7)',
            },
          },
        },
      },
    },
  },
  light1: {
    palette: {
      type: "light",
      primary: {
        light: "#C2C7F1",
        main: "#FFFFFF",
        dark: "#161EB3",
      },
      secondary: {
        light: "#F9F9F9",
        main: "#4379FF",
        dark: "#1E1F23",
        contrastText: "#1E1F23",
      },
      background: {
        paper: "#F9F9F9",
        default: "#F7FAFF",
      },
      error: red,
    },
    props: {
      MuiSvgIcon: {
        htmlColor: "#606060",
      },

    },
    status: {
      danger: "orange",
    },
  },
  dark1: {
    palette: {
      type: "dark",
      primary: {
        light: "#262526",
        main: "#323338",
        dark: "#131417",
      },
      secondary: {
        light: "#262526",
        main: "#129B7F",
        dark: "#056D4F",
        contrastText: "#FFFFFF",
      },
      background: {
        paper: "#262526",
        default: "#1E1D1E",
      },
      error: red,
    },
    props: {
      MuiSvgIcon: {
        htmlColor: "#129B7F",
      },
    },
    status: {
      danger: "orange",
    },
  },
  dark2: {
    palette: {
      type: "dark",
      primary: {
        light: "#23354E",
        main: "#354968",
        dark: "#16213A",
      },
      secondary: {
        light: "#23354E",
        main: "#FFFFFF", // "#D55847",
        dark: "#FFFFFF",
        contrastText: "#FFFFFF",
      },
      background: {
        paper: "#23354E",
        default: "#1B2A3F",
      },
      error: red,
    },
    status: {
      danger: "orange",
    },
  },
  dark3: {
    palette: {
      type: "dark",
      primary: {
        light: "#2A2E32",
        main: "#FEBE3E",
        dark: "#FD991B",
      },
      secondary: {
        light: "#2A2E32",
        main: "#c7c7c7", //
        dark: "#FFFFFF",
        contrastText: "#FFFFFF",
      },
      background: {
        paper: "#2A2E32",
        default: "#212529",
      },
      error: red,
    },
    props: {
      MuiSvgIcon: {
        htmlColor: "#FFFFFF",
      },
    },
    status: {
      danger: "orange",
    },
  },
  dark4: {
    palette: {
      type: "dark",
      primary: {
        light: "#BCC8CD",
        main: "#204657",
        dark: "#0B202C",
      },
      secondary: {
        light: "#1C1E27",
        main: "#00BD3E",
        dark: "#00981B",
        contrastText: "#FFFFFF",
      },
      background: {
        paper: "#1C1E27",
        default: "#15171E",
      },
      error: red,
    },
    props: {
      MuiSvgIcon: {
        htmlColor: "#00BD3E",
      },
    },
    status: {
      danger: "orange",
    },
  },
};

export default themesConfig;
