import React from "react";
import PropTypes from "prop-types";
import BaseReports from "../../../../components/reports/BaseReports";
import { reportsDataMock } from "../mock_data/reportsMock";
import { Box, Button, TextField, Dialog, DialogContent, DialogActions, DialogTitle, Typography } from "@mui/material";

const ReviewBonusRecord = ({open, setOpen, data}, context) => {
  const handleGoBack = (e) => {
    e.preventDefault();
    setOpen(false);
  };
  return (
    <>
      <Dialog open={open} maxWidth="sm">
        <DialogTitle sx={(theme) => ({
          color: theme.palette.primary.light,
          background: theme.palette.primary.main,
        })}>
          {context.t("Bonus Performance Report")}
        </DialogTitle>
        <DialogContent sx={{
          display: "flex",
          flexDirection: "column",
          whiteSpace: "nowrap",
        }}>
          <form>
            <Typography
              sx={{
                margin: (theme) => theme.spacing(2, 0),
              }}
              variant="body1"
              noWrap
              paragraph>
              {context.t("Bonus Performance")}:
            </Typography>
            <Box sx={{
              margin: (theme) => theme.spacing(2, 0),
              display: "flex",
              justifyContent: "left",
              flexWrap: "wrap",
            }}>
              <TextField
                id="name"
                label={context.t("Name")}
                value={data !== null ? data.name : ""}
                variant="outlined"
                sx={{
                  margin: (theme) => theme.spacing(1),
                  minWidth: 200,
                }}
              />
              <TextField
                id="status"
                label={context.t("Status")}
                value={
                  data !== null ? (data.status) : ""
                }
                variant="outlined"
                sx={{
                  margin: (theme) => theme.spacing(1),
                  minWidth: 200,
                }}
              />
              <TextField
                id="bonusGiven"
                label={context.t("Bonus Given")}
                value={data !== null ? data.bonusEntries.length : ""}
                variant="outlined"
                sx={{
                  margin: (theme) => theme.spacing(1),
                  minWidth: 200,
                }}
              />
              <TextField
                id="startDate"
                label={context.t("Start Date")}
                value={
                  data !== null
                    ? new Date(data.startDate).toDateString()
                    : ""
                }
                variant="outlined"
                sx={{
                  margin: (theme) => theme.spacing(1),
                  minWidth: 200,
                }}
              />
              <TextField
                id="endDate"
                label={context.t("End Date")}
                value={
                  data !== null ? new Date(data.endDate).toDateString() : ""
                }
                variant="outlined"
                sx={{
                  margin: (theme) => theme.spacing(1),
                  minWidth: 200,
                }}
              />
            </Box>

            <div>
              <div>
                <BaseReports
                  type="bar"
                  title="CTR vs Claim Report"
                  data={reportsDataMock.newreg.bar}
                />
              </div>
              <div>
                <BaseReports
                  type="line"
                  title="Spent vs Given Report"
                  data={reportsDataMock.bonusSpent.line}
                />
              </div>
              <div>
                <BaseReports
                  type="pie"
                  title="Game Providers Report"
                  data={reportsDataMock.popularGames.pie}
                />
              </div>
              <div>
                <BaseReports
                  type="line"
                  title="NGR Report"
                  data={reportsDataMock.bonusNGR.line}
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}>
          <Button onClick={handleGoBack} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ReviewBonusRecord.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ReviewBonusRecord;
