import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, TextField, Typography, InputLabel, MenuItem, FormControl, Select, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import BaseReports from "../../../components/reports/BaseReports";
import { reportsDataMock } from "../admin_dashboard/mock_data/reportsMock";

const drawerWidth = '270px';
const marginTop = '100px';

const AdminReports = (_, context) => {
  // Form Data
  const [data, setData] = useState(reportsDataMock);
  const [prevReportType, setPrevReportType] = useState("adminGGRvsNGR");

  const [currency, setCurrency] = useState("eur");
  const changeCurrency = (event) => {
    setCurrency(event.target.value);
  };
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [reportType, setReportType] = useState("GGRvsNGR");
  const changeReportType = (event) => {
    setIsSubmitClicked(false);
    setReportType(event.target.value);
  };

  const [startDate, handleStartDateChange] = useState(new Date());
  const [endDate, handleEndDateChange] = useState(new Date());

  const handleSubmit = (e) => {
    e.preventDefault();
    setPrevReportType(reportType);
    setIsSubmitClicked(true);
  };

  const renderChart = () => {
    let reportData = isSubmitClicked ? data[reportType] : data[prevReportType];
    let currentData = Object.keys(reportData);

    return (
      <BaseReports type={currentData[0]} data={reportData[currentData[0]]}/>
    );
  };
  useEffect(() => {
    setData(reportsDataMock);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            Reports
          </Typography>
          <form className="reports-form" onSubmit={handleSubmit}>
            <Box sx={(theme) => ({
              margin: theme.spacing(5, 0),
              display: "flex",
              flexWrap: "wrap",
              [theme.breakpoints.down("md")]: {
                margin: theme.spacing(1, 0),
                justifyContent: "center",
              },
              "& .MuiSvgIcon-root": {color: theme.palette.primary.main},
            })}>
              <FormControl variant="outlined" sx={{
                margin: (theme) => theme.spacing(1, 1, 1, 0),
                minWidth: 350,
              }}>
                <InputLabel id="currency">{context.t("Currency")}</InputLabel>
                <Select
                  labelId="currency"
                  label={context.t("Currency")}
                  value={currency}
                  onChange={changeCurrency}
                  labelWidth={65}
                >
                  <MenuItem value={"eur"}>EUR - Euro</MenuItem>
                  <MenuItem value={"usd"}>USD</MenuItem>
                  <MenuItem value={"gbp"}>GBP</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" sx={{
                margin: (theme) => theme.spacing(1, 1, 1, 0),
                minWidth: 350,
              }}>
                <InputLabel id="report-type">{context.t("Report Type")}</InputLabel>
                <Select
                  labelId="report-type"
                  label={context.t("Report Type")}
                  value={reportType}
                  onChange={changeReportType}
                  labelWidth={90}
                >
                  <MenuItem value={"ggr"}>
                    {context.t("Gross gaming Revenue")}
                  </MenuItem>
                  <MenuItem value={"averageRev"}>
                    {context.t("Net gaming Revenue")}
                  </MenuItem>
                  <MenuItem value={"GGRvsNGR"}>
                    {context.t("GGR vs NGR")}
                  </MenuItem>
                  <MenuItem value={"NGRtoDeposits"}>
                    {context.t("NGR to Deposits")}
                  </MenuItem>
                  <MenuItem value={"betsToDeposits"}>Bets To Deposits</MenuItem>
                  <MenuItem value={"betsToDeposits"}>
                    Withdrawal to bets ratio
                  </MenuItem>
                  <MenuItem value={"newreg"}>New Players</MenuItem>
                  <MenuItem value={"averageRev"}>Cost of Bonus</MenuItem>
                  <MenuItem value={"averageRev"}>Turnover</MenuItem>
                  <MenuItem value={"averageRev"}>Bets per player</MenuItem>
                  <MenuItem value={"averageRev"}>Margin</MenuItem>
                  <MenuItem value={"CPA"}>{context.t("CPA")}</MenuItem>
                  <MenuItem value={"averageRev"}>
                    {context.t("Average Revenue")}
                  </MenuItem>
                  <MenuItem value={"averageRev"}>Average bet</MenuItem>
                  <MenuItem value={"averageRev"}>Bonus costs</MenuItem>
                  <MenuItem value={"averageRev"}>Conversion rate</MenuItem>
                  <MenuItem value={"averageRev"}>Churn rate</MenuItem>
                  <MenuItem value={"conversionToChurn"}>
                    Conversion To Churn
                  </MenuItem>
                  <MenuItem value={"averageRev"}>
                    Customer lifetime value
                  </MenuItem>
                  <MenuItem value={"averageRev"}>Churn Rate</MenuItem>
                  <MenuItem value={"countries"}>Countries</MenuItem>
                  <MenuItem value={"betToBankrollRatio"}>
                    Bet to Bankroll ratio
                  </MenuItem>
                </Select>
              </FormControl>
              <DatePicker
                sx={{
                  margin: (theme) => theme.spacing(1, 1, 1, 0),
                  minWidth: 350,
                }}
                autoOk
                views={['year', 'month', 'day']}
                openTo="year"
                label={context.t("Start Date")}
                format="MM/dd/yyyy"
                value={startDate}
                onChange={(date) => handleStartDateChange(date)}
                renderInput={(params) => <TextField
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                    maxWidth: 350,
                  }}
                  {...params}
                />}
              />
              <DatePicker
                sx={{
                  margin: (theme) => theme.spacing(1, 1, 1, 0),
                  minWidth: 350,
                }}
                autoOk
                views={['year', 'month', 'day']}
                openTo="year"
                label={context.t("End Date")}
                format="MM/dd/yyyy"
                value={endDate}
                onChange={(date) => handleEndDateChange(date)}
                renderInput={(params) => <TextField
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                    maxWidth: 350,
                  }}
                  {...params}
                />}
              />
            </Box>
            <Button
              variant="contained"
              sx={(theme) => ({
                padding: theme.spacing(2),
                marginRight: '120px',
                minWidth: 220,
                float: "right",
                color: theme.palette.primary.light,
                background: theme.palette.success.main,
                "&:hover": {
                  background: theme.palette.success.dark,
                },
                [theme.breakpoints.down("sm")]: {
                  marginRight: 20,
                },
              })}
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Box>
        <Box sx={(theme) => ({
          margin: theme.spacing(1, 42),
          [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(1, 4),
          },
          [theme.breakpoints.down("xs")]: {
            margin: theme.spacing(1),
          },
        })}>{renderChart()}</Box>
      </Box>
    </>
  );
};

AdminReports.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AdminReports;
