import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Typography, Button, Box } from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Widget from "../../components/widget/Widget";
import GridConfig from "../../components/grid/GridConfig";
import { GridConfigData } from "./grid_data/GridData";
import { getPlayersData } from "../player_management/store/grid_player_load/gridPlayersActions";

const drawerWidth = '270px';
const marginTop = '100px';

const ResponsibleGaming = (_, context) => {
  const store = useSelector((state) => ({
    data: state.PlayersData.players,
  }));
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState({});
  const [playerId, setplayerId] = useState({disabled: true, id: null})
  useEffect(() => {
    dispatch(getPlayersData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Grid
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const onRowSelected = (e) => {
    const focusedCell = gridApi.getFocusedCell();
    const selectedInfo = gridApi.getDisplayedRowAtIndex(focusedCell.rowIndex);
    selectedInfo.selected
      ? setplayerId({ id: selectedInfo.data.uuid, disabled: false })
      : setplayerId({ id: null, disabled: true });
  };
  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("Responsible Gaming")}
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("Total Players")}
                data={store.data.length}
                description="Total registered players"
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Active Players")}
                data={store.data.filter((el) => el.status.name === "active").length}
                description=""
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Temporarily Blocked")}
                data={store.data.filter((el) => el.status.name === "blocked").length}
                description=""
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Permanently excluded")}
                data={store.data.filter((el) => el.status.name === "blocked").length}
                description=""
                Icon={PeopleAltIcon}
              />
            }
          </Box>
        </Box>
        <Box sx={{
          display: "grid",
          margin: (theme) => theme.spacing(10, 4),
          gridTemplateColumns: "100%",
        }}>
          <Box sx={{
            justifySelf: "end",
            margin: (theme) => theme.spacing(1, 0),
          }}>
            <Button
              variant="contained"
              disabled={playerId.disabled}
              href={`/playerRestrictions?id=${playerId.id}`}
            >
              {context.t("Show More")}
            </Button>
          </Box>
          <div>
            <div className="ag-theme-material">
              <Box sx={{
                height: "50vh",
              }}>
                <GridConfig
                  props={{
                    GridConfigData,
                    onGridReady,
                    onRowSelected,
                    rowData: store.data,
                  }}
                />
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

ResponsibleGaming.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ResponsibleGaming;
