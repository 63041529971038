import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MuiTelInput } from 'mui-tel-input';
import { countries, countryToFlag } from "../../../../helpers/countries";
import { CreateUser } from "../store/admin_actions/adminActions";
import {
  Box,
  Typography,
  Button,
  Autocomplete,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

const CreateAdmin = ({open, setOpenCreateAdmin}, context) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      dateOfBirth: new Date("1980"),
      address: "",
      city: "",
      country: "",
      role: "admin",
      status: "active",
      isVerified: true,
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
      city: Yup.string().required("City is required"),
      address: Yup.string().required("Address is required"),
      country: Yup.string().required("Country is required"),
      firstName: Yup.string().required("Name is required"),
      lastName: Yup.string().required("Last Name is required"),
    }),
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = formik;

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(CreateUser(values));
    setOpenCreateAdmin(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setOpenCreateAdmin(false);
  };
  return (
    <>
      <Dialog open={open} onClose={handleCancel} maxWidth="xs">
        <DialogTitle sx={(theme) => ({
          color: theme.palette.primary.light,
          background: theme.palette.primary.main,
        })}>
          {context.t("Create Admin")}
          <Typography variant="body2" noWrap>
            * {context.t("Required Fields")}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{
          display: "flex",
          flexDirection: "column",
          whiteSpace: "nowrap",
        }}>
          <form className="admin-form" onSubmit={handleSubmit}>
            <Box sx={(theme) => ({
              margin: theme.spacing(2, 0),
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              "& .MuiSvgIcon-root": {color: theme.palette.primary.main},
            })}>
              <TextField
                required
                name="firstName"
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName ? errors.firstName : ""}
                label={context.t("Name")}
                onBlur={handleBlur}
                value={values.firstName}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  margin: (theme) => theme.spacing(1),
                  minWidth: 300,
                }}
              />
              <TextField
                required
                name="lastName"
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName ? errors.firstName : ""}
                label={context.t("Last Name")}
                onBlur={handleBlur}
                value={values.lastName}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  margin: (theme) => theme.spacing(1),
                  minWidth: 300,
                }}
              />
              <TextField
                required
                name="email"
                type="email"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email ? errors.email : ""}
                label={context.t("Email")}
                onBlur={handleBlur}
                value={values.email}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  margin: (theme) => theme.spacing(1),
                  minWidth: 300,
                }}
              />
              <FormControl variant="outlined" sx={{
                margin: (theme) => theme.spacing(1),
                minWidth: 300,
              }}>
                <InputLabel id="Role">{context.t("Role")}</InputLabel>
                <Select
                  labelId="Role"
                  label={context.t("Role")}
                  value={values.role}
                  onChange={(_, newValue) => {
                    setFieldValue(
                      "role",
                      newValue ? newValue.props.value : "admin"
                    );
                  }}
                  labelWidth={60}
                >
                  <MenuItem value={"admin"}>Admin</MenuItem>
                  <MenuItem value={"hr"}>HR</MenuItem>
                  <MenuItem value={"players_management"}>
                    Players Management
                  </MenuItem>
                  <MenuItem value={"developer"}>Developer</MenuItem>
                  <MenuItem value={"tech"}>Tech</MenuItem>
                </Select>
              </FormControl>
              <DatePicker
                sx={{
                  margin: (theme) => theme.spacing(1),
                  minWidth: 300,
                }}
                required
                autoOk
                views={['year', 'month', 'day']}
                openTo="year"
                disableFuture={true}
                label={context.t("Date Of Birth")}
                format="MM/dd/yyyy"
                name="dateOfBirth"
                error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
                helperText={touched.dateOfBirth ? errors.dateOfBirth : ""}
                onBlur={handleBlur}
                value={values.dateOfBirth}
                onChange={(newValue) => {
                  setFieldValue("dateOfBirth", newValue ? newValue : "");
                }}
                renderInput={(params) => <TextField
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 300,
                    maxWidth: 300,
                  }}
                  error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
                  helperText={touched.dateOfBirth ? errors.dateOfBirth : ""}
                  {...params}
                />}
              />

              <MuiTelInput
                variant="outlined"
                name="phone"
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone ? errors.phone : ""}
                label={context.t("Phone")}
                onBlur={handleBlur}
                value={values.phone}
                onChange={(newValue) => {
                  setFieldValue("phone", newValue ? newValue : "");
                }}
                sx={{
                  margin: (theme) => theme.spacing(1),
                  minWidth: 300,
                }}
              />
              <TextField
                name="city"
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city ? errors.city : ""}
                label={context.t("City")}
                onBlur={handleBlur}
                value={values.city}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  margin: (theme) => theme.spacing(1),
                  minWidth: 300,
                }}
              />
              <Autocomplete
                options={countries}
                defaultValue={countries[0]}
                sx={{
                  margin: (theme) => theme.spacing(1),
                  minWidth: 300,
                }}
                name="country"
                onChange={(_, newValue) => {
                  setFieldValue("country", newValue ? newValue.label : "");
                }}
                sx={{
                  fontSize: 15,
                  "& > span": {
                    marginRight: 10,
                    fontSize: 18,
                  },
                }}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, state) => (
                  <li {...props}>
                    <Box component="span" mr={1}>{countryToFlag(option.code)}</Box>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      marginTop: (theme) => theme.spacing(1),
                      marginBottom: (theme) => theme.spacing(1),
                      minWidth: 300,
                    }}
                    {...params}
                    required
                    label={context.t("Country")}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <TextField
                required
                name="address"
                error={touched.address && Boolean(errors.address)}
                helperText={touched.address ? errors.address : ""}
                label={context.t("Address")}
                onBlur={handleBlur}
                value={values.address}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  margin: (theme) => theme.spacing(1),
                  minWidth: 300,
                }}
              />
            </Box>
          </form>
        </DialogContent>
        <DialogActions sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={!(isValid && dirty)}
          >
            Create
          </Button>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CreateAdmin.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CreateAdmin;
