import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import PaymentIcon from "@mui/icons-material/Payment";
import Widget from "../../../components/widget/Widget";
import GridConfig from "../../../components/grid/GridConfig";
import ReviewWithdraw from "./review_withdrawal/ReviewWithdrawal";

import { GridConfigData } from "./grid_data/GridData";
import { getTransactionsData } from "../store/get_all_transactions/TransactionsActions";
import { Box, Typography, Button } from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

const WithdrawalTransactions = (_, context) => {
  const store = useSelector((state) => ({
    withdrawals: state.TransactionsData.transactions,
    players: state.PlayersData.players,
  }));
  const [withdrawalInfo, setwithdrawalInfo] = useState({
    data: null,
    disabled: true,
  });
  const [reviewWithdrawal, setreviewWithdrawal] = useState(false);
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState({});

  useEffect(() => {
    dispatch(getTransactionsData("withdrawal", true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Grid
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const onRowSelected = (e) => {
    const focusedCell = gridApi.getFocusedCell();
    const selectedInfo = gridApi.getDisplayedRowAtIndex(focusedCell.rowIndex);
    selectedInfo.selected
      ? setwithdrawalInfo({ data: selectedInfo.data, disabled: false })
      : setwithdrawalInfo({ data: null, disabled: true });
  };
  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("Pending Withdrawals")}
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("Transactions in 1h")}
                data={
                  store.withdrawals.filter(
                    (el) =>
                      new Date(el.createdAt) >=
                      new Date(
                        (Math.round(new Date().getTime() / 1000) - 1 * 3600) *
                          1000
                      ).getTime()
                  ).length
                }
                description={`Amount of withdrawals > 100  - ${
                  store.withdrawals.filter((el) => el.amount > -100).length
                }`}
                Icon={PaymentIcon}
              />
            }
            {
              <Widget
                name={context.t("Transactions in 24hrs")}
                data={
                  store.withdrawals.filter(
                    (el) =>
                      new Date(el.createdAt) >=
                      new Date(
                        (Math.round(new Date().getTime() / 1000) - 24 * 3600) *
                          1000
                      ).getTime()
                  ).length
                }
                description={`Total amount of pending withdrawals  - ${store.withdrawals.length}`}
                Icon={PaymentIcon}
              />
            }
          </Box>
        </Box>
        <Box sx={{
          display: "grid",
          margin: (theme) => theme.spacing(10, 4),
          gridTemplateColumns: "100%",
        }}>
          <Box sx={{
            justifySelf: "end",
            margin: (theme) => theme.spacing(1, 0),
          }}>
            <Button
              variant="contained"
              target="_blank"
              sx={(theme) => ({
                padding: theme.spacing(1, 2),
                minWidth: 200,
                color: theme.palette.primary.light,
                background: theme.palette.secondary.dark,
              })}
              href="https://test-bo.paymentiq.io/#es-approve/search/"
              color="primary"
            >
              {context.t("Go to PaymentIQ")}
            </Button>
            <Button
              variant="contained"
              sx={(theme) => ({
                padding: theme.spacing(1, 2),
                margin: theme.spacing(0, 1),
                minWidth: 200,
                color: theme.palette.primary.light,
                background: theme.palette.secondary.dark,
              })}
              disabled={withdrawalInfo.disabled}
              onClick={(e) => setreviewWithdrawal(true)}
            >
              {context.t("Show More")}
            </Button>
          </Box>
          <ReviewWithdraw
            open={reviewWithdrawal}
            setOpenEditAdmin={setreviewWithdrawal}
            withdrawal={withdrawalInfo.data}
          />
          <div>
            <div className="ag-theme-material">
              <Box sx={{
                height: "50vh",
              }}>
                <GridConfig
                  props={{
                    GridConfigData,
                    onGridReady,
                    onRowSelected,
                    rowData: store.withdrawals,
                  }}
                />
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

WithdrawalTransactions.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WithdrawalTransactions;
