import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Widget from "../../../../components/widget/Widget";
import GridConfig from "../../../../components/grid/GridConfig";
import PlayerNavigation from "../../../../components/player_nav/PlayerNavigation";
import { GridConfigData } from "./grid_data/GridData";
import { getPlayerInfo } from "../../../player_management/store/player_actions/playerActions";
import { getParams } from "../../../../helpers/getParams";
import { getInGameTransactions } from "./store/inGameTransactionsActions";
import { Box, Typography, TextField } from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

const PlayerInGameTransactions = ({_, history}, context) => {
  const store = useSelector((state) => ({
    data: state.PlayersData.players,
    player: state.PlayerData.player,
    inGameTransactions: state.InGameTransactions.inGameTransactions,
  }));
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: store.player?.email || "",
      currency: store.player.accounts
        ? store.player.accounts[0].currency.code
        : "",
      balance: store.player.accounts
        ? store.player.accounts.filter((b) => b.type.name === "current")[0]
          .balance
        : "",
      blocked: store.player.accounts
        ? store.player.accounts.filter((b) => b.type.name === "blocked")[0]
          .balance
        : "",
      bonusBalance: store.player.accounts
        ? store.player.accounts.filter((b) => b.type.name === "bonus")[0]
          .balance
        : "",

      kycStatus: store.player?.kycStatus || "false",
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
      city: Yup.string().required("City is required"),
      address: Yup.string().required("Address is required"),
      country: Yup.string().required("Country is required"),
      firstName: Yup.string().required("Name is required"),
      lastName: Yup.string().required("Last Name is required"),
    }),
  });

  const {values, handleBlur} = formik;
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const [playerNav, setplayerNav] = useState({
    id: "",
    page: "",
  });
  useEffect(() => {
    let id = "";
    try {
      id = getParams(history.location.search).id[0];
      setplayerNav({...playerNav, id, page: history.location.pathname});
    } catch {
      id = "not_defined";
    }
    if (id === "not_defined") {
      history.push("/Not_Found");
    }
    dispatch(getPlayerInfo(id));
    dispatch(getInGameTransactions(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.player.id]);

  // Grid
  const onGridReady = (params) => {
  };
  const onRowSelected = (e) => {
  };

  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box
          sx={{
            margin: (theme) => theme.spacing(0, 4),
            display: "flex",
            flexDirection: "column",
          }}>
          <Typography variant="h5" noWrap paragraph>
            Player In-Game Transactions
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}>
            {
              <Widget
                name={context.t("24hr Money Won")}
                data={39504}
                description={`${context.t("Average winning")}: 38.4`} // HOW TO DO THIS??
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name={context.t("24hr Money Lost")}
                data={41504}
                description={`${context.t("Average lost")}: 83.4`}
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name={context.t("24hr Net Profit")}
                data={2000}
                description={`${context.t("NGR per Player")}: 43`}
                Icon={AccountBalanceIcon}
              />
            }
            {
              <Widget
                name={context.t("Players over 24hr")}
                data={6}
                description={context.t("Total Players")}
                Icon={PeopleAltIcon}
              />
            }
          </Box>
          <Box sx={{
            display: "grid",
            gridTemplateColumns: "20% 80%",
            gridGap: "10px",
            [(theme) => theme.breakpoints.down("md")]: {
              gridTemplateColumns: "100%",
            },
          }}>
            <PlayerNavigation id={playerNav.id} currentPage={playerNav.page}/>
            <form className="admin-form" onSubmit={handleSubmit}>
              <Box sx={(theme) => ({
                margin: theme.spacing(5, 0),
                display: "flex",
                flexWrap: "wrap",
                [theme.breakpoints.down("md")]: {
                  margin: theme.spacing(1, 0),
                  justifyContent: "center",
                },
              })}>
                <TextField
                  name="email"
                  type="email"
                  label={context.t("Email")}
                  onBlur={handleBlur}
                  value={values.email}
                  variant="outlined"
                  disabled
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                />
                <TextField
                  name="currency"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  label={context.t("Currency")}
                  value={values.currency}
                  variant="outlined"
                  labelWidth={120}
                  disabled
                />
                <TextField
                  name="balance"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  label={context.t("Balance")}
                  value={values.balance}
                  variant="outlined"
                  labelWidth={120}
                  disabled
                />
                <TextField
                  name="blocked"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  label={context.t("Blocked Balance")}
                  value={values.blocked}
                  variant="outlined"
                  labelWidth={120}
                  disabled
                />
                <TextField
                  name="bonusBalance"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  label={context.t("Bonus Balance")}
                  value={values.bonusBalance}
                  variant="outlined"
                  labelWidth={120}
                  disabled
                />
              </Box>
            </form>
          </Box>
          <Box sx={{
            margin: (theme) => theme.spacing(0, 0, 10, 0),
          }}>
            <div className="ag-theme-material">
              <Box sx={{
                height: "50vh",
              }}>
                <Typography variant="h6" noWrap paragraph>
                  Player In-Game Transactions:
                </Typography>
                {store.inGameTransactions.length === 0 ? (
                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: (theme) => theme.spacing(12),
                  }}>
                    <Typography variant="h5" noWrap paragraph>
                      No Records
                    </Typography>
                  </Box>
                ) : (
                  <GridConfig
                    props={{
                      GridConfigData,
                      onGridReady,
                      onRowSelected,
                      rowData: store.inGameTransactions,
                    }}
                  />
                )}
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

PlayerInGameTransactions.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PlayerInGameTransactions;
