const columnDefs = (context) => [
  {
    headerName: context.t("Email"),
    checkboxSelection: true,
    valueGetter: function getField(params) {
      return `${params.data.player.email}`;
    },
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },

  {
    headerName: context.t("Bonus Status"),
    valueGetter: function getField(params) {
      return `${params.data.status}`;
    },
    cellClass: "cell-default-style",
    chartDataType: "series",
  },
  {
    headerName: context.t("Given on"),
    field: "createdAt",
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
    },
    hide: true,
  },
  {
    headerName: context.t("Bonus Type"),
    valueGetter: function checkField(params) {
      return params.data?.bonusType?.condition?.name || "Unkonwn"
    },
    cellClass: "cell-default-style",
    chartDataType: "series",
    sortable: true,
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
  },
  {
    headerName: context.t("Cash Balance"),
    valueGetter: function checkField(params) {
      return params.data.player.accounts === null
        ? 0
        : params.data.player.accounts.filter(
            (el) => el.type.name === "current"
          )[0].balance;
    },
    cellClass: "cell-default-style",
    chartDataType: "series",
    sortable: true,
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
  },
  {
    headerName: context.t("Bonus Balance"),
    valueGetter: function checkField(params) {
      return params.data.player.accounts === null
        ? 0
        : params.data.player.accounts.filter(
            (el) => el.type.name === "bonus"
          )[0].balance;
    },
    cellClass: "cell-default-style",
    chartDataType: "series",
    sortable: true,
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
  },
  {
    headerName: context.t("Blocked By Bonus"),
    valueGetter: function checkField(params) {
      return params.data.player.accounts === null
        ? 0
        : params.data.player.accounts.filter(
            (el) => el.type.name === "blocked"
          )[0].balance;
    },
    cellClass: "cell-default-style",
    chartDataType: "series",
    sortable: true,
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
  },
  {
    headerName: context.t("Combined Balance"),
    valueGetter: function checkField(params) {
      return params.data.player.accounts === null
        ? 0
        : params.data.player.accounts.filter(
            (el) => el.type.name === "combined"
          )[0].balance;
    },
    cellClass: "cell-default-style",
    chartDataType: "series",
    sortable: true,
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
  },
  {
    headerName: context.t("Wagered"),
    field: "amountWagered",
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
  },
  {
    headerName: "% " + context.t("Cleared"),
    field: "percentageCleared",
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
    },
  },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};
const floatingFilter = true;

const rowSelection = "single";

export const GridConfigData = {
  columnDefs,
  defaultColDef,
  floatingFilter,
  rowSelection,
};
