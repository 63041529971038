import { setLoading } from "../../../components/loading/store/showLoadingActions";
import {
  setNotificationMessage,
  setShowNotificationMessage,
  setNotificationSeverity,
} from "../../../components/notifications/store/notificationActions";
import getConfig from "../../../config/getConfig";
import * as constants from "./PageConstants";

export const getPageDescription = (data) => (dispatch) => {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  dispatch(setLoading(true));
  fetch(`${getConfig().API_URL_AUTH}/auth/getPageDescription`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      if (json.description) {
        dispatch({
          type:
            data.lanCode === "en_GB"
              ? constants.REQUEST_GET_PAGE_DESCRIPTION_EN
              : constants.REQUEST_GET_PAGE_DESCRIPTION_ALT,
          payload: json,
        });
      } else {
        dispatch({
          type: constants.REQUEST_GET_PAGE_DESCRIPTION_ALT,
          payload: "",
        });
      }
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      // dispatch(setNotificationSeverity("success"));
      dispatch(setNotificationMessage("Error fetching translation"));
      dispatch(setShowNotificationMessage(true));
    });
};

export const addPageDescription = (data) => (dispatch) => {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  dispatch(setLoading(true))
  fetch(`${getConfig().API_URL_AUTH}/auth/addPageBody`, requestOptions)
    .then((res) => res.json)
    .then((json) => {
      dispatch({
        type: constants.REQUEST_ADD_PAGE_DESCRIPTION,
        payload: json,
      });
      dispatch(setLoading(false));
      dispatch(setShowNotificationMessage(true));
      dispatch(setNotificationSeverity("success"));
      dispatch(setNotificationMessage("Successfully added description"));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};

export const updatePageDescription = (data) => (dispatch) => {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  dispatch(setLoading(true));
  fetch(`${getConfig().API_URL_AUTH}/auth/updatePageDescription`, requestOptions)
    .then((res) => res.json())
    .then((json) => {
      dispatch({
        type: constants.REQUEST_UPDATE_PAGE_DESCRIPTION,
        payload: json,
      });
      dispatch(setLoading(false));
      dispatch(setShowNotificationMessage(true));
      dispatch(setNotificationSeverity("success"));
      dispatch(setNotificationMessage("Successfully updated description"));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};
