import React from "react";
import { Bar, Line, Pie,} from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement
} from 'chart.js';
import { Box, Typography } from "@mui/material";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement
);

const components = {
  Bar,
  Line,
  Pie,
};

function BaseReports({ type, data, title, label }) {
  const capitalizeFirstLetter = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const Content = components[capitalizeFirstLetter(type)];

  return (
    <Box sx={(theme) => ({
      padding: theme.spacing(4, 2),
      background: theme.palette.background.paper,
    })}>
      <Box sx={{
        margin: (theme) => theme.spacing(2),
      }}>
        <Typography variant="h5" noWrap>
          {title}
        </Typography>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Box>
      <Content
        data={data}
        options={{
          plugins: {
            datalabels: {
              align: "top",
              display: "auto",
              anchor: "end",
            },
          },
        }}
      />
    </Box>
  );
}

export default BaseReports;
