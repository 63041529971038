import React from "react";
import { Card, CardActions, CardContent, CardActionArea, Button, Typography } from "@mui/material";
import BonusDetails from "../../../components/bonuses/bonusDetails";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { setStepTwoData } from "./store/createCampaignActions";

export default function ImgMediaCard({bonusType}, context) {
  const store = useSelector((state) => ({
    open: state.campaignData.stepTwoData.open,
    bonus: state.campaignData.stepTwoData.bonus,
    chosenBonus: state.campaignData.stepTwoData.chosenBonus,
  }));
  const dispatch = useDispatch();

  const handleModal = () => {
    dispatch(setStepTwoData("open", !store.open));
  };

  return (
    <>
      {bonusType.map((type, i) => {
        if (type.status === "Active") {
          return (
            <Card
              key={type.id}
              sx={{
                ...(
                  store.chosenBonus.id === type.id ? {
                      boxShadow: "0px 0px 1.5px 2.5px rgba(135,200,71,1)",
                      transform: "scale(1.03, 1.03)",
                      maxWidth: 445,
                      margin: "15px 10px",
                      "& .bodyText": {
                        color: "black",
                      },
                    }
                    : {
                      maxWidth: 445,
                      margin: "15px 10px",
                      "& .bodyText": {
                        color: "black",
                      },
                    }
                )
              }}
            >
              <CardActionArea
                onClick={() => {
                  dispatch(setStepTwoData("chosenBonus", bonusType[i]));
                }}
              >
                <CardContent>
                  <div
                    style={{
                      display: "block",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      {" "}
                      <Typography
                        component={"div"}
                        sx={{
                          fontWeight: "500",
                        }}
                        color="textSecondary"
                        gutterBottom
                      >
                        {context.t("Name")}:
                        <br/>
                        <div className="bodyText">{type.title}</div>
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        component={"div"}
                        sx={{
                          ...(
                            type.status === "Active" ? {
                                "& .status": {
                                  color: "green",
                                },
                              }
                              :
                              {
                                "& .status": {
                                  color: "red",
                                },
                              }
                          )
                        }}
                        color="textSecondary"
                      >
                        {context.t("Status")}:
                        <br/>
                        <div className="status">{context.t(type.status)} </div>
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        component={"div"}
                        color="textSecondary"
                      >
                        {context.t("Duration")}:
                        <br/>
                        <div className="bodyText">
                          {type.startDate} - {type.endDate}
                        </div>
                        <br/>
                      </Typography>
                    </div>
                  </div>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => {
                    dispatch(setStepTwoData("bonus", bonusType[i]));
                    handleModal();
                  }}
                >
                  {context.t("More Info")}
                </Button>
              </CardActions>
            </Card>
          );
        }
        return <></>;
      })}
      <BonusDetails open={store.open} close={handleModal} data={store.bonus}/>
    </>
  );
}

ImgMediaCard.contextTypes = {
  t: PropTypes.func.isRequired,
};
