import * as constants from "./inGameTransactionsConstants";
import {
  setNotificationMessage,
  setShowNotificationMessage,
} from "../../../../../components/notifications/store/notificationActions";
import getConfig from "../../../../../config/getConfig";
// import { setLoading } from "../../../../../components/loading/store/showLoadingActions";


export const getInGameTransactions = (uuid) => (dispatch, getState) => {
  const { token } = getState().loginUser;
  const requestOptions = {
    method: "GET",
    headers: { "x-access-token": `${token}` },
  };
  // dispatch(setLoading(true));

  fetch(`${getConfig().API_URL_TRANSACTIONS}/transactions/get_by_user/${uuid}`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.errors) {
        json.errors.forEach((er) => {
          throw new Error(er.message);
        });
      }
      dispatch({
        type: constants.REQUEST_INGAME_TRANSACTIONS_SUCCESS,
        payload: json,
      });
      // dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      // dispatch(setLoading(false));
    });
};
