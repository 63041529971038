export const adminsDataMock = [
    {
      brand: "Evoplay",
      turnover: 86542,
      margin: 35,
      ggr: 30289,
      bonusgiven: 4863,
      bonusspent: 2543,
      ngr: 7586
    },
    {
      brand: "Pragmatic",
      turnover: 96421,
      margin: 42,
      ggr: 40496,
      bonusgiven: 14000,
      bonusspent: 5689,
      ngr: 12456
    }, {
      brand: "Booongo",
      turnover: 60214,
      margin: 38,
      ggr: 22881,
      bonusgiven: 13252,
      bonusspent: 7543,
      ngr: 11236
    }
  ];
