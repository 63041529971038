import React from "react";
import { Box, Button } from "@mui/material";

const drawerWidth = '270px';
const UnderConstruction = () => {
  return (
    <>
      <Box sx={{
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: '100vh'
      }}>
        <Button
          variant="contained"
          href="https://accounts.livechat.com/"
          target="_blank"
          color="primary"
        >Go to admin panel LiveChat</Button>
      </Box>
    </>
  );
};

export default UnderConstruction;
