const columnDefs = (context) => [
  {
    headerName: context.t("Name"),
    valueGetter: function combineField(params) {
      return params.data.firstName === null ? `_` : `${params.data.firstName} ${params.data.lastName}`;
    },
    cellClass: "cell-default-style",
    checkboxSelection: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Email"),
    field: "email",
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Status"),
    valueGetter: function combineField(params) {
      return params.data.status === null ? `none` :  params.data.status.name;
    },
    cellClass: "cell-default-style",
    chartDataType: "series",
  },
  {
    headerName: context.t("Self-Exclusion Start"),
    field: "selfExclusionStart",
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Self-Exclusion End"),
    field: "selfExclusionEnd",
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
    },
  },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};
const floatingFilter = true;

const rowSelection = "single";

export const GridConfigData = {
  columnDefs,
  defaultColDef,
  floatingFilter,
  rowSelection,
};
