import * as constants from "./TransactionsConstants";

const initialState = {
  total: [],
  transactions: [],
};

export const TransactionsData = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload.transactions,
        total: action.payload.amount,
      };
    default:
      return state;
  }
};
