import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { getGamesData } from "../../../../../store/games/GamesActions";
import { Box, Typography, TextField } from "@mui/material";

const Review = ({bonusData, gamesFilter}, context) => {
  const {bonusType} = bonusData;
  const {gamesFilters} = gamesFilter;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGamesData(gamesFilters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      padding: (theme) => theme.spacing(2),
    }}>
      <Typography variant="h5" noWrap paragraph>
        {context.t("Bonus Detail")}:
      </Typography>
      <Box sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: "repeat(2, 50%)",
        gridTemplateAreas: "'column column'",
        justifyItems: "center",
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "100%",
          gridTemplateAreas: "'column'",
        },
      })}>
        <TextField
          label={context.t("Bonus Type Name")}
          value={bonusType.name}
          variant="outlined"
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
          }}
          disabled
        />
        <TextField
          label={context.t("Promo Code")}
          value={bonusType.code}
          variant="outlined"
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
          }}
          disabled
        />
        <TextField
          label={context.t("Currency")}
          value={bonusType.currency}
          variant="outlined"
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
          }}
          disabled
        />
        <TextField
          label={context.t("Daily Request Limit")}
          value={bonusType.approveLimit}
          variant="outlined"
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
          }}
          disabled
        />
        <TextField
          label={context.t("Bonus Condition")}
          value={bonusType.condition}
          variant="outlined"
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
          }}
          disabled
        />
        <TextField
          label={context.t("Duration Per Player (in days)")}
          value={bonusType.duration}
          variant="outlined"
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
          }}
          disabled
        />
        <TextField
          label={context.t("Percentage")}
          value={bonusType.percentage}
          variant="outlined"
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
          }}
          disabled
        />
        <TextField
          label={context.t("Wagering Requirement")}
          value={bonusType.wageringRequirement}
          variant="outlined"
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
          }}
          disabled
        />
        <TextField
          label={context.t("Free Spins")}
          value={bonusType.freeSpins}
          variant="outlined"
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
          }}
          disabled
        />
        <TextField
          label={context.t("Bonus Max Amount")}
          value={bonusType.maxAmount}
          variant="outlined"
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
          }}
          disabled
        />
        <TextField
          disabled
          label={context.t("Description")}
          value={bonusType.description}
          variant="outlined"
          margin="dense"
          id="description"
          multiline
          rows={4}
          sx={{
            gridColumnStart: "column-start",
            gridColumnEnd: "column-end",
            width: "80%",
          }}
        />
      </Box>
      <Box sx={{
        width: "100%",
        display: "flex",
      }}>
        {bonusType.previewImage && (
          <Box component="img" src={bonusType.previewImage} sx={{width: "100%",}}/>
        )}
      </Box>
      <Typography variant="h5" noWrap paragraph>
        {context.t("Games")}:
      </Typography>
      <Box sx={{
        maxHeight: "35vh",
        overflow: "auto",
        margin: (theme) => theme.spacing(2, 0),
      }}>
        {bonusType.games.map((game) => {
          const image =
            game.images.filter((image) => image.type.name === "normal")
              .length === 0
              ? "https://media.istockphoto.com/photos/casino-element-isolation-on-the-colorful-slot-machine-roulette-while-picture-id1142572029?k=6&m=1142572029&s=612x612&w=0&h=t4wN--gstSSWR6qEttr0vgQl09XvcoRG48uxF21vayo="
              : game.images.filter((image) => image.type.name === "normal")[0]
                .link;
          return <Box
            component="img"
            src={image}
            sx={{
              width: "100px",
              height: "100px",
              margin: (theme) => theme.spacing(1),
            }}
            alt={game.name}/>;
        })}
      </Box>
    </Box>
  );
};

Review.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default Review;
