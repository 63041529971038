import * as constants from "./translationConstants";

const initialState = {
  allTranslations: {},
};

export const Translations = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_GET_LOCALE_TEXT:
      return { ...state, allTranslations: action.payload };
    case constants.REQUEST_SET_LOCALE_TEXT: {
      const keyName = Object.keys(action.payload)[0];
      let testVar = {...state};
      testVar.allTranslations[keyName] = action.payload[keyName];
      // console.log(testVar)
      return {...testVar};
    }
    default:
      return state;
  }
};
