import * as constants from "./currenciesConstatns";
import {
  setNotificationMessage,
  setShowNotificationMessage,
  setNotificationSeverity
} from "../../../../components/notifications/store/notificationActions";
import getConfig from "../../../../config/getConfig";
import { setLoading } from "../../../../components/loading/store/showLoadingActions";

export const getPlatformConfig = () => (dispatch) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  dispatch(setLoading(true));
  fetch(`${getConfig().API_URL_MASTER}/master/config`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch({
        type: constants.REQUEST_BASE_CURRENCY,
        payload: json.baseCurrency.code,
      });
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};

export const updatePlatformConfig = (body) => (dispatch) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  dispatch(setLoading(true));
  fetch(`${getConfig().API_URL_MASTER}/master/config`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch(getPlatformConfig());
      dispatch(setNotificationMessage("Base Currency Was Successfully Updated"));
      dispatch(setNotificationSeverity("success"));
      dispatch(setShowNotificationMessage(true));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};

export const getPlatformCurrencies = () => (dispatch) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  dispatch(setLoading(true));
  fetch(`${getConfig().API_URL_MASTER}/master/currency`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch({
        type: constants.REQUEST_CURRENCIES_SUCCESS,
        payload: json,
      });
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};

export const updateCurrencyAvailability = (body) => (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    dispatch(setLoading(true));
    fetch(`${getConfig().API_URL_MASTER}/master/currency/change_availability`, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.error) {
          throw new Error(json.error);
        }
        dispatch(getPlatformCurrencies());
        dispatch(setNotificationMessage("Currency Status Was Successfully Updated"));
        dispatch(setNotificationSeverity("success"));
        dispatch(setShowNotificationMessage(true));
      })
      .catch((error) => {
        dispatch(setNotificationMessage(error.message));
        dispatch(setShowNotificationMessage(true));
        dispatch(setLoading(false));
      });
  };


  export const updateCurrencyInformation = (body) => (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    dispatch(setLoading(true));
    fetch(`${getConfig().API_URL_MASTER}/master/currency/change_information`, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.error) {
          throw new Error(json.error);
        }
        dispatch(getPlatformCurrencies());
        dispatch(setNotificationMessage("Currency Was Successfully Updated"));
        dispatch(setNotificationSeverity("success"));
        dispatch(setShowNotificationMessage(true));
      })
      .catch((error) => {
        dispatch(setNotificationMessage(error.message));
        dispatch(setShowNotificationMessage(true));
        dispatch(setLoading(false));
      });
  };

export const getExchangeRates = () => (dispatch) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  dispatch(setLoading(true));
  fetch(`${getConfig().API_URL_MASTER}/master/rates`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch({
        type: constants.REQUEST_EXCHANGE_RATES_SUCCESS,
        payload: json,
      });
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};

export const addExchangeRate = (body) => (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    dispatch(setLoading(true));
    fetch(`${getConfig().API_URL_MASTER}/master/rates/create`, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.error) {
          throw new Error(json.error);
        }
        dispatch(getPlatformCurrencies());
        dispatch(setNotificationMessage("Exchange Rate Was Successfully Created"));
        dispatch(setNotificationSeverity("success"));
        dispatch(setShowNotificationMessage(true));
      })
      .catch((error) => {
        dispatch(setNotificationMessage(error.message));
        dispatch(setShowNotificationMessage(true));
        dispatch(setLoading(false));
      });
  };
