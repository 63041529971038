import React, { useState } from "react";

import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";

import GlossaryContent from "./GlossaryContent";

const Glossary = () => {
  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    setVisible(!visible);
  };

  return (
    <>
      <IconButton
        aria-label="chuoose color scheme"
        aria-controls="Color Change"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        <HelpIcon />
      </IconButton>
      {visible ? (
        // <div>Ok</div>
        <GlossaryContent handleClick={handleClick} />
      ) : (
        ""
      )}
    </>
  );
};

export default Glossary;
