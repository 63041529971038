import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ReportIcon from "@mui/icons-material/Report";
import Widget from "../../../components/widget/Widget";
import GridConfig from "../../../components/grid/GridConfig";
import { GridConfigData } from "./grid_data/GridData";
import { getAdminsData } from "../store/get_all_admins/AdminsActions";
import EditAdmin from "./edit_admin/EditAdmin";
import CreateAdmin from "./create_admin/CreateAdmin";
import ResetPassword from "./reset_password/ResetPassword";
import { checkToken } from "../../signin/login_page/store/LoginActions";
import { Box, Typography, Button, Link } from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

const AdminManagement = (_, context) => {
  const state = useSelector((state) => ({
    data: state.AdminsData.data,
    amount: state.AdminsData.amount,
    user: state.loginUser.user,
  }));
  const dispatch = useDispatch();
  const [openEditAdmin, setOpenEditAdmin] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [openCreateAdmin, setOpenCreateAdmin] = useState(false);
  const [admin, setAdmin] = useState({
    data: null,
    disabled: true,
  });
  // Grid
  const [gridApi, setGridApi] = useState({});
  const [paginationPageSize, setPaginationPageSize] = useState(0);
  const [cacheBlockSize, setCacheBlockSize] = useState(0);
  const [cahcheFilter, setcahcheFilter] = useState(undefined)
  const customOnGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();

    setPaginationPageSize(params.api.paginationGetPageSize());
    setCacheBlockSize(params.api.paginationGetPageSize());

    let datasource = createServerSideDatasource(cahcheFilter);
    params.api.setServerSideDatasource(datasource);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const createServerSideDatasource = (filter) => {
    return {
      getRows: (params) => {
        dispatch(checkToken(getAdminsData(params, filter)));
      },
    };
  }

  const onRowSelected = (e) => {
    const focusedCell = gridApi.getFocusedCell();
    const selectedInfo = gridApi.getDisplayedRowAtIndex(focusedCell.rowIndex);
    selectedInfo.selected
      ? selectedInfo.data.uuid === state.user.uuid
        ? setAdmin({data: null, disabled: true})
        : setAdmin({data: selectedInfo.data, disabled: false})
      : setAdmin({data: null, disabled: true});
  };
  const onFilterChanged = (params) => {
    let filterModel = gridApi.getFilterModel();
    setcahcheFilter(filterModel)
    let datasource = createServerSideDatasource(filterModel);
    params.api.setServerSideDatasource(datasource);
  };

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };
  return (
    <>
      <Box sx={(theme) => ({
        marginTop,
        marginLeft: drawerWidth,
        [theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      })}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("Admin Management")}
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("Total Admins")}
                data={state.amount}
                description=""
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Active Admins")}
                data={
                  state.data.length !== 0 &&
                  state.data.filter((el) =>
                    (el.status ? el.status.name === "active" : "")).length
                }
                description={context.t("Total Active Admins")}
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Inactive Admins")}
                data={
                  state.data.length !== 0 &&
                  state.data.filter((el) =>
                    (el.status ? el.status.name === "inactive" : "")).length
                }
                description={context.t("Total Inactive Admins")}
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Blocked Admins")}
                data={
                  state.data.length !== 0 &&
                  state.data.filter((el) =>
                    (el.status ? el.status.name === "blocked" : "")).length
                }
                description={context.t("Permanently Removed Access")}
                Icon={ReportIcon}
              />
            }
          </Box>
        </Box>
        <Box sx={{
          display: "grid",
          margin: (theme) => theme.spacing(4, 4),
          gridTemplateColumns: "100%",
        }}>
          <Box sx={(theme) => ({
            display: "flex",
            justifyContent: "space-between",
            margin: theme.spacing(1, 0),
            [theme.breakpoints.down("sm")]: {
              flexWrap: 'wrap'
            },
          })}>
            <Box component="span" sx={{
              justifySelf: "flex-start",
            }}>
              <Typography variant="caption">
                * {context.t("If you want to edit your account go to")}{" "}
                <Link href="/myProfile" variant="body2" color="primary">
                  {context.t("My Profile")}
                </Link>
              </Typography>
            </Box>
            <Box sx={(theme) => ({
              textAlign: 'right',
              [theme.breakpoints.down("sm")]: {
                textAlign: 'center',
              },
            })}>
              <Button
                variant="contained"
                sx={(theme) => ({
                  padding: theme.spacing(1, 2),
                  margin: theme.spacing(1, 1),
                  minWidth: 200,
                  color: theme.palette.primary.light,
                  background: theme.palette.secondary.dark,
                  "&:hover": {
                    background: theme.palette.primary.dark,
                  },
                })}
                disabled={admin.disabled}
                onClick={(e) => setOpenEditAdmin(true)}
              >
                {context.t("Edit Profile")}
              </Button>
              <Button
                variant="contained"
                sx={(theme) => ({
                  padding: theme.spacing(1, 2),
                  margin: theme.spacing(1, 1),
                  minWidth: 200,
                  color: theme.palette.primary.light,
                  background: theme.palette.secondary.dark,
                  "&:hover": {
                    background: theme.palette.secondary.dark,
                  },
                })}
                onClick={(e) => setOpenCreateAdmin(true)}
              >
                {context.t("Create Profile")}
              </Button>
              <Button
                variant="contained"
                sx={(theme) => ({
                  padding: theme.spacing(1, 2),
                  margin: theme.spacing(1, 1),
                  minWidth: 200,
                  color: theme.palette.primary.light,
                  background: theme.palette.secondary.dark,
                  "&:hover": {
                    background: theme.palette.primary.dark,
                  },
                })}
                disabled={admin.disabled}
                onClick={(e) => setOpenResetPassword(true)}
              >
                {context.t("Reset Password")}
              </Button>
            </Box>
          </Box>
          {admin.data ? (
            <EditAdmin
              open={openEditAdmin}
              setOpenEditAdmin={setOpenEditAdmin}
              data={admin.data}
              gridApi={gridApi}
            />
          ) : (
            <></>
          )}
          {admin.data ? (
            <ResetPassword
              open={openResetPassword}
              setOpen={setOpenResetPassword}
              data={admin.data}
            />
          ) : (
            <></>
          )}
          <CreateAdmin
            open={openCreateAdmin}
            setOpenCreateAdmin={setOpenCreateAdmin}
          />

          <div>
            <div className="ag-theme-material">
              <Box sx={{
                height: "47vh",
              }}>
                <GridConfig
                  props={{
                    GridConfigData: {
                      ...GridConfigData,
                      paginationPageSize,
                      cacheBlockSize,
                      onGridSizeChanged: customOnGridSizeChanged,
                      onFilterChanged,
                      onFirstDataRendered

                    },
                    onGridReady,
                    onRowSelected,
                  }}
                />
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

AdminManagement.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AdminManagement;
