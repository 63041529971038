export const GameConditionsMock = [
  {
    id: "c7f1a085-1afd-4de5-923f",
    name: "First Time Deposit",
    condition: "ftd",
  },
  {
    id: "c7f1a085-1afd-4de5-9231",
    name: "Reload Bonus",
    condition: "reload",
  },
];
