import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ReportIcon from "@mui/icons-material/Report";
import Widget from "../../../components/widget/Widget";
import PlayerNavigation from "../../../components/player_nav/PlayerNavigation";
import { getPlayerInfo } from "../../player_management/store/player_actions/playerActions";
import { getPlayersData } from "../../player_management/store/grid_player_load/gridPlayersActions";
import { getParams } from "../../../helpers/getParams";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  MenuItem,
  InputLabel,
  FormControl,
  Select
} from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

const Restrictions = ({_, history}, context) => {
  const store = useSelector((state) => ({
    data: state.PlayersData.players,
    player: state.PlayerData.player,
  }));
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: store.player?.firstName || "",
      lastName: store.player?.lastName || "",
      username: store.player?.username || "",
      email: store.player?.email || "",
      status: store.player.status?.name || "",
      excludeFor: "1month",
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
      city: Yup.string().required("City is required"),
      address: Yup.string().required("Address is required"),
      country: Yup.string().required("Country is required"),
      firstName: Yup.string().required("Name is required"),
      lastName: Yup.string().required("Last Name is required"),
    }),
  });

  const {
    values,

    setFieldValue,
  } = formik;

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const [playerNav, setplayerNav] = useState({
    id: "",
    page: "",
  });
  useEffect(() => {
    let id = "";
    try {
      id = getParams(history.location.search).id[0];
      setplayerNav({...playerNav, id, page: history.location.pathname});
    } catch {
      id = "not_defined";
    }
    if (id === "not_defined") {
      history.push("/Not_Found");
    }
    dispatch(getPlayersData());
    dispatch(getPlayerInfo(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.player.id]);

  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            Player Restrictions
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("Total Players")}
                data={store.data.length}
                description=""
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Active Players")}
                data={store.data.filter((el) => el.status === "active").length}
                description={context.t("Total Active Players")}
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Inactive Players")}
                data={
                  store.data.filter((el) => el.status === "inactive").length
                }
                description={context.t("Total Inactive Players")}
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Blocked Players")}
                data={store.data.filter((el) => el.status === "blocked").length}
                description={context.t("Permanently Blocked Players")}
                Icon={ReportIcon}
              />
            }
          </Box>
          <Box sx={{
            display: "grid",
            gridTemplateColumns: "20% 80%",
            gridGap: "10px",
            [(theme) => theme.breakpoints.down("md")]: {
              gridTemplateColumns: "100%",
            },
          }}>
            <PlayerNavigation id={playerNav.id} currentPage={playerNav.page}/>
            <form className="admin-form" onSubmit={handleSubmit}>
              <Box sx={(theme) => ({
                margin: theme.spacing(5, 0),
                display: "flex",
                flexWrap: "wrap",
                [theme.breakpoints.down("md")]: {
                  margin: theme.spacing(1, 0),
                  justifyContent: "center",
                },
              })}>
                <TextField
                  disabled
                  id="name"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  label={context.t("Name")}
                  value={`${values.firstName} ${values.lastName}`}
                  variant="outlined"
                />
                <TextField
                  disabled
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  id="username"
                  label={context.t("Username")}
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  disabled
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  id="email"
                  label={context.t("Email")}
                  value={values.email}
                  variant="outlined"
                  labelWidth={120}
                />
                <TextField
                  disabled
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}
                  id="status"
                  label={context.t("Status")}
                  value={values.status}
                  variant="outlined"
                  labelWidth={120}
                />
                <FormControl
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1, 1, 1, 0),
                    minWidth: 350,
                  }}>
                  <InputLabel id="exclude-for">{context.t("Exclude For")}</InputLabel>
                  <Select
                    labelId="exclude-for"
                    label={context.t("Exclude For")}
                    value={values.excludeFor}
                    onChange={(_, newValue) => {
                      setFieldValue(
                        "status",
                        newValue ? newValue.props.value : "active"
                      );
                    }}
                    labelWidth={120}
                  >
                    <MenuItem value={"24h"}>24h</MenuItem>
                    <MenuItem value={"72h"}>72h</MenuItem>
                    <MenuItem value={"1month"}>1 Month</MenuItem>
                    <MenuItem value={"forever"}>Forever</MenuItem>
                  </Select>
                </FormControl>
                <Divider/>
              </Box>
              <Button
                variant="contained"
                sx={(theme) => ({
                  padding: theme.spacing(2),
                  marginRight: '120px',
                  minWidth: 220,
                  float: "right",
                  color: theme.palette.primary.light,
                  background: theme.palette.success.main,
                  "&:hover": {
                    background: theme.palette.success.dark,
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginRight: 20,
                  },
                })}
                type="submit"
              >
                Submit
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

Restrictions.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Restrictions;
