import * as constants from "./gridPlayersConstants";

const initialState = {
  players: [],
  kyc: {},
};

export const PlayersData = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_PLAYERS_SUCCESS:
      return { ...state, players: action.payload };
    case constants.REQUEST_KYC_SUCCESS:
      return { ...state, kyc: action.payload };
    default:
      return state;
  }
};
