import React from 'react';

export default (params) => {
    const image = params.value === null ? 'https://signspecialist.com/decals/mascot2/images/Panther%20Head1.jpg' : params.value;
    return (
      <>
        <img style={{ display: 'block', borderRadius: '50%' }} width="40px" height="40px" src={image}
          alt="Profile Picture"/>
      </>
    );
};
