import React, { useEffect, useState } from "react";
import { JsonEditor as Editor } from "jsoneditor-react";

const JSON_Editor = ({ value, key_, onChange }) => {
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <div>
      {/* {console.log(key_, value)} */}
      {value && key_ && (
        <Editor
          mode="form"
          value={text}
          // key={Math.floor(Math.random() * 100000)}
          key={key_}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default JSON_Editor;
