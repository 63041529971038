import * as constants from "./GameProvidersConstants";

const initialState = {
  gameProviders: [],
};

export const GameProviders = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_GAME_PROVIDERS_SUCCESS:
      return { ...state, gameProviders: action.payload};
    default:
      return state;
  }
};
