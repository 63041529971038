import React from "react";


import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu"
import IconButton from "@mui/material/IconButton";
import MoreIcon from "@mui/icons-material/More";


import ProfileTopNav from "./Profile/Profile";
import ChangeThemeButton from "./Theme/ChangeThemeButton";
import ChooseLanguage from "./ChooseLanguage/ChooseLanguage"


const MobileMenuTopNav = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handleProfileMenuOpen}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem>
          <ChooseLanguage />
        </MenuItem>
        <MenuItem>
          <ChangeThemeButton />
        </MenuItem>
        <MenuItem>
          <ProfileTopNav />
        </MenuItem>
      </Menu>
    </>
  );
};

export default MobileMenuTopNav;
