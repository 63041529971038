import * as constants from "./kycConstants";
import {
  setNotificationMessage,
  setShowNotificationMessage,
} from "../../../../components/notifications/store/notificationActions";
import getConfig from "../../../../config/getConfig";
import { setLoading } from "../../../../components/loading/store/showLoadingActions";

export const getPlayerKyc = (uuid) => (dispatch, getState) => {
  const { token } = getState().loginUser;
  const requestOptions = {
    method: "GET",
    headers: { "x-access-token": `${token}` },
  };
  dispatch(setLoading(true));
  fetch(`${getConfig().API_URL_KYC}/kyc/kyc_expiration_status?uuid=${uuid}&isLatest=${true}`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch({
        type: constants.REQUEST_PLAYER_KYC_SUCCESS,
        payload: json,
      });
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
      dispatch(setLoading(false));
    });
};

export const documentApproval =
  (body) => (dispatch, getState) => {
    const { token } = getState().loginUser;
    const requestOptions = {
        method: "PUT",
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };
    dispatch(setLoading(true));
    fetch(`${getConfig().API_URL_KYC}/kyc/approval`, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.error) {
          throw new Error(json.error);
        }
        dispatch(getPlayerKyc(json.kyc.player.uuid));
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(setNotificationMessage(error.message));
        dispatch(setShowNotificationMessage(true));
        dispatch(setLoading(false));
      });
  };
