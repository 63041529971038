import React from "react";
import { Box, Typography } from "@mui/material";

const Widget = ({ name, data, description, Icon }) => {
  return (
    <>
      <Box sx={(theme) => ({
        minWidth: "320px",
        maxWidth: "320px",
        minHeight: "110px",
        maxHeight: "110px",
        background: theme.palette.background.paper,
        color: theme.palette.secondary.dark,
        borderRadius: "7px",
        margin: theme.spacing(1)
      })}>
        <Box sx={{
          display: "grid",
          gridTemplateColumns: "80% 20%",
          padding: "10px",
        }}>
          <div>
            <Typography variant="h6" noWrap>
              {name}
            </Typography>
          </div>
          <Box sx={{
            background: (theme) => theme.palette.primary.main,
            borderRadius: "100%",
            padding: "17px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>{<Icon color="secondary" />}</Box>
        </Box>
        <Box sx={{
          marginTop: "-35px",
          padding: " 0 10px",
        }}>
          <Typography variant="h5" noWrap>
            {data}
          </Typography>
        </Box>
        <Box sx={{
          padding: " 5px 10px 0 10px",
        }}>
          <Typography variant="body2" noWrap>
            {description}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Widget;
