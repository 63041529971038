const columnDefs = (context) => [
  {
    headerName: context.t("Transaction ID"),
    field: "ref",
    cellClass: "cell-default-style",
    filter: "agNumberColumnFilter",
    sortable: true,
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
    minWidth: 200,
    maxWidth: 450,
  },
  {
    headerName: context.t("Transactions Type"),
    valueGetter: function checkField(params) {
      return params.data.type.name;
    },
    cellClass: "cell-default-style",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
    minWidth: 200,
  },
  {
    headerName: context.t("Transactions Status"),
    valueGetter: function checkField(params) {
      return params.data.status.name;
    },
    cellClass: "cell-default-style",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
    minWidth: 200,
  },
  {
    headerName: context.t("Date"),
    valueGetter: function checkField(params) {
      return new Date(params.data.createdAt).toDateString();
    },
    cellClass: "cell-default-style",
    filter: "agDateColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["inRange"],
      comparator(filterLocalDateAtMidnight, cellValue) {
        let dateAsString = cellValue;
        let dateParts = dateAsString.split("/");
        let cellDate = new Date(
          Number(dateParts[2].slice(0, 4)),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
    minWidth: 200,
  },
  {
    headerName: context.t("Payment Type"),
    field: "feeSource",
    cellClass: "cell-default-style",
    chartDataType: "series",
    minWidth: 200,
  },
  {
    headerName: context.t("Amount"),
    field: "amount",
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains"],
    },
    minWidth: 140,
  },
  {
    headerName: context.t("Currency"),
    valueGetter: function checkField(params) {
      return params.data.player ? params.data.player.currency.code : "Euro";
    },
    cellClass: "cell-default-style",
    chartDataType: "series",
    minWidth: 140,
  },

  {
    headerName: context.t("KYC ID"),
    field: "kycId",
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
    minWidth: 200,
    hide: true,
  },
  {
    headerName: context.t("KYC Add"),
    field: "kycAdd",
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
    minWidth: 200,
    hide: true,
  },
  {
    headerName: context.t("KYC Pay"),
    field: "kycPay",
    cellClass: "cell-default-style",
    chartDataType: "series",
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
    minWidth: 200,
    hide: true,
  },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};
const floatingFilter = true;

const rowSelection = "single";

export const GridConfigData = {
  columnDefs,
  defaultColDef,
  floatingFilter,
  rowSelection,
};
