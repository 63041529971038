import * as constants from "./showLoadingConstatns";

const initialState = {
  isLoading: false,
};

export const showLoading = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.SHOW_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

