import React from "react";
import PropTypes from "prop-types";
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

const ReviewBonus = ({open, setOpen, bonus}, context) => {
  const handleGoBack = (e) => {
    e.preventDefault();
    setOpen(false);
  };
  return (
    <>
      <Dialog open={open} maxWidth="sm">
        <DialogTitle sx={(theme) => ({
          color: theme.palette.primary.light,
          background: theme.palette.primary.main,
        })}>
          {context.t("Bonus Entry Details")}
        </DialogTitle>
        <DialogContent sx={{
          display: "flex",
          flexDirection: "column",
          whiteSpace: "nowrap",
        }}>
          <form className="admin-form">
            <Box sx={(theme) => ({
              margin: theme.spacing(2, 0),
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            })}>
              <div>
                <Typography variant="body1" noWrap paragraph>
                  {context.t("Player Information")}:
                </Typography>
                <TextField
                  id="firstName"
                  label={context.t("Name")}
                  value={bonus !== null ? bonus.player.firstName : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />
                <TextField
                  id="lastName"
                  label={context.t("Last Name")}
                  value={bonus !== null ? bonus.player.lastName : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />
                <TextField
                  id="email"
                  label={context.t("Email")}
                  value={bonus !== null ? bonus.player.email : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />

                <TextField
                  id="country"
                  label={context.t("Player Country")}
                  value={bonus !== null ? bonus.player.country : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />
                <TextField
                  id="address"
                  label={context.t("Player Address")}
                  value={bonus !== null ? bonus.player.address : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />
                <TextField
                  id="phone_number"
                  label={context.t("Player Phone Number")}
                  value={bonus !== null ? bonus.player.phoneNumber : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />
                <TextField
                  id="kycStatus"
                  label={context.t("KYC")}
                  value={bonus !== null ? bonus.player.kycStatus : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />
                <TextField
                  id="balance"
                  label={context.t("Player Balance")}
                  value={
                    bonus !== null
                      ? bonus.player.accounts.filter(
                        (el) => el.type.name === "current"
                      )[0].balance
                      : ""
                  }
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />
                <TextField
                  id="currency_player"
                  label={context.t("Player Currency")}
                  value={
                    bonus !== null
                      ? bonus.player.accounts.filter(
                        (el) => el.type.name === "current"
                      )[0].currency.code
                      : ""
                  }
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />
              </div>
              <div>
                <Typography variant="body1" noWrap paragraph>
                  {context.t("Bonus Information")}:
                </Typography>
                <TextField
                  id="bonusName"
                  label={context.t("Bonus Name")}
                  value={bonus !== null ? bonus.bonusType.name : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />
                <TextField
                  id="depositedAmount"
                  label={context.t("Deposited Amount")}
                  value={bonus !== null ? bonus.depositedAmount : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />
                <TextField
                  id="BonsuStatus"
                  label={context.t("Bonus Status")}
                  value={bonus !== null ? bonus.status : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />
                <TextField
                  id="percentageCleared"
                  label={context.t("Percantage Cleared")}
                  value={bonus !== null ? bonus.percentageCleared : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />
                <TextField
                  id="bonusPercentage"
                  label={context.t("Bonus Percentage")}
                  value={bonus !== null ? bonus.bonusType.percentage : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />
                <TextField
                  id="depositDescription"
                  label={context.t("Bonus Description")}
                  value={bonus !== null ? bonus.bonusType.description : ""}
                  variant="outlined"
                  sx={{
                    margin: (theme) => theme.spacing(1),
                    minWidth: 200,
                  }}
                  disabled
                />
              </div>
            </Box>
          </form>
        </DialogContent>
        <DialogActions sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button color="primary">Deactivate</Button>
          <Button onClick={handleGoBack} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ReviewBonus.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ReviewBonus;
