import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import {
  Typography,
  styled,
  Box,
  Stepper,
  Step,
  stepConnectorClasses,
  StepLabel,
  StepConnector,
  Button
} from "@mui/material";
import { getPlayersData } from "../../../player_management/store/grid_player_load/gridPlayersActions";
import { getBonusRecordsData } from "../../store/bonus_records/BonusRecordsActions";
import PLayersGrid from "./stepper_grid/PlayersGrid";
import BonusesGrid from "./stepper_grid/BonusesGrid";
import Review from "./bonus_review/Review"

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.secondary.main} 100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.main
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIcon = (props) => {
  const {active, completed} = props;

  const icons = {
    1: <SettingsIcon/>,
    2: <GroupAddIcon/>,
    3: <VideoLabelIcon/>,
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        ...(active && {
          backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,
          boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
        }),
        ...(completed && {
          background: theme.palette.primary.main,
        })
      })}
    >
      {icons[String(props.icon)]}
    </Box>
  );
};
ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const getSteps = (context) => {
  return [
    context.t("Select Palyer/s"),
    context.t("Select Bonus Type"),
    context.t("Review"),
  ];
};

const GetStepContent = (step, players, bonuses, data, store) => {
  switch (step) {
    case 0:
      return <PLayersGrid players={players} store={store}/>;
    case 1:
      return <BonusesGrid bonuses={bonuses} store={store}/>;
    case 2:
      return <Review data={data}/>;
    default:
      return "Unknown step";
  }
};
const StepperApplyBonus = ({cancel}, context) => {
  const store = useSelector((state) => ({
    players: state.PlayersData.players,
    bonusRecords: state.BonusRecords.bonusRecords,
  }));

  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps(context);
  const [playerIds, setPlayerIds] = useState({info: []});
  const [bonusRecord, setbonusRecord] = useState({bonus: null});

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    dispatch(getPlayersData());
    dispatch(getBonusRecordsData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [gridApi, setGridApi] = useState({});
  const [gridPlayerApi, setGridPlayerApi] = useState({});

  // Grid Players
  const onPlayersGridReady = (params) => {
    setGridPlayerApi(params.api);
    if (playerIds.info.length !== 0) {
      params.api.forEachNode((node) => {
        if (playerIds.info.find((el) => el.id === node.data.id)) {
          node.setSelected(true);
        }
      });
    }
  };
  const onPlayersRowSelected = (e) => {
    const selectedRows = gridPlayerApi.getSelectedRows();
    if (selectedRows.length === 1) {
      setPlayerIds({info: [selectedRows[0]]});
      return;
    }
    setPlayerIds({
      info: selectedRows
    });
  };
  // Grid Bonuses
  const onBonusesGridReady = (params) => {
    setGridApi(params.api);
    if (bonusRecord.bonus !== null) {
      params.api.forEachNode((node) => {
        if (bonusRecord.bonus.id === node.data.id) {
          node.setSelected(true);
        }
      });
    }
  };
  const onBonusesRowSelected = (e) => {
    const selectedRows = gridApi.getSelectedRows();
    selectedRows.length !== 0
      ? setbonusRecord({bonus: selectedRows[0]})
      : setbonusRecord({id: null});
  };
  return (
    <Box sx={{
      marginTop: 3,
      width: "100%",
    }}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector/>}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography variant="h5" sx={{
              display: "flex",
              justifyContent: "center",
              margin: (theme) => theme.spacing(5, 0),
            }}>
              All done here!
            </Typography>
            <Box sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}>
              <Button onClick={cancel} sx={{
                marginRight: (theme) => theme.spacing(1)
              }}>
                Cancel
              </Button>
              <Button onClick={handleReset} sx={{
                marginRight: (theme) => theme.spacing(1)
              }}>
                Apply New
              </Button>
            </Box>
          </div>
        ) : (
          <div>
            <Box sx={{
              width: "50vw",
            }}>
              <div className="ag-theme-material">
                <Box sx={{
                  height: "50vh",
                }}>
                  {GetStepContent(
                    activeStep,
                    {onPlayersGridReady, onPlayersRowSelected},
                    {onBonusesGridReady, onBonusesRowSelected},
                    {playerIds, bonusRecord},
                    store
                  )}
                </Box>
              </div>
            </Box>

            <Box sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}>
              <Button onClick={cancel} sx={{
                marginRight: (theme) => theme.spacing(1)
              }}>
                Cancel
              </Button>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{
                  marginRight: (theme) => theme.spacing(1)
                }}
              >
                Back
              </Button>
              <Button
                disabled={(activeStep === 0 && playerIds.info.length === 0) || (activeStep === 1 && bonusRecord.id === null)}
                variant="contained"
                color="primary"
                onClick={handleNext}
                sx={{
                  marginRight: (theme) => theme.spacing(1)
                }}
              >
                {activeStep === steps.length - 1 ? "Apply" : "Next"}
              </Button>
            </Box>
          </div>
        )}
      </div>
    </Box>
  );
};

StepperApplyBonus.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default StepperApplyBonus;
