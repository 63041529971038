const columnDefs = (context) => [
  {
    headerName: context.t("Bonus Name"),
    field: "name",
    checkboxSelection: true,
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Status"),
    valueGetter: function checkField(params) {
      return params.data.status === 'active' ? "Active" : "Inactive";
    },
    cellClass: "cell-default-style",
  },
  {
    headerName: "Bonus given",
    valueGetter: function checkField(params) {
      return params.data.bonusEntries.length;
    },
    cellClass: "cell-default-style",
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "lessThan", "greaterThan"],
    },
  },
  //   {
  //     headerName: context.t("Bonus Spent"),
  //     field: "bonusspent",
  //     cellClass: "cell-default-style",
  //     chartDataType: "series",
  //   },
  {
    headerName: context.t("Start date:"),
    valueGetter: function checkField(params) {
      return new Date(params.data.startDate).toDateString();
    },
    filter: "agDateColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["inRange"],
      comparator(filterLocalDateAtMidnight, cellValue) {
        let dateAsString = cellValue;
        let dateParts = dateAsString.split("/");
        let cellDate = new Date(
          Number(dateParts[2].slice(0, 4)),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
    cellClass: "cell-default-style",
  },
  {
    headerName: "End date",
    valueGetter: function checkField(params) {
      return new Date(params.data.endDate).toDateString();
    },
    filter: "agDateColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["inRange"],
      comparator(filterLocalDateAtMidnight, cellValue) {
        let dateAsString = cellValue;
        let dateParts = dateAsString.split("/");
        let cellDate = new Date(
          Number(dateParts[2].slice(0, 4)),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
    cellClass: "cell-default-style",
  },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};
const floatingFilter = true;

const rowSelection = "single";

export const GridConfigData = {
  columnDefs,
  defaultColDef,
  floatingFilter,
  rowSelection,
};
