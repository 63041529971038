import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Box } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import { MuiTelInput } from 'mui-tel-input';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { countries, countryToFlag } from "../../../helpers/countries";
import { updateCurrentUserInfo } from "../admin_management/store/admin_actions/adminActions";
import { checkToken } from "../../signin/login_page/store/LoginActions";

const MyProfile = ({ editProfile, seteditProfile }, context) => {
  const state = useSelector((state) => ({
    user: state.loginUser.user,
  }));
  const dispatch = useDispatch();
  // form
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: state.user?.firstName || "",
      lastName: state.user?.lastName || "",
      email: state.user?.email || "",
      phone: state.user?.phone || null,
      dateOfBirth: state.user?.dateOfBirth || "",
      address: state.user?.address || "",
      city: state.user?.city || "",
      country: state.user
        // ? countries.filter((country) => country.label === state.user.country)[0].label
        ?
          countries.filter((country) => country.label === state.user.country).length > 0
            ? countries.filter((country) => country.label === state.user.country)[0].label
            : ""
        : "",
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Enter a valid email")
        .required("Email is required"),
      city: Yup.string().required("City is required"),
      address: Yup.string().required("Address is required"),
      country: Yup.string().required("Country is required"),
      firstName: Yup.string().required("Name is required"),
      lastName: Yup.string().required("Last Name is required"),
    }),
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleReset,
    setFieldValue,
    isValid,
    dirty,
  } = formik;

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(checkToken(updateCurrentUserInfo(values)));
    seteditProfile(true);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset(e);
    seteditProfile(true);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form className="admin-form" onSubmit={handleSubmit}>
      <Box sx={(theme) => ({
        margin: theme.spacing(7, 0),
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
          justifyContent: "center",
        },
        "& .MuiSvgIcon-root": { color: theme.palette.primary.main },
      })}>
        <TextField
          required={!editProfile}
          name="firstName"
          error={touched.firstName && Boolean(errors.firstName)}
          helperText={touched.firstName ? errors.firstName : ""}
          label={context.t("Name")}
          onBlur={handleBlur}
          value={values.firstName}
          onChange={handleChange}
          variant="outlined"
          disabled={editProfile}
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
            maxWidth: 350,
          }}
        />
        <TextField
          required={!editProfile}
          name="lastName"
          error={touched.firstName && Boolean(errors.firstName)}
          helperText={touched.firstName ? errors.firstName : ""}
          label={context.t("Last Name")}
          onBlur={handleBlur}
          value={values.lastName}
          onChange={handleChange}
          variant="outlined"
          disabled={editProfile}
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
            maxWidth: 350,
          }}
        />
        <TextField
          required={!editProfile}
          name="email"
          type="email"
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email ? errors.email : ""}
          label={context.t("Email")}
          onBlur={handleBlur}
          value={values.email}
          onChange={handleChange}
          variant="outlined"
          disabled={editProfile}
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
            maxWidth: 350,
          }}
        />
        <DatePicker
          required
          autoOk
          views={['year', 'month', 'day']}
          openTo="year"
          label={context.t("Date Of Birth")}
          format="MM/dd/yyyy"
          name="dateOfBirth"
          error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
          helperText={touched.dateOfBirth ? errors.dateOfBirth : ""}
          onBlur={handleBlur}
          disabled={editProfile}
          value={values.dateOfBirth}
          onChange={(newValue) => {
              setFieldValue("dateOfBirth", newValue ? newValue : "");
            }}
          renderInput={(params) => <TextField
            color="secondary"
            sx={{
              margin: (theme) => theme.spacing(1, 1, 1, 0),
              minWidth: 350,
              maxWidth: 350,
            }}
            error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
            helperText={touched.dateOfBirth ? errors.dateOfBirth : ""}
            {...params}
            />}
        />
        <MuiTelInput
          variant="outlined"
          name="phone"
          error={touched.phone && Boolean(errors.phone)}
          helperText={touched.phone ? errors.phone : ""}
          label={context.t("Phone")}
          onBlur={handleBlur}
          value={values.phone}
          onChange={(newValue) => {
            setFieldValue("phone", newValue ? newValue : "");
          }}
          disabled={editProfile}
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
            maxWidth: 350,
          }}
        />
        <TextField
          required={!editProfile}
          name="city"
          error={touched.city && Boolean(errors.city)}
          helperText={touched.city ? errors.city : ""}
          label={context.t("City")}
          onBlur={handleBlur}
          value={values.city}
          onChange={handleChange}
          variant="outlined"
          disabled={editProfile}
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
            maxWidth: 350,
          }}
        />
        <Autocomplete
          options={countries}
          defaultValue={
            state.user
              ? countries.filter(
                  (country) => country.label === state.user.country
                )[0]
              : countries[0]
          }
          disabled={editProfile}
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
            maxWidth: 350,
          }}
          name="country"
          onChange={(_, newValue) => {
            setFieldValue("country", newValue ? newValue.label : "");
          }}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, state) => (
            <li {...props}>
              <Box component="span" mr={1}>{countryToFlag(option.code)}</Box>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              required={!editProfile}
              label={context.t("Country")}
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
        <TextField
          required={!editProfile}
          name="address"
          error={touched.address && Boolean(errors.address)}
          helperText={touched.address ? errors.address : ""}
          label={context.t("Address")}
          onBlur={handleBlur}
          value={values.address}
          onChange={handleChange}
          variant="outlined"
          disabled={editProfile}
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
            maxWidth: 350,
          }}
        />
        <TextField
          id="status"
          label={context.t("Status")}
          value={state.user && state.user.status&& state.user.status.name}
          variant="outlined"
          disabled={true}
          sx={{
            margin: (theme) => theme.spacing(1, 1, 1, 0),
            minWidth: 350,
            maxWidth: 350,
          }}
        />
      </Box>

      <Box sx={{
        margin: (theme) => theme.spacing(0, 10),
        float: "right",
      }}>
        {!editProfile ? (
          <>
            <Button
              variant="contained"
              sx={(theme) => ({
                margin: theme.spacing(1, 1),
                padding: theme.spacing(1),
                minWidth: 150,
                color: theme.palette.primary.light,
                background: theme.palette.success.main,
                "&:hover": {
                  background: theme.palette.success.dark,
                },
                [theme.breakpoints.down("sm")]: {
                  marginRight: 20,
                },
              })}
              type="submit"
              disabled={!(isValid && dirty)}
            >
              {context.t("Submit")}
            </Button>
            <Button
              variant="contained"
              sx={(theme) => ({
                margin: theme.spacing(1, 1),
                padding: theme.spacing(1),
                minWidth: 150,
                color: theme.palette.primary.light,
                background: theme.palette.error.main,
                "&:hover": {
                  background: theme.palette.error.dark,
                },
                [theme.breakpoints.down("sm")]: {
                  marginRight: 20,
                },
              })}
              onClick={handleCancel}
            >
              {context.t("Cancel")}
            </Button>
          </>
        ) : (
          ""
        )}
      </Box>
    </form>
  );
};

MyProfile.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MyProfile;
