import * as constants from "./BonusRecordsConstants";
import {
  setNotificationMessage,
  setShowNotificationMessage,
} from "../../../../components/notifications/store/notificationActions";
import getConfig from "../../../../config/getConfig";
// import { setLoading } from "../../../../components/loading/store/showLoadingActions";

export const getBonusRecordsData = () => (dispatch, getState) => {
  const { token } = getState().loginUser;
  const requestOptions = {
    method: "GET",
    headers: { "x-access-token": `${token}` },
  };

  fetch(`${getConfig().API_URL_BONUSES}/bonuses/get_all`, requestOptions)
    .then((response) => {
      if (response.status === 204) {
        throw new Error("No Avaliable Bonuses");
      }
      return response.json();
    })
    .then((json) => {
      if (json.errors) {
        throw new Error(json.errors[0].message);
      }
      dispatch({
        type: constants.REQUEST_BONUS_RECORDS_SUCCESS,
        payload: json,
      });
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};

export const CreateBonusRecordsData = (body) => (dispatch, getState) => {
  const requestOptions = {
    method: "POST",
    credential: "include",
    body,
  };

  fetch(`${getConfig().API_URL_BONUSES}/bonuses/create`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      if (json.errors) {
        throw new Error(json.errors[0].message);
      }
      dispatch({
        type: constants.REQUEST_CREATE_BONUS_RECORD_SUCCESS,
        payload: json,
      });
      dispatch(getBonusRecordsData());
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};

export const deactivateBonusRecord = (uuid) => (dispatch, getState) => {
  const { token } = getState().loginUser;
  const requestOptions = {
    method: "GET",
    headers: { "x-access-token": `${token}` },
  };

  fetch(
    `${getConfig().API_URL_BONUSES}/bonuses/deactivate_bonus_record/${uuid}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        throw new Error(json.error);
      }
      dispatch({
        type: constants.REQUEST_DEACTIVATE_BONUS_RECORD_SUCCESS,
      });
      dispatch(getBonusRecordsData());
    })
    .catch((error) => {
      dispatch(setNotificationMessage(error.message));
      dispatch(setShowNotificationMessage(true));
    });
};
