import * as constants from "./filtersConstants";

const initialState = {
  filters: [
    {
      name: "Signup Bonuses",
      field: "condition.name",
      parameter: "===",
      value: "ftd",
    },
    {
      name: "Reload Bonuses",
      field: "condition.name",
      parameter: "===",
      value: "reload",
    },
    {
      name: "Active",
      field: "status",
      parameter: "===",
      value: "active",
    },
    {
      name: "Inactive",
      field: "status",
      parameter: "===",
      value: "inactive",
    },
    // {
    //   name: "Wagering Requirement >= x35",
    //   field: "wageringRequirement",
    //   parameter: ">=",
    //   value: 35,
    // },
    // {
    //   name: "100% Bonuses",
    //   field: "percentage",
    //   parameter: "===",
    //   value: 100,
    // },
    // {
    //   name: ">100% Bonuses",
    //   field: "percentage",
    //   parameter: ">",
    //   value: 100,
    // },
    // {
    //   name: "<100% Bonuses",
    //   field: "percentage",
    //   parameter: "<",
    //   value: "100",
    // },
  ],
  chosenFilter: "",
};

export const bonusTypesFilters = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.CHANGE_FILTER:
      return { ...state, chosenFilter: action.payload };
    case constants.ADD_FILTER:
      return { ...state, filters: [...state.filters, action.payload] };
    case constants.REMOVE_FILTER:
      return {
        ...state,
        filters: state.filters.filter((item) => item.name !== action.payload),
      };
    default:
      return state;
  }
};
