import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import Widget from "../../../components/widget/Widget";
import GridConfig from "../../../components/grid/GridConfig";
import { GridConfigData } from "./grid_data/GridData";
import { getTransactionsData } from "../store/get_all_transactions/TransactionsActions";

const drawerWidth = '270px';
const marginTop = '100px';

const WithdrawalHistory = (_, context) => {
  const store = useSelector((state) => ({
    withdrawals: state.TransactionsData.transactions,
  }));
  const dispatch = useDispatch();
  //   const [gridApi, setGridApi] = useState({});
  useEffect(() => {
    dispatch(getTransactionsData('withdrawal'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Grid
  const onGridReady = (params) => {
    // setGridApi(params.api);
  };
  const onRowSelected = (e) => {
    // const selectedRows = gridApi.getSelectedRows();
  };
  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("Withdrawal History")}
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("Transactions in 1h")}
                data={
                  store.withdrawals.filter(
                    (el) =>
                      new Date(el.createdAt) >=
                      new Date(
                        (Math.round(new Date().getTime() / 1000) - 1 * 3600) *
                          1000
                      ).getTime()
                  ).length
                }
                description={`Amount of withdrawals > 100  - ${
                  store.withdrawals.filter((el) => el.amount > -100).length
                }`}
                Icon={PaymentIcon}
              />
            }
            {
              <Widget
                name={context.t("Transactions in 24hrs")}
                data={
                  store.withdrawals.filter(
                    (el) =>
                      new Date(el.createdAt) >=
                      new Date(
                        (Math.round(new Date().getTime() / 1000) - 24 * 3600) *
                          1000
                      ).getTime()
                  ).length
                }
                description={`Total amount of withdrawals  - ${store.withdrawals.length}`}
                Icon={PaymentIcon}
              />
            }
          </Box>
        </Box>
        <Box sx={{
          display: "grid",
          margin: (theme) => theme.spacing(10, 4),
          gridTemplateColumns: "100%",
        }}>
          <div>
            <div className="ag-theme-material">
              <Box sx={{
                height: "50vh",
              }}>
                <GridConfig
                  props={{
                    GridConfigData,
                    onGridReady,
                    onRowSelected,
                    rowData: store.withdrawals,
                  }}
                />
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

WithdrawalHistory.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WithdrawalHistory;
