import React from "react";

import GridConfig from "../../../../../components/grid/GridConfig";
import { GridConfigData as PlayerGrid } from "../grid_data/PlayerGrid";

const Grid = ({ players, store }) => {
  return (
    <>
      <GridConfig
        props={{
          GridConfigData: PlayerGrid,
          onGridReady: players.onPlayersGridReady,
          onRowSelected: players.onPlayersRowSelected,
          rowData: store.players,
        }}
      />
    </>
  );
};

export default Grid;
