import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ReportIcon from "@mui/icons-material/Report";
import Widget from "../../components/widget/Widget";
import GridConfig from "../../components/grid/GridConfig";
import { GridConfigData } from "./grid_data/GridData";
import { getPlayersData } from "./store/grid_player_load/gridPlayersActions";
import DeactivatePlayers from "./deactivate_players/DeactivatePlayers";
import { Box, Typography, Button } from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

const PlayerManagement = (_, context) => {
  const store = useSelector((state) => ({
    players: state.PlayersData.players,
  }));
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState({});
  const [playerId, setPlayerId] = useState({ id: "", disabled: true });
  const [playerIds, setPlayerIds] = useState({ ids: [], disabled: true });
  const [openDeactivate, setOpenDeactivate] = useState(false);
  useEffect(() => {
    dispatch(getPlayersData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Grid
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const onRowSelected = (e) => {
    const selectedRows = gridApi.getSelectedRows();
    if (selectedRows.length === 1) {
      setPlayerId({ id: selectedRows[0].uuid, disabled: false });
      setPlayerIds({ ids: [selectedRows[0].uuid], disabled: false });
      return;
    }
    const selectOnlyIds = selectedRows.map((el) => el.uuid);
    setPlayerId({ id: "", disabled: true });
    setPlayerIds({
      ids: selectOnlyIds,
      disabled: selectOnlyIds.length === 0 ? true : false,
    });
  };
  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("Player Management")}
          </Typography>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
            {
              <Widget
                name={context.t("Total Players")}
                data={store.players.length}
                description=""
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Active Players")}
                data={store.players.filter((el) => el.status.name === "active").length}
                description={context.t("Total Active Players")}
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Inactive Players")}
                data={
                  store.players.filter((el) => el.status.name === "inactive").length
                }
                description={context.t("Total Inactive Players")}
                Icon={PeopleAltIcon}
              />
            }
            {
              <Widget
                name={context.t("Blocked Players")}
                data={store.players.filter((el) => el.status.name === "blocked").length}
                description={context.t("Permanently Blocked Players")}
                Icon={ReportIcon}
              />
            }
          </Box>
        </Box>
        <Box sx={{
          display: "grid",
          margin: (theme) => theme.spacing(10, 4),
          gridTemplateColumns: "100%",
        }}>
          <Box sx={{
            justifySelf: "end",
            margin: (theme) => theme.spacing(1, 0),
          }}>
            <Button
              variant="contained"
              sx={(theme) => ({
                padding: theme.spacing(1, 2),
                margin: theme.spacing(0, 1),
                minWidth: 200,
                color: theme.palette.primary.light,
                background: theme.palette.secondary.dark,
              })}
              disabled={playerId.disabled}
              href={`/playerProfile?id=${playerId.id}`}
            >
              {context.t("Show Profile")}
            </Button>

            <Button
              variant="contained"
              sx={(theme) => ({
                padding: theme.spacing(1, 2),
                minWidth: 200,
                color: theme.palette.primary.light,
                background: theme.palette.error.main,
                "&:hover": {
                  background: theme.palette.error.dark,
                },
              })}
              disabled={playerIds.disabled}
              onClick={(_) => setOpenDeactivate(true)}
            >
              {context.t("Deactivate")}
            </Button>
            <DeactivatePlayers
              selectedPlayers={playerIds}
              setOpenDeactivate={setOpenDeactivate}
              openDeactivate={openDeactivate}
              context={context}
            />
          </Box>
          <div>
            <div className="ag-theme-material">
              <Box sx={{
                height: "50vh",
              }}>
                <GridConfig
                  props={{
                    GridConfigData,
                    onGridReady,
                    onRowSelected,
                    rowData: store.players,
                  }}
                />
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

PlayerManagement.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PlayerManagement;
