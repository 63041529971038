import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Terms_Conditions from "../../components/pages/Terms_Conditions";
import Privacy_Policy from "../../components/pages/Privacy_Policy";
import Responsible_Gaming from "../../components/pages/Responsible_Gaming";
import { getPageDescription } from "./store/PageActions";
import { getLanguages } from "../languages/store/LanguageActions";
import { Box, Typography, Button } from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

const Pages = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [activeLanguage, setActiveLanguage] = useState("en_GB");
  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    descriptionData: state.Pages,
    languages: state.Languages.languages,
  }));

  useEffect(() => {
    dispatch(getPageDescription({ lanCode: "en_GB", pageId: 1 }));
    dispatch(getLanguages());
  }, []);
  // console.log(store.descriptionData.en)
  useEffect(() => {
    if (activeLanguage !== "en_GB") {
      dispatch(getPageDescription({ lanCode: activeLanguage, pageId: pageIndex }));
    }
  }, [activeLanguage]);

  useEffect(() => {
    setActiveLanguage("en_GB");
  }, [pageIndex]);

  useEffect(() => {}, [store.descriptionData, store.languages]);

  const getOptions = () => {
    if (store.languages) {
      // return store.languages.map((item) => ({ value: item.code, label: `${item.language} - ${item.country}` }));
      const filtered = store.languages.filter((item) => item.lanCode !== "en_GB");
      const final = filtered.filter((item) => item.enable === true);
      // {console.log(final)}
      return final.map((item) => ({
        value: item.lanCode,
        label: `${item.language} - ${item.country}`,
      }));
    }
    return [];
  };

  // const getOptions = () => {
  //   if (store.lang) {
  //     const filtered = store.lang.filter((item) => item.code !== "en_GB");
  //     const final = filtered.filter((item) => item.enable === true);
  //     // {console.log(final)}
  //     return final.map((item) => ({
  //       value: item.id,
  //       label: `${item.language} - ${item.country}`,
  //     }));
  //   }
  //   return [];
  // };
  // console.log(store.descriptionData.en)
  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(0, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            Pages
          </Typography>
          <Box sx={{
            display: "flex",
            gap: "0.75rem",
            margin: "0 auto",
          }}>
            <Button
              variant="contained"
              sx={(theme) => ({
                margin: theme.spacing(0, 0, 0, 1),
                padding: theme.spacing(1, 2),
                minWidth: 200,
                color: theme.palette.primary.light,
                background: theme.palette.secondary.dark,
                ...(pageIndex === 1 &&{
                  background: theme.palette.primary.light,
                  color: theme.palette.secondary.dark,
                })
              })}
              onClick={() => {
                dispatch(getPageDescription({ lanCode: "en_GB", pageId: 1 }));
                setPageIndex(1);
              }}
            >
              Terms and Conditions
            </Button>
            <Button
              variant="contained"
              sx={(theme) => ({
                margin: theme.spacing(0, 0, 0, 1),
                padding: theme.spacing(1, 2),
                minWidth: 200,
                color: theme.palette.primary.light,
                background: theme.palette.secondary.dark,
                ...(pageIndex === 2 &&{
                  background: theme.palette.primary.light,
                  color: theme.palette.secondary.dark,
                })
              })}
              onClick={() => {
                dispatch(getPageDescription({ lanCode: "en_GB", pageId: 2 }));
                setPageIndex(2);
              }}
            >
              Privacy Policy
            </Button>
            <Button
              variant="contained"
              sx={(theme) => ({
                margin: theme.spacing(0, 0, 0, 1),
                padding: theme.spacing(1, 2),
                minWidth: 200,
                color: theme.palette.primary.light,
                background: theme.palette.secondary.dark,
                ...(pageIndex === 3 &&{
                  background: theme.palette.primary.light,
                  color: theme.palette.secondary.dark,
                })
              })}
              onClick={() => {
                dispatch(getPageDescription({ lanCode: "en_GB", pageId: 3 }));
                setPageIndex(3);
              }}
            >
              Responsible Gaming
            </Button>
          </Box>
          {pageIndex === 1 ? (
            <Terms_Conditions
              english={store.descriptionData.en ? store.descriptionData.en.description : null}
              alt={
                activeLanguage !== "en_GB"
                  ? store.descriptionData.alt
                    ? store.descriptionData.alt.description
                    : null
                  : ""
              }
              languages={getOptions()}
              setActiveLanguage={setActiveLanguage}
              activeLanguage={activeLanguage}
            />
          ) : null}
          {pageIndex === 2 ? (
            <Privacy_Policy
              english={store.descriptionData.en ? store.descriptionData.en.description : null}
              alt={
                activeLanguage !== "en_GB"
                  ? store.descriptionData.alt
                    ? store.descriptionData.alt.description
                    : null
                  : ""
              }
              languages={getOptions()}
              setActiveLanguage={setActiveLanguage}
              activeLanguage={activeLanguage}
            />
          ) : null}
          {pageIndex === 3 ? (
            <Responsible_Gaming
              english={store.descriptionData.en ? store.descriptionData.en.description : null}
              alt={
                activeLanguage !== "en_GB"
                  ? store.descriptionData.alt
                    ? store.descriptionData.alt.description
                    : null
                  : ""
              }
              languages={getOptions()}
              setActiveLanguage={setActiveLanguage}
              activeLanguage={activeLanguage}
            />
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default Pages;
