const columnDefs = (context) => [
  {
    headerName: context.t("Name"),
    checkboxSelection: true,
    minWidth: 240,
    field: "name",
    cellClass: "cell-default-style",
    filter: "agTextColumnFilter",
    filterParams: {
      cellHeight: 20,
      debounceMs: 100,
      filterOptions: ["contains"],
    },
  },
  {
    headerName: context.t("Bonus Percentage"),
    field: "percentage",
    minWidth: 130,
    cellClass: "cell-default-style",
    chartDataType: "series",
  },
  {
    headerName: context.t("Starts At"),
    valueGetter: function checkField(params) {
      return new Date(params.data.startDate).toDateString();
    },
    minWidth: 130,
    cellClass: "cell-default-style",
    chartDataType: "series",
  },
  {
    headerName: context.t("Expiring At"),
    valueGetter: function checkField(params) {
      return new Date(params.data.endDate).toDateString();
    },
    minWidth: 130,
    cellClass: "cell-default-style",
    chartDataType: "series",
  },
  {
    headerName: context.t("Status"),
    valueGetter: function checkField(params) {
      return params.data.status ? "Active" : "Inactive";
    },
    minWidth: 130,
    cellClass: "cell-default-style",
    chartDataType: "series",
  },
  {
    headerName: context.t("Wagering Requirement"),
    field: "wageringRequirement",
    minWidth: 130,
    cellClass: "cell-default-style",
    chartDataType: "series",
  },
  {
    headerName: context.t("Code"),
    field: "code",
    minWidth: 130,
    cellClass: "cell-default-style",
    chartDataType: "series",
  },
];
const defaultColDef = {
  resizable: true,
  filter: true,
};
const floatingFilter = true;

const rowSelection = "single";

export const GridConfigData = {
  columnDefs,
  defaultColDef,
  floatingFilter,
  rowSelection,
};
