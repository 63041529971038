import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import ProfileForm from "./ProfileForm";
import ChangeProfilePicture from "./ChangePicture";
import ChangePassword from "./ChangePassword";
import GridConfig from "../../../components/grid/GridConfig";
import { GridConfigData } from "./grid_data/GridData";
import {
  checkToken,
  getCurrentUserSessions,
} from "../../signin/login_page/store/LoginActions";
import { Box, Typography, Button } from "@mui/material";

const drawerWidth = '270px';
const marginTop = '100px';

const MyProfile = ({_, history}, context) => {
  const [editPicture, seteditPicture] = useState(false);
  const [editProfile, seteditProfile] = useState(true);
  const [changePassword, setchangePassword] = useState(false);
  const dispatch = useDispatch();
  // Grid
  const [paginationPageSize, setPaginationPageSize] = useState(0);
  const [cacheBlockSize, setCacheBlockSize] = useState(0);
  const [gridApi, setGridApi] = useState({});
  const [cahcheFilter, setcahcheFilter] = useState(undefined)

  const customOnGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();

    setPaginationPageSize(params.api.paginationGetPageSize());
    setCacheBlockSize(params.api.paginationGetPageSize());

    let datasource = createServerSideDatasource(cahcheFilter);
    params.api.setServerSideDatasource(datasource);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const createServerSideDatasource = (filter) => {
    return {
      getRows: (params) => {
        dispatch(checkToken(getCurrentUserSessions(params, filter)));
      },
    };
  };
  const onFilterChanged = (params) => {
    let filterModel = gridApi.getFilterModel();
    setcahcheFilter(filterModel)
    let datasource = createServerSideDatasource(filterModel);
    params.api.setServerSideDatasource(datasource);
  };


  const onRowSelected = (e) => {
    const focusedCell = gridApi.getFocusedCell();
    const selectedInfo = gridApi.getDisplayedRowAtIndex(focusedCell.rowIndex);
    console.log(selectedInfo);
  };
  return (
    <>
      <Box sx={{
        marginTop,
        marginLeft: drawerWidth,
        [(theme) => theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      }}>
        <Box sx={{
          margin: (theme) => theme.spacing(2, 4),
          display: "flex",
          flexDirection: "column",
        }}>
          <Typography variant="h5" noWrap paragraph>
            {context.t("User Profile")}
          </Typography>
          <Box sx={{
            display: "grid",
            gridTemplateColumns: "25% 75%",
            gridGap: "10px",
            [(theme) => theme.breakpoints.down("md")]: {
              gridTemplateColumns: "100%",
            },
          }}>
            <ChangeProfilePicture
              editPicture={editPicture}
              seteditPicture={seteditPicture}
            />
            {changePassword ? (
              <ChangePassword
                setchangePassword={setchangePassword}
                changePassword={changePassword}
              />
            ) : (
              <ProfileForm
                editProfile={editProfile}
                seteditProfile={seteditProfile}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            margin: theme.spacing(0, 2),
            textAlign: "end",
            ...((editPicture || !editProfile || changePassword) && {
              display: "none",
            }),
            [theme.breakpoints.down("md")]: {
              textAlign: "center",
              margin: 0,
            },
          })}
        >
          <Button
            variant="contained"
            sx={(theme) => ({
              padding: theme.spacing(1, 2),
              margin: theme.spacing(2, 1),
              minWidth: 200,
              color: theme.palette.primary.light,
              background: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.primary.dark,
              },
            })}
            onClick={(el) => seteditPicture(true)}
          >
            {context.t("Change Picture")}
          </Button>
          <Button
            variant="contained"
            sx={(theme) => ({
              padding: theme.spacing(1, 2),
              margin: theme.spacing(2, 1),
              minWidth: 200,
              color: theme.palette.primary.light,
              background: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.primary.dark,
              },
            })}
            onClick={(el) => seteditProfile(false)}
          >
            {context.t("Edit Inforamtion")}
          </Button>
          <Button
            variant="contained"
            sx={(theme) => ({
              padding: theme.spacing(1, 2),
              margin: theme.spacing(2, 1),
              minWidth: 200,
              color: theme.palette.primary.light,
              background: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.primary.dark,
              },
            })}
            onClick={(el) => setchangePassword(true)}
          >
            {context.t("Change Password")}
          </Button>
        </Box>
        <Box sx={{
          margin: (theme) => theme.spacing(2, 4)
        }}>
          <Typography variant="h5">{context.t("Sessions")}:</Typography>
          <div>
            <div className="ag-theme-material">
              <Box sx={{
                height: "40vh",
              }}>
                <GridConfig
                  props={{
                    GridConfigData: {
                      ...GridConfigData,
                      paginationPageSize,
                      cacheBlockSize,
                      onGridSizeChanged: customOnGridSizeChanged,
                      onFilterChanged,

                    },
                    onGridReady,
                    onRowSelected,
                  }}
                />
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

MyProfile.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MyProfile;
