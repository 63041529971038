import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector, useDispatch } from "react-redux";
import { setStepOneData } from "./store/createCampaignActions";
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  Input,
  TextField
} from "@mui/material";

const StepOne = (props, context) => {
  const store = useSelector((state) => ({
    startDate: state.campaignData.stepOneData.startDate,
    endDate: state.campaignData.stepOneData.endDate,
    campaignName: state.campaignData.stepOneData.name,
    targetName: state.campaignData.stepOneData.targetName,
    countries: state.campaignData.stepOneData.countries,
    description: state.campaignData.stepOneData.description,
  }));
  const dispatch = useDispatch();
  const [targetGroup, setTargetGroup] = useState([]);
  const [countries, setCountries] = useState([
    "Italy",
    "Sweden",
    "Germany",
    "France",
  ]);

  useEffect(() => {
    setTargetGroup([
      "New registered client that hasnt deposit within the first 7 days of registration",
      "Players that haven't deposit within 1 month",
      "Player that haven't deposit within a month, but has deposit at least once",
      "Player that haven't deposit within 3 months, but has deposit at least once",
      "Player that haven't deposit within 6 months, but has deposit at least once",
      "Players who registered in the last week",
      "Players who registered in the last month",
      "Not active for > 1 month",
      "GGR > 1k",
      "More than 5 deposits in last month",
    ]);

    setCountries(["Italy", "Sweden", "Germany", "France"]);
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  return (
    <>
      <Box sx={{
        "& .MuiTextField-root": {
          minWidth: "40%",
        },
        "& .MuiOutlinedInput-input": {
          border: "none",
        },
        "& .MuiInputLabel-formControl": {
          left: "10px",
        },
      }}>
        <DatePicker
          required
          autoOk
          views={['year', 'month', 'day']}
          openTo="year"
          label={context.t("Campaign Start")}
          format="MM/dd/yyyy"
          value={store.startDate}
          onChange={(date) => dispatch(setStepOneData("startDate", date))}
          renderInput={(params) => <TextField
            sx={(theme) => ({
              width: "45%",
              padding: "0 9px",
              margin: "15px 0",
              "& .MuiInputLabel-shrink": {
                transform: "translate(25px, -6px) scale(0.75)",
              },
              "& .MuiInputLabel-formControl": {
                left: "0px",
              },
              [theme.breakpoints.down("xs")]: {
                width: "90%",
              },
            })}
            color="secondary"
            {...params}
          />}
        />
        <DatePicker
          required
          autoOk
          views={['year', 'month', 'day']}
          openTo="year"
          label={context.t("Campaign End")}
          format="MM/dd/yyyy"
          value={store.endDate}
          minDate={store.startDate}
          onChange={(date) => dispatch(setStepOneData("endDate", date))}
          renderInput={(params) => <TextField
            sx={(theme) => ({
              width: "45%",
              padding: "0 9px",
              margin: "15px 0",
              "& .MuiInputLabel-shrink": {
                transform: "translate(25px, -6px) scale(0.75)",
              },
              "& .MuiInputLabel-formControl": {
                left: "0px",
              },
              [theme.breakpoints.down("xs")]: {
                width: "90%",
              },
            })}
            color="secondary"
            {...params}
          />}
        />
        <TextField
          variant="outlined"
          name="name"
          label={context.t("Campaign Name")}
          type="text"
          required
          value={store.campaignName}
          onChange={(event) =>
            dispatch(setStepOneData("name", event.target.value))}
          sx={{
            width: "90%",
            padding: "0 11px",
            margin: "8px 0 10px",
            "& .MuiInputLabel-shrink": {
              transform: "translate(20px, -6px) scale(0.75)",
            },
          }}
        />

        <FormControl sx={{
          width: "90%",
          padding: "0 11px",
          margin: "8px 0 10px",
          "& .MuiInputLabel-shrink": {
            transform: "translate(20px, -6px) scale(0.75)",
          },
        }}>
          <InputLabel id="demo-mutiple-checkbox-label">
            {context.t("Countries Included in Promo")}
          </InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            label={context.t("Countries Included in Promo")}
            multiple
            value={store.countries.length === 0 ? countries : store.countries}
            onChange={(event) =>
              dispatch(setStepOneData("countries", event.target.value))}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                <Checkbox
                  checked={
                    store.countries.length === 0
                      ? countries.indexOf(country) > -1
                      : store.countries.indexOf(country) > -1
                  }
                />
                <ListItemText primary={country}/>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{
          width: "90%",
          padding: "0 11px",
          margin: "8px 0 10px",
          "& .MuiInputLabel-shrink": {
            transform: "translate(20px, -6px) scale(0.75)",
          },
        }}>
          <InputLabel required id="demo-mutiple-checkbox-label">
            {context.t("Target Group")}
          </InputLabel>
          <Select
            label={context.t("Target Group")}
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={store.targetName}
            onChange={(event) =>
              dispatch(setStepOneData("targetName", event.target.value))}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {targetGroup.map((target) => (
              <MenuItem key={target} value={target}>
                <Checkbox checked={store.targetName.indexOf(target) > -1}/>
                <ListItemText primary={target}/>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          name="description"
          multiline
          label={context.t("Campaign Description")}
          type="text"
          rows={6}
          rowsMax={6}
          value={store.description}
          onChange={(event) =>
            dispatch(setStepOneData("description", event.target.value))}
          sx={{
            width: "90%",
            padding: "0 11px",
            margin: "8px 0 10px",
            "& .MuiInputLabel-shrink": {
              transform: "translate(20px, -6px) scale(0.75)",
            },
          }}
        />
      </Box>
    </>
  );
};
StepOne.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default StepOne;
