import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

const SearchBox = ({ searchChange }, context) => {
  const [search, setSearch] = useState("");
  const handleChange = (e) => {
    searchChange(e);
    setSearch(e.target.value);
  };

  return (
    <TextField
      id="search"
      label={context.t("Search")}
      value={search}
      onChange={handleChange}
      variant="outlined"
      sx={(theme) => ({
        width: "35%",
        [theme.breakpoints.down("md")]: {
          width: "40%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "65%",
        },
      })}
    />
  );
};

SearchBox.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SearchBox;
